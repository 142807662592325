// Get RTC token
export const getRtcToken = async (channelName: string, uid: number) => {
  try {
    const accessToken = localStorage.getItem("access_token");
    const url = `${process.env.REACT_APP_API_URL}/agora/rtc-token?channelName=${channelName}&uid=${uid}`;
    console.log(url);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get RTC token");
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    console.error("Error getting RTC token:", error);
    throw error;
  }
};

// Get RTMP token
export const getRtmpToken = async (channelName: string, uid: string) => {
  const accessToken = localStorage.getItem("access_token");
  const url = `${process.env.REACT_APP_API_URL}/agora/rtm-token?uid=${uid}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to get RTMP token");
  }

  const data = await response.json();
  return data.token;
};
