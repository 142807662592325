import React, { FC } from "react";

const CTASection: FC = () => {
  return (
    <section className="mb-16">
      <div className="bg-[#FFF] rounded-3xl overflow-hidden">
        <div className="flex">
          {/* Left: Image with orange circle background */}
          <div className="relative w-[45%]">
            <div className="absolute inset-0 z-10" />
            <img
              src="/images/student.png"
              alt=""
              className="h-[90%] w-[90%] object-contain mt-8"
            />
          </div>

          {/* Right: Content */}
          <div className="flex-1 p-16">
            <h2 className="text-[40px] font-bold text-[#020A13] leading-tight mb-4">
              Join 5000+ students to up-skill your learning with our Code Coast
              premium
            </h2>
            <p className="text-[#020A13]/60 text-lg mb-8">
              Start learning by registering for free trial
            </p>
            <button className="bg-[#55B7FF] text-white px-8 py-3 rounded-full text-lg font-medium hover:bg-[#0284C7] transition-colors">
              Get free trial
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
