import { FC, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import LiveSessionModal from "../../../components/LiveSessionModal/LiveSessionModal";
import { useNavigate } from "react-router-dom";
import EditSessionModal from "../../../components/EditSessionModal/EditSessionModal";
import { adminFetch } from "../../../utils/fetchInterceptor";

interface Instructor {
  _id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  title: string;
  avatar: string;
  expertise: string[];
  rating: number;
  totalStudents: number;
}

interface Student {
  _id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  avatar: string;
}

interface Course {
  _id: string;
  title: string;
}

interface LiveSession {
  _id: string;
  title: string;
  description: string;
  scheduledFor: string;
  duration: number;
  status: "scheduled" | "live" | "ended" | "cancelled";
  totalSignups: number;
  maxStudents: number;
  roomId: string;
  meetingId: string;
  courseId: Course;
  instructorId: Instructor;
  registeredStudents: Student[];
  createdAt: string;
  updatedAt: string;
}

interface PaginationInfo {
  total: number;
  pages: number;
  page: number;
  limit: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

interface MetaInfo {
  totalClasses: number;
  totalStudents: number;
  averageClassSize: number;
}

interface LiveSessionsResponse {
  classes: LiveSession[];
  pagination: PaginationInfo;
  meta: MetaInfo;
}

interface Statistics {
  totalLiveSessions: {
    count: number;
    completed: number;
    scheduled: number;
    live: number;
    cancelled: number;
    monthlyCompleted: number;
    percentageIncrease: string;
  };
  totalJoinedStudents: {
    count: number;
    averagePerSession: number;
    monthlyActive: number;
    percentageIncrease: string;
  };
  attendance: {
    total: {
      registered: number;
      attended: number;
      rate: string;
    };
    monthly: {
      registered: number;
      attended: number;
      rate: string;
    };
  };
  absentStudents: {
    count: number;
    percentage: string;
    monthlyTrend: string;
  };
  weeklySessions: {
    completed: number;
    upcoming: number;
    percentageChange: string;
  };
}

interface Course {
  _id: string;
  title: string;
}

const AdminLiveSessions: FC = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSession, setEditSession] = useState<LiveSession | null>(null);
  const navigate = useNavigate();

  const { data: liveSessionsData } = useQuery<LiveSessionsResponse>({
    queryKey: ["live-sessions", page, search, status, category],
    queryFn: async () => {
      const queryParams = new URLSearchParams({
        page: page.toString(),
        ...(search && { search }),
        ...(status && { status }),
        ...(category && { category }),
      });

      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/live-classes/admin/classes?${queryParams}`
      );

      if (!response) return null;
      if (!response.ok) throw new Error("Failed to fetch live sessions");

      return response.json();
    },
  });

  const { data: statistics } = useQuery<Statistics>({
    queryKey: ["live-sessions-stats"],
    queryFn: async () => {
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/live-classes/statistics`
      );

      if (!response) return null;
      if (!response.ok) throw new Error("Failed to fetch statistics");

      return response.json();
    },
  });

  const { data: coursesData } = useQuery({
    queryKey: ["courses"],
    queryFn: async () => {
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/courses`
      );

      if (!response) return null;
      if (!response.ok) throw new Error("Failed to fetch courses");

      return response.json();
    },
  });

  // Update the stats object to use the fetched data
  const stats = {
    totalSessions: {
      count: statistics?.totalLiveSessions.count ?? 0,
      trend: statistics?.totalLiveSessions.percentageIncrease ?? "0%",
      completed: statistics?.totalLiveSessions.completed ?? 0,
    },
    joinedStudents: {
      count: statistics?.totalJoinedStudents.count ?? 0,
      trend: statistics?.totalJoinedStudents.percentageIncrease ?? "0%",
      averagePerSession: statistics?.totalJoinedStudents.averagePerSession ?? 0,
    },
    unjoinedStudents: {
      count: statistics?.absentStudents.count ?? 0,
      percentage: statistics?.absentStudents.percentage ?? "0%",
      trend: statistics?.absentStudents.monthlyTrend ?? "0%",
    },
    weeklySessions: {
      count: statistics?.weeklySessions.completed ?? 0,
      trend: statistics?.weeklySessions.percentageChange ?? "0%",
      nextWeek: statistics?.weeklySessions.upcoming ?? 0,
    },
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.target.value);
    setPage(1);
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCategory(event.target.value);
    setPage(1);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Live Sessions</h1>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          Schedule New Session
        </button>
      </div>

      <LiveSessionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        courses={(Array.isArray(coursesData) ? coursesData : []).map(
          (course) => ({
            id: course._id,
            title: course.title,
          })
        )}
      />

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Total Live Sessions</div>
          <div className="text-2xl font-bold">{stats.totalSessions.count}</div>
          <div className="flex items-center">
            <span
              className={`text-sm ${
                stats.totalSessions.trend.startsWith("+")
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {stats.totalSessions.trend} this month
            </span>
            <span className="text-sm text-gray-500 ml-2">
              ({stats.totalSessions.completed} completed)
            </span>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Total Joined Students</div>
          <div className="text-2xl font-bold">{stats.joinedStudents.count}</div>
          <div className="flex items-center">
            <span className="text-sm text-green-600">
              {stats.joinedStudents.trend} increase
            </span>
            <span className="text-sm text-gray-500 ml-2">
              (~{stats.joinedStudents.averagePerSession} per session)
            </span>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Absent Students</div>
          <div className="text-2xl font-bold">
            {stats.unjoinedStudents.count}
          </div>
          <div className="flex items-center">
            <span
              className={`text-sm ${
                stats.unjoinedStudents.trend.startsWith("-")
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {stats.unjoinedStudents.trend} this week
            </span>
            <span className="text-sm text-gray-500 ml-2">
              ({stats.unjoinedStudents.percentage})
            </span>
          </div>
        </div>

        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Weekly Sessions</div>
          <div className="text-2xl font-bold">{stats.weeklySessions.count}</div>
          <div className="flex items-center">
            <span
              className={`text-sm ${
                stats.weeklySessions.trend.startsWith("+")
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {stats.weeklySessions.trend}
            </span>
            <span className="text-sm text-blue-600 ml-2">
              ({stats.weeklySessions.nextWeek} scheduled next week)
            </span>
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex gap-4">
          <div className="flex-1">
            <input
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Search sessions..."
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <select
            value={status}
            onChange={handleStatusChange}
            className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          >
            <option value="">All Status</option>
            <option value="scheduled">Scheduled</option>
            <option value="live">Live</option>
            <option value="ended">Ended</option>
            <option value="cancelled">Cancelled</option>
          </select>
          <select
            value={category}
            onChange={handleCategoryChange}
            className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          >
            <option value="">All Categories</option>
            <option value="web-development">Web Development</option>
            <option value="mobile-development">Mobile Development</option>
            <option value="data-science">Data Science</option>
            <option value="devops">DevOps</option>
            <option value="cloud">Cloud Computing</option>
          </select>
        </div>
      </div>

      {/* Sessions Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Session Details
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Instructor
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Schedule
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {liveSessionsData?.classes.map((session: LiveSession) => (
              <tr key={session._id} className="hover:bg-gray-50">
                <td className="px-6 py-4">
                  <div className="flex flex-col">
                    <div className="text-sm font-medium text-gray-900">
                      {session.title}
                    </div>
                    <div className="text-sm text-gray-500">
                      {session.courseId.title}
                    </div>
                    <div className="text-sm text-gray-500">
                      {session.totalSignups} participants
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    {/* <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full object-cover"
                        src={session.instructorId.avatar}
                        alt={`${session.instructorId.firstName} ${session.instructorId.lastName}`}
                      />
                    </div> */}
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {session.instructorId.username}{" "}
                        {/* {session.instructorId.lastName} */}
                      </div>
                      <div className="text-sm text-gray-500">
                        {session.instructorId.title}
                      </div>
                      {/* <div className="text-sm text-gray-500">
                        Rating: {session.instructorId.rating} •{" "}
                        {session.instructorId.totalStudents} students
                      </div> */}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {new Date(session.scheduledFor).toLocaleDateString()}
                  </div>
                  <div className="text-sm text-gray-500">
                    {new Date(session.scheduledFor).toLocaleTimeString()}
                  </div>
                  <div className="text-sm text-gray-500">
                    {session.duration} minutes
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      {
                        scheduled: "bg-yellow-100 text-yellow-800",
                        live: "bg-green-100 text-green-800",
                        ended: "bg-gray-100 text-gray-800",
                        cancelled: "bg-red-100 text-red-800",
                      }[session.status]
                    }`}
                  >
                    {session.status.charAt(0).toUpperCase() +
                      session.status.slice(1)}
                  </span>
                </td>
                <td className="px-0 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex space-x-3">
                    <button
                      onClick={() => setEditSession(session)}
                      className="text-blue-600 hover:text-blue-900"
                    >
                      Edit
                    </button>
                    {(session.status === "live" ||
                      session.status === "scheduled") && (
                      <button
                        onClick={() =>
                          navigate(`/instructor/classroom/${session.roomId}`)
                        }
                        className="text-green-600 hover:text-green-900"
                      >
                        Enter Classroom
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {liveSessionsData?.pagination && (
        <div className="mt-4 flex justify-center gap-2">
          {Array.from({ length: liveSessionsData.pagination.pages }).map(
            (_, i) => (
              <button
                key={i}
                onClick={() => handlePageChange(i + 1)}
                className={`px-3 py-1 rounded ${
                  page === i + 1 ? "bg-blue-600 text-white" : "bg-gray-200"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
      )}

      {/* Add EditSessionModal */}
      <EditSessionModal
        isOpen={!!editSession}
        onClose={() => setEditSession(null)}
        session={editSession}
      />
    </div>
  );
};

export default AdminLiveSessions;
