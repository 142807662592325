import React, { FC } from "react";
import { Teacher } from "../../types";

interface TeacherCardProps {
  teacher: Teacher;
}

const TeacherCard: FC<TeacherCardProps> = ({ teacher }) => {
  return (
    <section className="mb-16">
      <div className="bg-white rounded-2xl p-8 flex gap-12">
        {/* Left: Content */}
        <div className="flex-1">
          <h2 className="text-2xl font-extrabold mb-8">
            Our top popular teacher this month
          </h2>
          <p className="text-gray-500 text-lg leading-relaxed mb-8">
            {teacher.description}
          </p>
          <h3 className="font-semibold text-xl mb-1">{teacher.name}</h3>
          <p className="text-gray-400">{teacher.role}</p>
          <button className="text-[#0284C7] font-medium mt-6 hover:text-blue-700">
            See More
          </button>
        </div>

        {/* Right: Image with Diagonal Corners */}
        <div className="relative w-[40%]">
          {/* Top-right orange corner */}
          <div className="absolute -top-4 -right-4 w-32 h-32 overflow-hidden">
            <div className="absolute top-0 right-0 w-24 h-24 bg-[#F1962E] rounded-2xl transform rotate-15" />
          </div>

          {/* Bottom-left orange corner */}
          <div className="absolute -bottom-4 -left-4 w-32 h-32 overflow-hidden">
            <div className="absolute bottom-0 left-0 w-24 h-24 bg-[#F1962E] rounded-2xl transform -rotate-15" />
          </div>

          {/* Image container */}
          <div className="aspect-[4/3] relative z-10">
            <img
              src={teacher.image}
              alt={teacher.name}
              className="w-full h-full object-cover rounded-2xl"
            />
          </div>

          {/* Orange decorative elements */}
          <div className="absolute -top-4 -right-4 w-24 h-24 bg-[#F1962E]/10 rounded-2xl -z-10" />
          <div className="absolute -bottom-4 -left-4 w-24 h-24 bg-[#F1962E]/10 rounded-2xl -z-10" />
        </div>
      </div>
    </section>
  );
};

export default TeacherCard;
