import { store } from "../store";
import { setShowLoginModal } from "../store/authSlice";

interface User {
  role: "admin" | "user";
  username: string;
  email: string;
  _id: string;
  // other user properties...
}

export const getCurrentUser = (): User | null => {
  const userStr = localStorage.getItem("user");
  return userStr ? JSON.parse(userStr) : null;
};

// Add a flag to track ongoing refresh
let isRefreshing = false;
let refreshPromise: Promise<string> | null = null;

export const refreshAccessToken = async (): Promise<string> => {
  // If already refreshing, return the existing promise
  if (isRefreshing) {
    return refreshPromise!;
  }

  const refreshToken = localStorage.getItem("refresh_token");
  //console.log("refreshToken", refreshToken);

  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    isRefreshing = true;
    refreshPromise = (async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/refresh`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh_token: refreshToken }),
        }
      );

      if (!response.ok) {
        //localStorage.clear(); // Clear all tokens if refresh fails
        throw new Error("Token refresh failed");
      }

      const data = await response.json();
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("user", JSON.stringify(data.user));
      return data.access_token;
    })();

    const newToken = await refreshPromise;

    //console.log("newToken", newToken);
    return newToken;
  } catch (error) {
    //localStorage.clear(); // Clear all tokens on any error
    throw error;
  } finally {
    isRefreshing = false;
    refreshPromise = null;
  }
};

export const handleTokenRefresh = (user: User | null) => {
  if (!user) {
    // No user, show login modal
    return { showLoginModal: true };
  }

  if (user.role === "admin") {
    // Admin user, redirect to admin login
    window.location.href = "/admin/login";
    return { showLoginModal: false };
  }

  // Regular user, show login modal
  return { showLoginModal: true };
};

export const logout = () => {
  console.log("logout");
  // Clear all authentication tokens
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user");

  // Clear any other stored data
  localStorage.clear();
  sessionStorage.clear();

  // Redirect based on current path
  const isAdminPath = window.location.pathname.startsWith("/admin");
  if (isAdminPath) {
    window.location.href = "/admin/login";
  } else {
    //show login modal
    store.dispatch(setShowLoginModal(true));
  }
};

export const handleTokenExpiration = async (error: any) => {
  const originalRequest = error.config;

  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    try {
      const newToken = await refreshAccessToken();
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      return originalRequest;
    } catch (refreshError) {
      // Only logout after refresh token fails
      const user = getCurrentUser();
      const { showLoginModal } = handleTokenRefresh(user);

      if (showLoginModal) {
        store.dispatch(setShowLoginModal(true));
      }

      // Move logout here, after handling refresh failure
      logout();
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
};
