import React, { FC } from "react";
import { Category } from "../../types";
import { Link } from "react-router-dom";

interface CategoryCardProps {
  category: Category;
}

const CategoryCard: FC<CategoryCardProps> = ({ category }) => {
  const placeholderImage = "/images/placeholder.jpg";

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = placeholderImage;
  };

  return (
    <Link to={`/courses/${category.slug}`} className="block">
      <div className="relative rounded-lg overflow-hidden aspect-[4/3]">
        <img
          src={category.image}
          alt={category.title}
          className="w-full h-full object-cover"
          onError={handleImageError}
        />
        <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
          <h3 className="text-white text-xl font-semibold">{category.title}</h3>
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
