import { FC, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useDispatch } from "react-redux";
import { setShowLoginModal } from "../store/authSlice";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";

interface Props {
  children: ReactNode;
}

const ProtectedRoute: FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && location.pathname === "/learning") {
      dispatch(setShowLoginModal(true));
    }
  }, [isAuthenticated, location.pathname, dispatch]);

  if (!isAuthenticated) {
    return (
      <>
        <LoginModal
          isOpen={!showSignUp}
          onClose={() => dispatch(setShowLoginModal(false))}
          onSignUpClick={() => setShowSignUp(true)}
        />
        <SignUpModal
          isOpen={showSignUp}
          onClose={() => dispatch(setShowLoginModal(false))}
          onLoginClick={() => setShowSignUp(false)}
        />
      </>
    );
  }

  return <>{children}</>;
};

export default ProtectedRoute;
