export const getInitialsAvatar = (username: string) => {
  const initial = username.charAt(0).toUpperCase();
  const colors = [
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-red-500",
    "bg-purple-500",
  ];

  // Generate consistent color based on username
  const colorIndex = username.length % colors.length;
  const bgColor = colors[colorIndex];

  return { initial, bgColor };
};
