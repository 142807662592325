import React, { FC, useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const scheduleSessionSchema = z.object({
  title: z.string().min(5, "Title must be at least 5 characters"),
  description: z.string().min(10, "Description must be at least 10 characters"),
  scheduledFor: z
    .string()
    .refine((date: string) => new Date(date) > new Date(), {
      message: "Session must be scheduled for future date",
    }),
  duration: z.number().min(15).max(180),
  courseId: z.string().min(1, "Course selection is required"),
  maxStudents: z.number().optional(),
});

type ScheduleSessionInput = z.infer<typeof scheduleSessionSchema>;

interface LiveSession {
  _id: string;
  title: string;
  description: string;
  scheduledFor: string;
  duration: number;
  status: "scheduled" | "live" | "ended" | "cancelled";
  totalSignups: number;
  maxStudents: number;
  courseId: {
    _id: string;
    title: string;
  };
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  courses: Array<{
    id: string;
    title: string;
  }>;
  scheduledFor?: string;
  editSession?: LiveSession | null;
}

const LiveSessionModal: FC<Props> = ({
  isOpen,
  onClose,
  courses,
  scheduledFor,
  editSession,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  //   console.log("scheduledFor", scheduledFor);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ScheduleSessionInput>({
    resolver: zodResolver(scheduleSessionSchema),
    defaultValues: {
      duration: 60,
      maxStudents: 50,
      scheduledFor: scheduledFor || undefined,
    },
  });

  const { mutate } = useMutation({
    mutationFn: async (data: any) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/live-classes/${
          editSession?._id || "schedule"
        }`,
        {
          method: editSession ? "PATCH" : "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) throw new Error("Failed to save session");
      return response.json();
    },
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ["live-sessions-calendar"] });
    },
  });

  useEffect(() => {
    if (editSession) {
      reset({
        title: editSession.title,
        description: editSession.description,
        scheduledFor: new Date(editSession.scheduledFor)
          .toISOString()
          .slice(0, 16),
        // ... other fields
      });
    }
  }, [editSession, reset]);

  const onSubmit = async (data: ScheduleSessionInput) => {
    setIsSubmitting(true);
    mutate(data);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-xl w-full rounded-xl bg-white p-6">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-xl font-semibold">
              Schedule New Live Session
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                {...register("title")}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Enter session title"
              />
              {errors.title && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.title.message}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                {...register("description")}
                rows={3}
                className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Enter session description"
              />
              {errors.description && (
                <p className="mt-1 text-sm text-red-600">
                  {errors.description.message}
                </p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Date & Time
                </label>
                <input
                  type="datetime-local"
                  {...register("scheduledFor")}
                  defaultValue={
                    scheduledFor
                      ? new Date(scheduledFor).toISOString().slice(0, 16)
                      : undefined
                  }
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                {errors.scheduledFor && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.scheduledFor.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Duration (minutes)
                </label>
                <input
                  type="number"
                  {...register("duration", { valueAsNumber: true })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  min={15}
                  max={180}
                />
                {errors.duration && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.duration.message}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Course
                </label>
                <select
                  {...register("courseId")}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                >
                  <option value="">Select a course</option>
                  {courses.map((course) => (
                    <option key={course.id} value={course.id}>
                      {course.title}
                    </option>
                  ))}
                </select>
                {errors.courseId && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.courseId.message}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Max Students (optional)
                </label>
                <input
                  type="number"
                  {...register("maxStudents", { valueAsNumber: true })}
                  className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  placeholder="Default: 50"
                />
                {errors.maxStudents && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.maxStudents.message}
                  </p>
                )}
              </div>
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50"
              >
                {isSubmitting ? "Scheduling..." : "Schedule Session"}
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default LiveSessionModal;
