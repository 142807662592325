import React, { FC, useState } from "react";
import EditorSelector from "../../../components/CodeEditors/EditorSelector";

const AdminCourseTools: FC = () => {
  const [selectedEditor, setSelectedEditor] = useState("javascript");

  const sampleCode = {
    react: `import React from 'react';

function App() {
  return (
    <div className="App">
      <h1>Hello React!</h1>
      <p>Start editing to see some magic happen!</p>
    </div>
  );
}

export default App;`,
    javascript: `// Write your JavaScript code here
console.log("Hello, World!");
const sum = (a, b) => a + b;
console.log(sum(5, 3));`,
    python: `# Write your Python code here
print("Hello, World!")
def sum(a, b):
    return a + b
print(sum(5, 3))`,
    flutter: `
import 'package:flutter/material.dart';

void main() {
  runApp(MyApp());
}

class MyApp extends StatelessWidget {
  @override
  Widget build(BuildContext context) {
    return MaterialApp(
      home: Scaffold(
        appBar: AppBar(
          title: Text('Flutter Demo'),
        ),
        body: Center(
          child: Text('Hello, Flutter!'),
        ),
      ),
    );
  }
}`,
    mysql: `SELECT 
      customers.name,
      orders.order_date,
      products.product_name
    FROM customers
    JOIN orders ON customers.id = orders.customer_id
    JOIN order_items ON orders.id = order_items.order_id
    JOIN products ON order_items.product_id = products.id
    WHERE orders.order_date >= DATE_SUB(NOW(), INTERVAL 30 DAY)
    ORDER BY orders.order_date DESC;`,
    postgresql: `WITH RECURSIVE subordinates AS (
      SELECT employee_id, manager_id, name, 1 as level
      FROM employees
      WHERE manager_id = 1
      
      UNION ALL
      
      SELECT e.employee_id, e.manager_id, e.name, s.level + 1
      FROM employees e
      INNER JOIN subordinates s ON s.employee_id = e.manager_id
    )
    SELECT * FROM subordinates
    ORDER BY level, employee_id;`,
    mongodb: `// Find all users who have made a purchase in the last 30 days
db.users.aggregate([
  {
    $lookup: {
      from: "orders",
      localField: "_id",
      foreignField: "userId",
      as: "userOrders"
    }
  },
  {
    $match: {
      "userOrders": {
        $elemMatch: {
          orderDate: {
            $gte: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
          }
        }
      }
    }
  },
  {
    $project: {
      name: 1,
      email: 1,
      totalOrders: { $size: "$userOrders" }
    }
  }
]);`,
    sqlite: `-- Create a virtual table using FTS5 for full-text search
CREATE VIRTUAL TABLE articles_fts USING fts5(
  title, 
  body,
  tags
);
// ... rest of SQLite sample`,
    redis: `# Set user profile data
HSET user:1000 username "john_doe" email "john@example.com" visits 10
// ... rest of Redis sample`,
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Course Tools</h1>
        <div className="flex gap-4">
          <select
            value={selectedEditor}
            onChange={(e) => setSelectedEditor(e.target.value)}
            className="px-3 py-2 border rounded-lg"
          >
            <option value="openml">OpenML Platform</option>
            <option value="cybersecurity">Cyber Security Lab</option>
            <option value="jupyter">Jupyter Lab</option>
            <option value="react">React Editor</option>
            <option value="javascript">JavaScript Editor</option>
            <option value="python">Python Playground</option>
            <option value="mysql">MySQL Editor</option>
            <option value="postgresql">PostgreSQL Editor</option>
            <option value="flutter">Flutter Editor (DartPad)</option>
            <option value="zapp">Flutter Editor (Zapp)</option>
            <option value="mongodb">MongoDB Editor</option>
            <option value="sqlite">SQLite Editor</option>
            <option value="redis">Redis Editor</option>
            <option value="mariadb">MariaDB Editor</option>
            <option value="oracle-plsql">Oracle PL/SQL Editor</option>
            <option value="mssql">MS SQL Server Editor</option>
            <option value="oracle">Oracle Database Editor</option>
          </select>
        </div>
      </div>

      <div className="h-[800px] mb-6">
        <EditorSelector
          editorType={selectedEditor}
          initialCode={sampleCode[selectedEditor as keyof typeof sampleCode]}
        />
      </div>
    </div>
  );
};

export default AdminCourseTools;
