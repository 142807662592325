import { FC, useEffect, useRef, useState } from "react";
import { Editor } from "@monaco-editor/react";
import { BaseEditorProps } from "./BaseEditor";

declare global {
  interface Window {
    loadPyodide: (config?: {
      indexURL: string;
      stdout?: (text: string) => void;
      stderr?: (text: string) => void;
    }) => Promise<any>;
    pyodide: any;
  }
}

const PythonPlayground: FC<BaseEditorProps> = ({
  code,
  onChange,
  readOnly = false,
}) => {
  const [output, setOutput] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const outputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initializePyodide = async () => {
      try {
        if (!window.loadPyodide) {
          setError(
            "Pyodide is not loaded. Please check your internet connection."
          );
          return;
        }

        // Redirect Python stdout/stderr to our console
        const pyodide = await window.loadPyodide({
          indexURL: "https://cdn.jsdelivr.net/pyodide/v0.23.4/full/",
          stdout: (text: string) => {
            setOutput((prev) => [...prev, `> ${text.trimEnd()}`]);
          },
          stderr: (text: string) => {
            setOutput((prev) => [...prev, `🔴 ${text.trimEnd()}`]);
          },
        });
        window.pyodide = pyodide;
        setIsLoading(false);
      } catch (err) {
        setError("Failed to initialize Python environment");
        console.error("Pyodide loading error:", err);
      }
    };

    initializePyodide();
  }, []);

  // Scroll to bottom when output changes
  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  const handleRunCode = async () => {
    if (error) {
      setOutput([`🔴 Environment Error: ${error}`]);
      return;
    }

    try {
      setOutput([]); // Clear previous output
      setOutput((prev) => [...prev, "> Running Python code..."]);
      const result = await window.pyodide.runPython(code);
      if (result !== undefined && result !== null) {
        setOutput((prev) => [...prev, `> ${result.toString()}`]);
      }
    } catch (err: any) {
      setOutput((prev) => [
        ...prev,
        `🔴 ${err.message || "An error occurred while running the code"}`,
      ]);
    }
  };

  const handleClearConsole = () => {
    setOutput([]);
  };

  if (error) {
    return (
      <div className="h-full flex items-center justify-center bg-red-50 text-red-600 p-4">
        {error}
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Python Playground</span>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleClearConsole}
            className="px-3 py-1 bg-gray-600 text-white rounded hover:bg-gray-700"
            disabled={isLoading}
          >
            Clear Console
          </button>
          <button
            onClick={handleRunCode}
            disabled={isLoading}
            className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700 disabled:opacity-50"
          >
            {isLoading ? "Loading Python..." : "Run Code"}
          </button>
        </div>
      </div>
      <div className="flex-1">
        <Editor
          height="100%"
          defaultLanguage="python"
          defaultValue={code}
          theme="vs-dark"
          onChange={(value: string | undefined) => onChange?.(value || "")}
          options={{
            readOnly,
            minimap: { enabled: false },
            fontSize: 14,
            lineNumbers: "on",
            scrollBeyondLastLine: false,
            automaticLayout: true,
          }}
        />
      </div>
      <div
        className="bg-[#1e1e1e] text-white p-2 h-48 overflow-auto font-mono text-sm"
        ref={outputRef}
      >
        {output.length === 0 ? (
          <div className="text-gray-400">Python Output</div>
        ) : (
          output.map((line, index) => (
            <div key={index} className="whitespace-pre-wrap">
              {line}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PythonPlayground;
