import { FC, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Whiteboard from "../../components/Whiteboard/Whiteboard";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import PollCreator from "../../components/PollCreator/PollCreator";
import PdfViewer from "../../components/PdfViewer/PdfViewer";
import { StreamService } from "../../services/StreamService";
import { AgoraService } from "../../services/AgoraService";
import { getRtcToken } from "../../services/AgoraApiServices";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";

interface DrawData {
  type: "start" | "draw" | "end";
  x: number;
  y: number;
  color: string;
  size: number;
}

interface Participant {
  id: string;
  name: string;
  handRaised: boolean;
  raisedAt?: Date;
}

interface ChatMessage {
  message: string;
  sender: {
    userId: string;
    name: string;
    role: string;
  };
  timestamp: Date;
}

interface PollOption {
  id: string;
  text: string;
}

const InstructorClassroom: FC = () => {
  const { roomId } = useParams();
  const [chatMessage, setChatMessage] = useState("");
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isWhiteboardActive, setIsWhiteboardActive] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [showEndConfirm, setShowEndConfirm] = useState(false);
  const [showRaisedHands, setShowRaisedHands] = useState(false);
  const [showPollCreator, setShowPollCreator] = useState(false);
  const [currentPdf, setCurrentPdf] = useState<File | null>(null);
  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [joinedParticipants, setJoinedParticipants] = useState<Participant[]>(
    []
  );
  const [leftParticipants, setLeftParticipants] = useState<Participant[]>([]);
  const raisedHands = participants.filter((p) => p.handRaised);
  const streamServiceRef = useRef<StreamService | null>(null);
  const navigate = useNavigate();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [remoteUsers, setRemoteUsers] = useState<{ uid: string }[]>([]);
  const remoteUserRef = useRef<IAgoraRTCRemoteUser | null>(null);

  const { data: sessionData } = useQuery({
    queryKey: ["classroom", roomId],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/live-classes/${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch session");
      return response.json();
    },
  });

  // Initialize streaming service and join room
  useEffect(() => {
    if (!roomId) return;

    const initializeStream = async () => {
      try {
        const service = new StreamService();
        streamServiceRef.current = service;
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        console.log(user);

        const userId = user.id || user._id || user.userId;
        const name = user.username || user.name;
        const role = user.role || "admin";

        console.log(userId, name, role);

        // Join room first
        service.socketInstance.emit("join-room", {
          roomId: roomId,
          userId: userId,
          role: role,
          name: name,
        });

        // Debug socket connection
        service.socketInstance.on("connect", () => {
          console.log("Socket connected, joining room:", roomId);
          setIsSocketConnected(true);
        });

        // Generate unique random 8 digit number for the instructor
        const uuid = Math.floor(10000000 + Math.random() * 90000000);
        console.log("uuid:::", uuid);
        agoraMethods(uuid, roomId);
      } catch (error) {
        console.error("Stream initialization failed:", error);
      }
    };

    initializeStream();
  }, [roomId, isMicOn]);

  const agoraMethods = async (userId: number, roomId: string) => {
    //initialize agora service
    const token = await getRtcToken(roomId, userId);
    // Make sure we have a valid token
    if (!token) {
      throw new Error("Failed to get valid Agora token");
    }

    console.log("token:::", token);
    const agoraService = new AgoraService("admin", token);
    agoraService.initializeAgoraClient("admin");

    // Setup event handlers before joining
    agoraService.setupEventListeners();

    agoraService.joinAsHost(roomId, userId, () => {
      const videoElement = document.getElementById("camera-video");
      if (
        videoElement &&
        agoraService.localVideoTrack &&
        !videoElement.hasChildNodes()
      ) {
        agoraService.localVideoTrack.play(videoElement);
      }
    });

    //handle remote users
    agoraService.getClient()?.on("user-published", async (user, mediaType) => {
      await agoraService.getClient()?.subscribe(user, mediaType);

      if (mediaType === "video") {
        setRemoteUsers((prev) => {
          if (prev.some((u) => u.uid === user.uid.toString())) {
            return prev;
          }
          return [...prev, { uid: user.uid.toString() }];
        });

        const remoteVideo = document.getElementById(`remote-video-${user.uid}`);
        if (remoteVideo && !remoteVideo.hasChildNodes()) {
          user.videoTrack?.play(remoteVideo);
        }
      }

      if (mediaType === "audio") {
        // Play audio immediately after subscription
        user.audioTrack?.play();
      }
    });

    // Add user-unpublished handler to stop audio
    agoraService.getClient()?.on("user-unpublished", (user, mediaType) => {
      if (mediaType === "audio") {
        user.audioTrack?.stop();
      }
      if (mediaType === "video") {
        setRemoteUsers((prev) => prev.filter((u) => u.uid !== user.uid));
      }
    });
  };

  // Control handlers
  const toggleMic = async () => {
    try {
      //await streamServiceRef.current?.toggleAudio(!isMicOn);
      remoteUserRef.current?.audioTrack?.setVolume(isMicOn ? 0 : 100);
      setIsMicOn(!isMicOn);
    } catch (error) {
      console.error("Error toggling microphone:", error);
    }
  };

  const toggleCamera = async () => {
    try {
      //await streamServiceRef.current?.toggleVideo(!isCameraOn);
      setIsCameraOn(!isCameraOn);
    } catch (error) {
      console.error("Error toggling camera:", error);
    }
  };

  const toggleScreenShare = async () => {
    try {
      if (!isScreenSharing) {
        // Start screen sharing

        setIsScreenSharing(true);
      } else {
        stopScreenSharing();
      }
    } catch (error) {
      console.error("Error sharing screen:", error);
      setIsScreenSharing(false);
    }
  };

  const stopScreenSharing = () => {
    setIsScreenSharing(false);
  };

  const handleWhiteboardToggle = async () => {
    try {
      if (!isWhiteboardActive) {
        // Set active first
        setIsWhiteboardActive(true);

        // Wait for canvas to mount
        await new Promise((resolve) => setTimeout(resolve, 100));
      } else {
        setIsWhiteboardActive(false);
      }
    } catch (error) {
      console.error("Error sharing whiteboard:", error);
      setIsWhiteboardActive(false);
    }
  };

  const handleWhiteboardDraw = (drawData: DrawData) => {
    if (!streamServiceRef.current) return;

    // Send draw data to all students in room
    streamServiceRef.current.socketInstance.emit("whiteboard-draw", {
      roomId,
      drawData: {
        ...drawData,
        timestamp: Date.now(),
        instructorId: sessionData.instId,
      },
    });
  };

  const handleEndSession = async () => {
    try {
      // Emit end session event
      streamServiceRef.current?.socketInstance.emit("end-session", { roomId });

      // Stop streaming and socket connection
      streamServiceRef.current?.stopStreaming();
      streamServiceRef.current?.socketInstance.disconnect();
      streamServiceRef.current = null;

      const agoraService = new AgoraService("admin", "token");
      agoraService.leaveChannel();

      //stop the video
      const videoElement = document.querySelector("video");
      if (videoElement) {
        videoElement.src = "";
      }

      //turn off device camera
      agoraService.turnOffCamera();

      //turn off device microphone
      agoraService.turnOffMicrophone();

      // Navigate away
      navigate("/admin/live-sessions");
    } catch (error) {
      console.error("Failed to end session:", error);
      alert("Failed to end session. Please try again.");
    }
  };

  const sendMessage = () => {
    if (!chatMessage.trim() || !streamServiceRef.current) return;

    const user = JSON.parse(localStorage.getItem("user") || "{}");

    // Create message object
    const newMessage: ChatMessage = {
      message: chatMessage.trim(),
      sender: {
        userId: sessionData.instId,
        name: user.username,
        role: "admin",
      },
      timestamp: new Date(),
    };

    // Update local state immediately
    setMessages((prev) => [...prev, newMessage]);

    // Send through socket
    streamServiceRef.current.socketInstance.emit("chat-message", {
      roomId,
      message: chatMessage.trim(),
      sender: newMessage.sender,
    });

    // Clear input
    setChatMessage("");

    // Force scroll to bottom
    setTimeout(scrollToBottom, 0);
  };

  const handleLowerHand = (participantId: string) => {
    // TODO: Implement WebSocket call to lower hand
    console.log("Lower hand for participant:", participantId);
  };

  const handlePdfUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file || !file.type.includes("pdf")) {
      alert("Please select a valid PDF file");
      return;
    }

    setCurrentPdf(file);
    setShowPdfViewer(true);

    // Reset input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCreatePoll = (question: string, options: PollOption[]) => {
    // TODO: Implement WebSocket send for poll
    console.log("Creating poll:", { question, options });
  };

  useEffect(() => {
    if (!streamServiceRef.current) return;

    // Listen for participant updates
    streamServiceRef.current.socketInstance.on(
      "participants-updated",
      ({
        participants,
        joinedParticipants,
        leftParticipants,
      }: {
        participants: Participant[];
        joinedParticipants: Participant[];
        leftParticipants: Participant[];
      }) => {
        //console.log("Participants updated:", participants);

        setParticipants(participants);
        setJoinedParticipants(joinedParticipants);
        setLeftParticipants(leftParticipants);

        // Optional: Show notifications
        if (joinedParticipants.length > 0) {
          console.log(`${joinedParticipants[0].name} joined the room`);
        }
        if (leftParticipants.length > 0) {
          console.log(`${leftParticipants[0].name} left the room`);
        }
      }
    );

    // Cleanup listener
    return () => {
      streamServiceRef.current?.socketInstance.off("participants-updated");
    };
  }, [roomId]);

  useEffect(() => {
    if (!streamServiceRef.current) return;

    // Listen for chat messages
    streamServiceRef.current.socketInstance.on(
      "chat-message",
      ({ message, sender, timestamp }: ChatMessage) => {
        setMessages((prev) => [...prev, { message, sender, timestamp }]);
      }
    );

    // Cleanup listener
    return () => {
      streamServiceRef.current?.socketInstance.off("chat-message");
    };
  }, [roomId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]); // Scroll when messages update

  return (
    <div className="h-screen bg-gray-100">
      {/* Top Navigation */}
      <div className="bg-white shadow-sm px-6 py-3 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h1 className="text-xl font-semibold">{sessionData?.title}</h1>
          <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-sm">
            Live
          </span>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-600">
            {participants.length} Participants
          </span>
          <button
            onClick={() => setShowEndConfirm(true)}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
          >
            End Session
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex h-[calc(100vh-64px)]">
        {/* Left Panel - Video/Screen Share */}
        <div className="flex-1 p-4">
          <div className="bg-white rounded-lg shadow-sm h-full flex flex-col">
            {/* Video Container */}
            <div className="flex-1 bg-gray-900 rounded-t-lg relative h-[calc(100vh-240px)]">
              {/* Main Video Container (Local Video) */}
              <div
                id="camera-video"
                className="w-full h-full bg-black rounded-lg"
              />

              {/* Remote Videos Floating Grid */}
              <div className="absolute top-4 right-4 flex flex-col gap-2 z-20">
                {remoteUsers.map((user) => (
                  <div
                    key={user.uid}
                    id={`remote-video-${user.uid}`}
                    className="w-64 h-48 bg-black rounded-lg shadow-lg border border-white/20"
                  />
                ))}
              </div>

              {/* Video Controls - now part of the container div */}
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/50 flex justify-center space-x-4 rounded-b-lg">
                <button
                  onClick={toggleMic}
                  className="p-3 bg-white/10 hover:bg-white/20 rounded-full relative group"
                >
                  <i
                    className={`fas fa-microphone${
                      isMicOn ? "" : "-slash"
                    } text-${isMicOn ? "white" : "red-400"}`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isMicOn ? "Turn Off Microphone" : "Turn On Microphone"}
                  </span>
                </button>

                <button
                  onClick={toggleCamera}
                  className="p-3 bg-white/10 hover:bg-white/20 rounded-full relative group"
                >
                  <i
                    className={`fas fa-video${
                      isCameraOn ? "" : "-slash"
                    } text-${isCameraOn ? "white" : "red-400"}`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isCameraOn ? "Turn Off Camera" : "Turn On Camera"}
                  </span>
                </button>

                <button
                  onClick={toggleScreenShare}
                  className="p-3 bg-white/10 hover:bg-white/20 rounded-full relative group"
                >
                  <i
                    className={`fas fa-desktop text-${
                      isScreenSharing ? "green-400" : "white"
                    }`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isScreenSharing ? "Stop Sharing" : "Share Screen"}
                  </span>
                </button>

                <button
                  onClick={handleWhiteboardToggle}
                  className="p-3 bg-white/10 hover:bg-white/20 rounded-full relative group"
                >
                  <i
                    className={`fas fa-chalkboard text-${
                      isWhiteboardActive ? "green-400" : "white"
                    }`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isWhiteboardActive ? "Hide Whiteboard" : "Show Whiteboard"}
                  </span>
                </button>
              </div>

              <div className="whiteboard-container">
                <Whiteboard
                  isActive={isWhiteboardActive}
                  onDraw={handleWhiteboardDraw}
                  onClose={handleWhiteboardToggle}
                />
              </div>
            </div>

            {/* Presentation Tools */}
            <div className="p-4 border-t flex justify-between items-center">
              <div className="flex space-x-3">
                <button
                  onClick={handleWhiteboardToggle}
                  className="p-2 hover:bg-gray-100 rounded relative group"
                >
                  <i
                    className={`fas fa-chalkboard text-${
                      isWhiteboardActive ? "blue-600" : "gray-600"
                    }`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isWhiteboardActive ? "Hide Whiteboard" : "Show Whiteboard"}
                  </span>
                </button>

                <div className="relative group">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".pdf"
                    onChange={handlePdfUpload}
                    className="hidden"
                  />
                  <button
                    onClick={() => fileInputRef.current?.click()}
                    className="p-2 hover:bg-gray-100 rounded"
                  >
                    <i className="fas fa-file-pdf text-gray-600" />
                    <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                      Share PDF
                    </span>
                  </button>
                </div>

                <button
                  onClick={() => setShowPollCreator(true)}
                  className="p-2 hover:bg-gray-100 rounded relative group"
                >
                  <i className="fas fa-poll text-gray-600" />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    Create Poll
                  </span>
                </button>
              </div>
              <div className="relative">
                <button
                  onClick={() => setShowRaisedHands(!showRaisedHands)}
                  className="p-2 hover:bg-gray-100 rounded flex items-center space-x-2"
                >
                  <i className="fas fa-hand-paper text-gray-600" />
                  <span className="text-sm">
                    {raisedHands.length} Raised Hands
                  </span>
                </button>

                {showRaisedHands && raisedHands.length > 0 && (
                  <div className="absolute bottom-full right-0 mb-2 w-64 bg-white rounded-lg shadow-lg border z-50">
                    <div className="p-3 border-b">
                      <h3 className="font-medium">Raised Hands</h3>
                    </div>
                    <div className="max-h-64 overflow-y-auto">
                      {raisedHands
                        .sort(
                          (a, b) =>
                            (a.raisedAt?.getTime() || 0) -
                            (b.raisedAt?.getTime() || 0)
                        )
                        .map((participant) => (
                          <div
                            key={participant.id}
                            className="p-3 hover:bg-gray-50 flex items-center justify-between border-b last:border-b-0"
                          >
                            <div>
                              <p className="font-medium">{participant.name}</p>
                              <p className="text-xs text-gray-500">
                                {participant.raisedAt &&
                                  `Raised ${formatTimeAgo(
                                    participant.raisedAt
                                  )}`}
                              </p>
                            </div>
                            <button
                              onClick={() => handleLowerHand(participant.id)}
                              className="text-xs text-blue-600 hover:text-blue-700"
                            >
                              Lower Hand
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Right Panel - Chat & Participants */}
        <div className="w-80 bg-white border-l">
          <div className="h-full flex flex-col">
            {/* Tabs */}
            <div className="flex border-b">
              <button
                onClick={() => setShowParticipants(false)}
                className={`flex-1 px-4 py-3 text-sm font-medium ${
                  !showParticipants
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-600 hover:text-gray-800"
                }`}
              >
                Chat
              </button>
              <button
                onClick={() => setShowParticipants(true)}
                className={`flex-1 px-4 py-3 text-sm font-medium ${
                  showParticipants
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-600 hover:text-gray-800"
                }`}
              >
                Participants
              </button>
            </div>

            {/* Content */}

            {/* Chat Input - Only show when chat tab is active */}
            {!showParticipants ? (
              <>
                <div className="flex-1 overflow-y-auto p-4">
                  <div className="space-y-4">
                    {messages.map((msg, index) => (
                      <div key={index} className="flex items-start space-x-3">
                        <img
                          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                            msg.sender.name
                          )}`}
                          alt={msg.sender.name}
                          className="w-8 h-8 rounded-full"
                        />
                        <div>
                          <p className="text-sm font-medium">
                            {msg.sender.name}
                          </p>
                          <p className="text-sm text-gray-600">{msg.message}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="p-4 border-t">
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                      onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                      placeholder="Type a message..."
                      className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                      onClick={sendMessage}
                      disabled={!chatMessage.trim()}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="space-y-3 p-4">
                {participants.map((participant) => (
                  <div
                    key={participant.id}
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center space-x-3">
                      <img
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          participant.name
                        )}`}
                        alt={participant.name}
                        className="w-8 h-8 rounded-full"
                      />
                      <span className="font-medium">{participant.name}</span>
                    </div>
                    {participant.handRaised && (
                      <i className="fas fa-hand-paper text-blue-500" />
                    )}
                  </div>
                ))}
                {joinedParticipants.map((participant) => (
                  <div key={participant.id} className="text-sm text-green-600">
                    {participant.name} joined
                  </div>
                ))}
                {leftParticipants.map((participant) => (
                  <div key={participant.id} className="text-sm text-red-600">
                    {participant.name} left
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <ConfirmModal
        isOpen={showEndConfirm}
        onClose={() => setShowEndConfirm(false)}
        onConfirm={handleEndSession}
        title="End Session"
        message="Are you sure you want to end this session? All participants will be disconnected."
      />

      <PollCreator
        isOpen={showPollCreator}
        onClose={() => setShowPollCreator(false)}
        onCreatePoll={handleCreatePoll}
      />

      {showPdfViewer && currentPdf && (
        <PdfViewer
          file={currentPdf}
          onClose={() => {
            setShowPdfViewer(false);
            setCurrentPdf(null);
          }}
        />
      )}

      {!isSocketConnected && (
        <div className="bg-red-100 text-red-700 px-4 py-2 rounded-md mb-4">
          Disconnected from server. Trying to reconnect...
        </div>
      )}
    </div>
  );
};

// Helper function to format time
const formatTimeAgo = (date: Date) => {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  if (seconds < 60) return "just now";
  const minutes = Math.floor(seconds / 60);
  return `${minutes}m ago`;
};

export default InstructorClassroom;
