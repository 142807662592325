import { FC, useEffect, useRef, useState } from "react";
import { BaseEditorProps } from "./BaseEditor";
import { Editor } from "@monaco-editor/react";

const JavaScriptEditor: FC<BaseEditorProps> = ({
  code,
  onChange,
  language = "javascript",
  theme = "vs-dark",
  readOnly = false,
}) => {
  const [output, setOutput] = useState<string[]>([]);
  const outputRef = useRef<HTMLDivElement>(null);

  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      onChange?.(value);
    }
  };

  useEffect(() => {
    // Store original console methods
    const originalLog = console.log;
    const originalError = console.error;
    const originalWarn = console.warn;
    const originalInfo = console.info;

    // Helper to format any type of data
    const formatOutput = (args: any[]) => {
      return args
        .map((arg) => {
          if (typeof arg === "object") {
            return JSON.stringify(arg, null, 2);
          }
          return String(arg);
        })
        .join(" ");
    };

    // Override console methods
    console.log = (...args) => {
      originalLog.apply(console, args);
      setOutput((prev) => [...prev, `> ${formatOutput(args)}`]);
    };

    console.error = (...args) => {
      originalError.apply(console, args);
      setOutput((prev) => [...prev, `🔴 ${formatOutput(args)}`]);
    };

    console.warn = (...args) => {
      originalWarn.apply(console, args);
      setOutput((prev) => [...prev, `⚠️ ${formatOutput(args)}`]);
    };

    console.info = (...args) => {
      originalInfo.apply(console, args);
      setOutput((prev) => [...prev, `ℹ️ ${formatOutput(args)}`]);
    };

    // Cleanup
    return () => {
      console.log = originalLog;
      console.error = originalError;
      console.warn = originalWarn;
      console.info = originalInfo;
    };
  }, []);

  // Scroll to bottom when output changes
  useEffect(() => {
    if (outputRef.current) {
      outputRef.current.scrollTop = outputRef.current.scrollHeight;
    }
  }, [output]);

  const handleRunCode = () => {
    try {
      setOutput([]); // Clear previous output

      // Create a sandboxed iframe
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      document.body.appendChild(iframe);

      // Override console methods in the iframe
      const iframeWindow = iframe.contentWindow as any;
      const script = `
        const __log = [];
        console.log = (...args) => __log.push(['log', args]);
        console.error = (...args) => __log.push(['error', args]);
        console.warn = (...args) => __log.push(['warn', args]);
        console.info = (...args) => __log.push(['info', args]);
        
        try {
          const result = eval(${JSON.stringify(code)});
          if (result !== undefined) {
            console.log(result);
          }
        } catch (error) {
          console.error(error.message);
        }
        
        __log;
      `;

      // Execute code and get logs
      const logs = iframeWindow.eval(script);

      // Process logs
      logs.forEach(([type, args]: [string, any[]]) => {
        const formattedArgs = args
          .map((arg) => {
            if (typeof arg === "object") {
              return JSON.stringify(arg, null, 2);
            }
            return String(arg);
          })
          .join(" ");

        switch (type) {
          case "error":
            setOutput((prev) => [...prev, `🔴 ${formattedArgs}`]);
            break;
          case "warn":
            setOutput((prev) => [...prev, `⚠️ ${formattedArgs}`]);
            break;
          case "info":
            setOutput((prev) => [...prev, `ℹ️ ${formattedArgs}`]);
            break;
          default:
            setOutput((prev) => [...prev, `> ${formattedArgs}`]);
        }
      });

      // Cleanup
      document.body.removeChild(iframe);
    } catch (error: any) {
      setOutput((prev) => [...prev, `🔴 ${error.message}`]);
    }
  };

  const handleClearConsole = () => {
    setOutput([]);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">JavaScript Editor</span>
        </div>
        <div className="flex gap-2">
          <button
            onClick={handleClearConsole}
            className="px-3 py-1 bg-gray-600 text-white rounded hover:bg-gray-700"
          >
            Clear Console
          </button>
          <button
            onClick={handleRunCode}
            className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700"
          >
            Run Code
          </button>
        </div>
      </div>
      <div className="flex-1">
        <Editor
          height="100%"
          defaultLanguage={language}
          defaultValue={code}
          theme={theme}
          onChange={handleEditorChange}
          options={{
            readOnly,
            minimap: { enabled: false },
            fontSize: 14,
            lineNumbers: "on",
            scrollBeyondLastLine: false,
            automaticLayout: true,
          }}
        />
      </div>
      <div
        className="bg-[#1e1e1e] text-white p-2 h-48 overflow-auto font-mono text-sm"
        ref={outputRef}
      >
        {output.length === 0 ? (
          <div className="text-gray-400">Console Output</div>
        ) : (
          output.map((line, index) => (
            <div key={index} className="whitespace-pre-wrap">
              {line}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default JavaScriptEditor;
