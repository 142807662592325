import React, { FC, useState, useEffect } from "react";
import api from "../../../services/api";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

interface SubscriptionStats {
  totalSubscribers: number;
  activeSubscribers: number;
  canceledSubscribers: number;
  totalRevenue: number;
  monthlyRevenue: number;
  averageSubscriptionDuration: number;
}

interface Subscriber {
  _id: string;
  userId: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  status: "active" | "canceled";
  startDate: string;
  endDate: string;
  plan: string;
  amount: number;
}

const AdminSubscribers: FC = () => {
  const [stats, setStats] = useState<SubscriptionStats | null>(null);
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get("/subscriptions/stats/overview");
        setStats(data);
      } catch (err) {
        console.error("Error fetching stats:", err);
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    const fetchSubscribers = async () => {
      try {
        const { data } = await api.get("/subscriptions?showInactive=false");
        setSubscribers(data);
      } catch (err) {
        console.error("Error fetching subscribers:", err);
        setError(err instanceof Error ? err.message : "An error occurred");
      }
    };

    fetchStats();
    fetchSubscribers();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <LoadingSpinner size="large" color="#2563eb" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Subscribers Overview</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Total Subscribers */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">
                Total Subscribers
              </p>
              <h3 className="text-2xl font-bold text-gray-900">
                {stats?.totalSubscribers || 0}
              </h3>
            </div>
            <div className="bg-blue-100 rounded-full p-3">
              <i className="fas fa-users text-blue-600 text-xl"></i>
            </div>
          </div>
        </div>

        {/* Active Subscribers */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">
                Active Subscribers
              </p>
              <h3 className="text-2xl font-bold text-gray-900">
                {stats?.activeSubscribers || 0}
              </h3>
            </div>
            <div className="bg-green-100 rounded-full p-3">
              <i className="fas fa-user-check text-green-600 text-xl"></i>
            </div>
          </div>
        </div>

        {/* Canceled Subscribers */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">
                Canceled Subscribers
              </p>
              <h3 className="text-2xl font-bold text-gray-900">
                {stats?.canceledSubscribers || 0}
              </h3>
            </div>
            <div className="bg-red-100 rounded-full p-3">
              <i className="fas fa-user-times text-red-600 text-xl"></i>
            </div>
          </div>
        </div>

        {/* Total Revenue */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Total Revenue</p>
              <h3 className="text-2xl font-bold text-gray-900">
                ${stats?.totalRevenue?.toFixed(2) || "0.00"}
              </h3>
            </div>
            <div className="bg-purple-100 rounded-full p-3">
              <i className="fas fa-dollar-sign text-purple-600 text-xl"></i>
            </div>
          </div>
        </div>

        {/* Monthly Revenue */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">
                Monthly Revenue
              </p>
              <h3 className="text-2xl font-bold text-gray-900">
                ${stats?.monthlyRevenue?.toFixed(2) || "0.00"}
              </h3>
            </div>
            <div className="bg-yellow-100 rounded-full p-3">
              <i className="fas fa-chart-line text-yellow-600 text-xl"></i>
            </div>
          </div>
        </div>

        {/* Average Subscription Duration */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">
                Avg. Subscription Duration
              </p>
              <h3 className="text-2xl font-bold text-gray-900">
                {stats?.averageSubscriptionDuration?.toFixed(1) || "0"} months
              </h3>
            </div>
            <div className="bg-indigo-100 rounded-full p-3">
              <i className="fas fa-clock text-indigo-600 text-xl"></i>
            </div>
          </div>
        </div>
      </div>

      {/* Subscribers Table */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Subscribers List</h2>
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  User
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Plan
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Start Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  End Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {subscribers.map((subscriber) => (
                <tr key={subscriber._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {subscriber.userId.firstName}{" "}
                          {subscriber.userId.lastName}
                        </div>
                        <div className="text-sm text-gray-500">
                          {subscriber.userId.email}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {subscriber.plan}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      ${subscriber.amount.toFixed(2)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        subscriber.status === "active"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {subscriber.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(subscriber.startDate).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {subscriber.endDate
                      ? new Date(subscriber.endDate).toLocaleDateString()
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminSubscribers;
