import { FC, useRef, useEffect, useState } from "react";
import TextInputModal from "../TextInputModal/TextInputModal";

type Tool = "pen" | "eraser" | "rectangle" | "circle" | "line" | "text";

interface Props {
  isActive: boolean;
  onDraw?: (data: DrawData) => void;
  onClose?: () => void;
}

interface DrawData {
  type: "start" | "draw" | "end";
  x: number;
  y: number;
  color: string;
  size: number;
}

interface Shape {
  id: string;
  type: "rectangle" | "circle" | "line";
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  color: string;
  size: number;
}

const Whiteboard: FC<Props> = ({ isActive, onDraw, onClose }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [color, setColor] = useState("#000000");
  const [brushSize, setBrushSize] = useState(5);
  const [selectedTool, setSelectedTool] = useState<Tool>("pen");
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(
    null
  );
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [currentShape, setCurrentShape] = useState<Shape | null>(null);
  const [selectedShape, setSelectedShape] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [showTextInput, setShowTextInput] = useState(false);
  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set canvas size
    canvas.width = canvas.offsetWidth * 2;
    canvas.height = canvas.offsetHeight * 2;
    canvas.style.width = `${canvas.offsetWidth}px`;
    canvas.style.height = `${canvas.offsetHeight}px`;

    // Setup context
    const context = canvas.getContext("2d");
    if (!context) return;

    context.scale(2, 2);
    context.lineCap = "round";
    context.strokeStyle = color;
    context.lineWidth = brushSize;
    contextRef.current = context;
  }, [color, brushSize]);

  const tools = [
    { id: "pen", icon: "fa-pen", label: "Pen" },
    { id: "eraser", icon: "fa-eraser", label: "Eraser" },
    { id: "rectangle", icon: "fa-square", label: "Rectangle" },
    { id: "circle", icon: "fa-circle", label: "Circle" },
    { id: "line", icon: "fa-minus", label: "Line" },
    { id: "text", icon: "fa-font", label: "Text" },
  ];

  const startDrawing = ({ nativeEvent }: React.MouseEvent) => {
    const { offsetX, offsetY } = nativeEvent;

    // Check if clicking on existing shape
    const clickedShape = shapes.find((shape) =>
      isPointInShape(offsetX, offsetY, shape)
    );

    if (clickedShape) {
      setSelectedShape(clickedShape.id);
      setIsDragging(true);
      setDragOffset({
        x: offsetX - clickedShape.startX,
        y: offsetY - clickedShape.startY,
      });
      return;
    }

    setStartPoint({ x: offsetX, y: offsetY });
    if (selectedTool === "pen" || selectedTool === "eraser") {
      contextRef.current?.beginPath();
      contextRef.current?.moveTo(offsetX, offsetY);
    }
    setIsDrawing(true);
  };

  const draw = ({ nativeEvent }: React.MouseEvent) => {
    const { offsetX, offsetY } = nativeEvent;

    // Handle shape dragging
    if (isDragging && selectedShape) {
      const shapeIndex = shapes.findIndex((s) => s.id === selectedShape);
      if (shapeIndex !== -1) {
        const shape = shapes[shapeIndex];
        const dx = offsetX - dragOffset.x;
        const dy = offsetY - dragOffset.y;
        const width = shape.endX - shape.startX;
        const height = shape.endY - shape.startY;

        const updatedShape = {
          ...shape,
          startX: dx,
          startY: dy,
          endX: dx + width,
          endY: dy + height,
        };

        const newShapes = [...shapes];
        newShapes[shapeIndex] = updatedShape;
        setShapes(newShapes);
        redrawCanvas();
      }
      return;
    }

    if (!isDrawing) return;

    const context = contextRef.current;
    if (!context || !startPoint) return;

    context.strokeStyle = selectedTool === "eraser" ? "#ffffff" : color;
    context.lineWidth = selectedTool === "eraser" ? brushSize * 2 : brushSize;

    switch (selectedTool) {
      case "pen":
      case "eraser":
        context.lineTo(offsetX, offsetY);
        context.stroke();
        break;

      case "rectangle":
      case "circle":
      case "line":
        // Update current shape
        setCurrentShape({
          id: Date.now().toString(),
          type: selectedTool,
          startX: startPoint.x,
          startY: startPoint.y,
          endX: offsetX,
          endY: offsetY,
          color,
          size: brushSize,
        });
        // Redraw all shapes
        redrawCanvas();
        break;
    }
  };

  const finishDrawing = () => {
    if (isDragging) {
      setIsDragging(false);
      setSelectedShape(null);
      return;
    }

    if (currentShape) {
      setShapes([...shapes, { ...currentShape, id: Date.now().toString() }]);
      setCurrentShape(null);
    }
    setIsDrawing(false);
    contextRef.current?.closePath();
  };

  const redrawCanvas = () => {
    const context = contextRef.current;
    if (!context) return;

    // Clear canvas
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    // Draw all saved shapes
    shapes.forEach(drawShape);

    // Draw current shape if exists
    if (currentShape) {
      drawShape(currentShape);
    }
  };

  const drawShape = (shape: Shape) => {
    const context = contextRef.current;
    if (!context) return;

    context.strokeStyle = shape.color;
    context.lineWidth = shape.size;
    context.beginPath();

    switch (shape.type) {
      case "rectangle":
        context.strokeRect(
          shape.startX,
          shape.startY,
          shape.endX - shape.startX,
          shape.endY - shape.startY
        );
        break;

      case "circle":
        const radius = Math.sqrt(
          Math.pow(shape.endX - shape.startX, 2) +
            Math.pow(shape.endY - shape.startY, 2)
        );
        context.arc(shape.startX, shape.startY, radius, 0, 2 * Math.PI);
        context.stroke();
        break;

      case "line":
        context.moveTo(shape.startX, shape.startY);
        context.lineTo(shape.endX, shape.endY);
        context.stroke();
        break;
    }
  };

  const handleTextClick = (e: React.MouseEvent) => {
    if (selectedTool !== "text") return;

    setTextPosition({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    });
    setShowTextInput(true);
  };

  const handleAddText = (text: string) => {
    const context = contextRef.current;
    if (!context) return;

    context.font = `${brushSize * 2}px Arial`;
    context.fillStyle = color;
    context.fillText(text, textPosition.x, textPosition.y);
  };

  const clearCanvas = () => {
    const context = contextRef.current;
    if (!context) return;
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    setShapes([]);
    setCurrentShape(null);
  };

  const isPointInShape = (x: number, y: number, shape: Shape): boolean => {
    const padding = 5; // Click tolerance

    switch (shape.type) {
      case "rectangle":
        return (
          x >= shape.startX - padding &&
          x <= shape.endX + padding &&
          y >= shape.startY - padding &&
          y <= shape.endY + padding
        );

      case "circle":
        const radius = Math.sqrt(
          Math.pow(shape.endX - shape.startX, 2) +
            Math.pow(shape.endY - shape.startY, 2)
        );
        const distance = Math.sqrt(
          Math.pow(x - shape.startX, 2) + Math.pow(y - shape.startY, 2)
        );
        return distance <= radius + padding;

      case "line":
        const lineLength = Math.sqrt(
          Math.pow(shape.endX - shape.startX, 2) +
            Math.pow(shape.endY - shape.startY, 2)
        );
        const d1 = Math.sqrt(
          Math.pow(x - shape.startX, 2) + Math.pow(y - shape.startY, 2)
        );
        const d2 = Math.sqrt(
          Math.pow(x - shape.endX, 2) + Math.pow(y - shape.endY, 2)
        );
        return Math.abs(d1 + d2 - lineLength) <= padding;

      default:
        return false;
    }
  };

  // Update canvas style to show it's draggable
  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.style.cursor = isDragging ? "grabbing" : "default";
    }
  }, [isDragging]);

  if (!isActive) return null;

  return (
    <>
      <div className="absolute inset-0 bg-white z-10">
        <div className="p-4 flex justify-between items-center bg-gray-100">
          <div className="flex items-center space-x-4">
            <div className="flex space-x-2 border-r pr-4">
              {tools.map((tool) => (
                <button
                  key={tool.id}
                  onClick={() => setSelectedTool(tool.id as Tool)}
                  className={`p-2 rounded ${
                    selectedTool === tool.id
                      ? "bg-blue-100 text-blue-600"
                      : "hover:bg-gray-200"
                  }`}
                  title={tool.label}
                >
                  <i className={`fas ${tool.icon}`} />
                </button>
              ))}
            </div>

            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              className="w-8 h-8"
            />
            <input
              type="range"
              min="1"
              max="20"
              value={brushSize}
              onChange={(e) => setBrushSize(parseInt(e.target.value))}
              className="w-32"
            />

            <button
              onClick={clearCanvas}
              className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Clear
            </button>
          </div>

          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-200 rounded-full"
          >
            <i className="fas fa-times text-gray-600" />
          </button>
        </div>

        <canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseUp={finishDrawing}
          onMouseMove={draw}
          onMouseLeave={() => setIsDrawing(false)}
          onClick={handleTextClick}
          className="w-full h-[calc(100%-64px)]"
        />
      </div>

      <TextInputModal
        isOpen={showTextInput}
        onClose={() => setShowTextInput(false)}
        onConfirm={handleAddText}
      />
    </>
  );
};

export default Whiteboard;
