import React, { FC } from "react";
import { Testimonial } from "../../types";

interface TestimonialCardProps {
  testimonial: Testimonial;
  isSelected?: boolean;
  onClick?: () => void;
}

const TestimonialCard: FC<TestimonialCardProps> = ({
  testimonial,
  isSelected = false,
  onClick,
}) => {
  return (
    <div
      className={`p-6 rounded-2xl cursor-pointer transition-colors relative ${
        isSelected ? "bg-gray-100" : "hover:bg-gray-50"
      }`}
      onClick={onClick}
    >
      {/* Opening quote */}
      <div className="absolute -left-2 -top-2 text-orange-500/20 text-[80px] leading-none font-serif">
        "
      </div>
      <p className="text-gray-500 leading-relaxed text-lg relative pl-4">
        {testimonial.content}
      </p>
      {/* Closing quote */}
      <div className="absolute -bottom-8 right-4 text-orange-500/20 text-[80px] leading-none font-serif rotate-180">
        "
      </div>
    </div>
  );
};

export default TestimonialCard;
