import { FC, useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

interface LiveSession {
  _id: string;
  title: string;
  description: string;
  scheduledFor: string;
  duration: number;
  status: "scheduled" | "live" | "ended" | "cancelled";
  totalSignups: number;
  maxStudents: number;
  courseId: {
    _id: string;
    title: string;
  };
  roomId: string;
  meetingId: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  session: LiveSession | null;
}

const editSessionSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().min(1, "Description is required"),
  scheduledFor: z.string().min(1, "Date and time is required"),
  duration: z.number().min(15, "Duration must be at least 15 minutes"),
  maxStudents: z.number().min(1, "Maximum students is required"),
});

type EditSessionInput = z.infer<typeof editSessionSchema>;

const EditSessionModal: FC<Props> = ({ isOpen, onClose, session }) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EditSessionInput>({
    resolver: zodResolver(editSessionSchema),
    defaultValues: {
      title: session?.title,
      description: session?.description,
      scheduledFor: session?.scheduledFor
        ? new Date(session.scheduledFor).toISOString().slice(0, 16)
        : undefined,
      duration: session?.duration,
      maxStudents: session?.maxStudents,
    },
  });

  const { mutate } = useMutation({
    mutationFn: async (data: EditSessionInput) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/live-classes/${session?._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
          body: JSON.stringify({
            title: data.title,
            description: data.description,
            scheduledFor: data.scheduledFor,
            duration: data.duration,
            maxStudents: data.maxStudents,
            status: session?.status,
            courseId: session?.courseId._id,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to update session");
      return response.json();
    },
    onSuccess: () => {
      setIsSubmitting(false);
      onClose();
      queryClient.invalidateQueries({ queryKey: ["live-sessions-calendar"] });
    },
    onError: () => {
      setIsSubmitting(false);
    },
  });

  const onSubmit = (data: EditSessionInput) => {
    setIsSubmitting(true);
    mutate(data, {
      onSuccess: () => {
        setIsSubmitting(false);
        onClose();
        queryClient.invalidateQueries({ queryKey: ["live-sessions-calendar"] });
      },
      onError: () => {
        setIsSubmitting(false);
      },
    });
  };

  useEffect(() => {
    if (session) {
      reset({
        title: session.title,
        description: session.description,
        scheduledFor: new Date(session.scheduledFor).toISOString().slice(0, 16),
        duration: session.duration,
        maxStudents: session.maxStudents,
      });
    }
  }, [session, reset]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Edit Live Session</h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <i className="fas fa-times" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Title
            </label>
            <input
              type="text"
              {...register("title")}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.title && (
              <p className="mt-1 text-sm text-red-600">
                {errors.title.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              {...register("description")}
              rows={3}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.description && (
              <p className="mt-1 text-sm text-red-600">
                {errors.description.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Date & Time
            </label>
            <input
              type="datetime-local"
              {...register("scheduledFor")}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.scheduledFor && (
              <p className="mt-1 text-sm text-red-600">
                {errors.scheduledFor.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Duration (minutes)
            </label>
            <input
              type="number"
              {...register("duration", { valueAsNumber: true })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.duration && (
              <p className="mt-1 text-sm text-red-600">
                {errors.duration.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Maximum Students
            </label>
            <input
              type="number"
              {...register("maxStudents", { valueAsNumber: true })}
              className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
            {errors.maxStudents && (
              <p className="mt-1 text-sm text-red-600">
                {errors.maxStudents.message}
              </p>
            )}
          </div>

          <div className="flex justify-between items-center mt-6">
            <div className="flex justify-end space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm font-medium disabled:opacity-50"
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSessionModal;
