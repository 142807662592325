import React, { FC, useState, useEffect } from "react";
import api from "../../../services/api";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import CreatePlanModal from "./CreatePlanModal";
import EditPlanModal from "./EditPlanModal";
import ConfirmModal from "../../../components/ConfirmModal";

interface SubscriptionPlan {
  _id: string;
  name: string;
  price: number;
  duration: number; // in months
  description: string;
  features: string[];
  isPopular: boolean;
  isActive: boolean;
}

const AdminSubscriptions: FC = () => {
  const [plans, setPlans] = useState<SubscriptionPlan[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editingPlan, setEditingPlan] = useState<SubscriptionPlan | null>(null);
  const [deletingPlan, setDeletingPlan] = useState<string | null>(null);
  const [planToDelete, setPlanToDelete] = useState<string | null>(null);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/subscriptions/plans");
      setPlans(data);
    } catch (error) {
      console.error("Failed to fetch plans:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePlan = async (planData: Omit<SubscriptionPlan, "_id">) => {
    try {
      await api.post("/subscriptions/plans", planData);
      fetchPlans();
      setShowCreateModal(false);
    } catch (error) {
      console.error("Failed to create plan:", error);
    }
  };

  const handleUpdatePlan = async (
    id: string,
    planData: Partial<SubscriptionPlan>
  ) => {
    try {
      await api.patch(`/subscriptions/plans/${id}`, planData);
      fetchPlans();
      setEditingPlan(null);
    } catch (error) {
      console.error("Failed to update plan:", error);
    }
  };

  const handleToggleStatus = async (id: string, isActive: boolean) => {
    try {
      await api.patch(`/subscriptions/plans/${id}/status`, { isActive });
      fetchPlans();
    } catch (error) {
      console.error("Failed to toggle plan status:", error);
    }
  };

  const handleDeleteConfirm = async () => {
    if (!planToDelete) return;

    try {
      setDeletingPlan(planToDelete);
      await api.delete(`/subscriptions/plans/${planToDelete}`);
      fetchPlans();
    } catch (error) {
      console.error("Failed to delete plan:", error);
    } finally {
      setDeletingPlan(null);
      setPlanToDelete(null);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Subscription Plans</h1>
        <button
          onClick={() => setShowCreateModal(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          Add New Plan
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {plans.map((plan) => (
          <div
            key={plan._id}
            className={`bg-white rounded-lg shadow-sm p-6 ${
              plan.isPopular ? "ring-2 ring-blue-500" : ""
            }`}
          >
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-xl font-bold">{plan.name}</h3>
                <p className="text-gray-500">${plan.price}/month</p>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setEditingPlan(plan)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  <i className="fas fa-edit" />
                </button>
                <button
                  onClick={() => setPlanToDelete(plan._id)}
                  disabled={deletingPlan === plan._id}
                  className="text-red-600 hover:text-red-700 disabled:opacity-50"
                >
                  {deletingPlan === plan._id ? (
                    <LoadingSpinner size="small" />
                  ) : (
                    <i className="fas fa-trash" />
                  )}
                </button>
                <button
                  onClick={() => handleToggleStatus(plan._id, !plan.isActive)}
                  className={`${
                    plan.isActive ? "text-green-600" : "text-red-600"
                  } hover:opacity-80`}
                >
                  <i
                    className={`fas fa-${plan.isActive ? "check" : "times"}`}
                  />
                </button>
              </div>
            </div>

            <ul className="space-y-2 mb-4">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-center gap-2">
                  <i className="fas fa-check text-green-500" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>

            {plan.isPopular && (
              <span className="inline-block bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded">
                Popular
              </span>
            )}
          </div>
        ))}
      </div>

      <CreatePlanModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSubmit={handleCreatePlan}
      />

      {editingPlan && (
        <EditPlanModal
          isOpen={true}
          plan={editingPlan}
          onClose={() => setEditingPlan(null)}
          onSubmit={(data) => handleUpdatePlan(editingPlan._id, data)}
        />
      )}

      <ConfirmModal
        isOpen={!!planToDelete}
        title="Delete Plan"
        message="Are you sure you want to delete this subscription plan? This action cannot be undone."
        onConfirm={handleDeleteConfirm}
        onCancel={() => setPlanToDelete(null)}
      />
    </div>
  );
};

export default AdminSubscriptions;
