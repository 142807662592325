import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import "buffer";
import process from "process";
window.process = process;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
