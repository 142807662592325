import { FC, useState } from "react";
import { BaseEditorProps } from "./BaseEditor";

const CyberSecurityEditor: FC<BaseEditorProps> = () => {
  const [platform, setPlatform] = useState<"tryhackme" | "hackthebox">(
    "tryhackme"
  );

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-4">
          <span className="text-sm font-medium">Cyber Security Lab</span>
          <div className="flex gap-2">
            <button
              onClick={() => setPlatform("tryhackme")}
              className={`px-3 py-1 rounded ${
                platform === "tryhackme"
                  ? "bg-blue-600 text-white"
                  : "bg-gray-700 text-gray-300"
              }`}
            >
              TryHackMe
            </button>
            <button
              onClick={() => setPlatform("hackthebox")}
              className={`px-3 py-1 rounded ${
                platform === "hackthebox"
                  ? "bg-green-600 text-white"
                  : "bg-gray-700 text-gray-300"
              }`}
            >
              Hack The Box
            </button>
          </div>
        </div>
        <div className="text-sm text-gray-300">
          Powered by{" "}
          {platform === "tryhackme" ? "TryHackMe" : "Hack The Box Academy"}
        </div>
      </div>
      <div className="flex-1 bg-white">
        <iframe
          src={
            platform === "tryhackme"
              ? "https://tryhackme.com/room/tutorial"
              : "https://hackthebox.com"
          }
          className="w-full h-full border-none"
          title={
            platform === "tryhackme" ? "TryHackMe Tutorial" : "HTB Academy"
          }
          allow="fullscreen"
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
        />
      </div>
    </div>
  );
};

export default CyberSecurityEditor;
