export const adminFetch = async (url: string, options: RequestInit = {}) => {
  const token = localStorage.getItem("access_token");
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await fetch(url, { ...options, headers });

    if (response.status === 401) {
      try {
        // Get refresh token
        const refreshToken = localStorage.getItem("refresh_token");
        console.log("refreshToken:::::", refreshToken);

        // Request new access token
        const refreshResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/refresh`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ refresh_token: refreshToken }),
          }
        );

        if (!refreshResponse.ok) throw new Error("Refresh failed");

        const tokens = await refreshResponse.json();
        localStorage.setItem("access_token", tokens.access_token);
        localStorage.setItem("refresh_token", tokens.refresh_token);
        localStorage.setItem("user", JSON.stringify(tokens.user));
        // Retry original request with new token
        return fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${tokens.access_token}`,
          },
        });
      } catch (error) {
        //localStorage.clear();
        window.location.href = "/admin/login";
        return;
      }
    }

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
