export const AVAILABLE_EDITORS = [
  {
    id: "codesandbox",
    name: "Code Sandbox",
    url: "https://codesandbox.io/embed/react-new",
  },
  {
    id: "zapp",
    name: "Zapp Flutter Editor",
    url: "https://zapp.run/edit/flutter?entry=lib/main.dart&file=lib/main.dart",
  },
  {
    id: "stackblitz",
    name: "StackBlitz",
    url: "https://stackblitz.com/edit/react-ts",
  },
  {
    id: "dartpad",
    name: "DartPad",
    url: "https://dartpad.dev/embed-flutter.html?split=60&run=true&null_safety=true",
  },
  {
    id: "jupyter",
    name: "Jupyter Lab",
    url: "https://jupyter.org/try-jupyter/lab/",
  },
  {
    id: "openml",
    name: "OpenML",
    url: "https://www.openml.org/search?type=task",
  },
  {
    id: "cybersecurity",
    name: "Cyber Security Lab",
    url: "https://hackthebox.com",
  },
  {
    id: "mysql",
    name: "MySQL Editor",
    url: "https://onecompiler.com/embed/mysql",
  },
];
