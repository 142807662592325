import { FC, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Set worker path for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props {
  file: string | File;
  onClose: () => void;
}

const PdfViewer: FC<Props> = ({ file, onClose }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div className="fixed inset-0 bg-white z-20 flex flex-col">
      <div className="p-4 border-b flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setPageNumber((prev) => Math.max(1, prev - 1))}
            disabled={pageNumber <= 1}
            className="p-2 hover:bg-gray-100 rounded disabled:opacity-50"
          >
            <i className="fas fa-chevron-left" />
          </button>

          <span className="text-sm">
            Page {pageNumber} of {numPages || "?"}
          </span>

          <button
            onClick={() =>
              setPageNumber((prev) => Math.min(numPages || prev, prev + 1))
            }
            disabled={pageNumber >= (numPages || 1)}
            className="p-2 hover:bg-gray-100 rounded disabled:opacity-50"
          >
            <i className="fas fa-chevron-right" />
          </button>
        </div>

        <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded">
          <i className="fas fa-times" />
        </button>
      </div>

      <div className="flex-1 overflow-auto flex justify-center p-4">
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          className="shadow-lg"
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </div>
  );
};

export default PdfViewer;
