import { FC, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { EventInput, EventClickArg } from "@fullcalendar/core";
import LiveSessionModal from "../../../components/LiveSessionModal/LiveSessionModal";
import { useQuery } from "@tanstack/react-query";
import EditSessionModal from "../../../components/EditSessionModal/EditSessionModal";
import { useNavigate } from "react-router-dom";
import { adminFetch } from "../../../utils/fetchInterceptor";

interface Instructor {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  avatar: string;
  expertise: string[];
  rating: number;
  totalStudents: number;
}

interface CalendarEvent extends EventInput {
  instructor: string;
  category: string;
  participants: number;
  status: "scheduled" | "live" | "ended" | "cancelled";
  description?: string;
  maxParticipants?: number;
  meetingLink?: string;
  isRecurring?: boolean;
  recurringFrequency?: string;
  start: string;
  end: string;
  title: string;
  roomId: string;
  meetingId: string;
}

interface Student {
  _id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  avatar: string;
}

interface Course {
  _id: string;
  title: string;
}

interface LiveSession {
  _id: string;
  title: string;
  description: string;
  scheduledFor: string;
  duration: number;
  status: "scheduled" | "live" | "ended" | "cancelled";
  totalSignups: number;
  maxStudents: number;
  roomId: string;
  meetingId: string;
  courseId: Course;
  instructorId: Instructor;
  instId: string;
  registeredStudents: Student[];
  createdAt: string;
  updatedAt: string;
}

interface LiveSessionsResponse {
  classes: LiveSession[];
  pagination: {
    total: number;
    pages: number;
    page: number;
    limit: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  meta: {
    totalClasses: number;
    totalStudents: number;
    averageClassSize: number;
  };
}

const AdminCalendar: FC = () => {
  const navigate = useNavigate();
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(
    null
  );
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState<{
    start: Date;
    end: Date;
  } | null>(null);
  const [editSession, setEditSession] = useState<LiveSession | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const { data: liveSessionsData } = useQuery<LiveSessionsResponse>({
    queryKey: ["live-sessions-calendar"],
    queryFn: async () => {
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/live-classes/admin/classes`
      );

      if (!response) return null;
      if (!response.ok) throw new Error("Failed to fetch live sessions");

      return response.json();
    },
  });

  const { data: coursesData } = useQuery<Course[]>({
    queryKey: ["courses"],
    queryFn: async () => {
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/courses`
      );

      if (!response) return null;
      if (!response.ok) throw new Error("Failed to fetch courses");

      return response.json();
    },
  });

  const statusColors: Record<
    LiveSession["status"],
    { bg: string; border: string }
  > = {
    scheduled: { bg: "#FCD34D", border: "#F59E0B" },
    live: { bg: "#34D399", border: "#059669" },
    ended: { bg: "#9CA3AF", border: "#6B7280" },
    cancelled: { bg: "#EF4444", border: "#DC2626" },
  };

  const events =
    liveSessionsData?.classes.map((session: LiveSession) => ({
      id: session._id,
      title: session.title,
      start: new Date(session.scheduledFor),
      end: new Date(
        new Date(session.scheduledFor).getTime() + session.duration * 60000
      ),
      instructor: session.instructorId.username,
      category: session.courseId.title,
      participants: session.totalSignups,
      status: session.status,
      description: session.description,
      maxParticipants: session.maxStudents,
      backgroundColor: statusColors[session.status].bg,
      borderColor: statusColors[session.status].border,
      instId: session.instId,
      roomId: session.roomId,
      meetingId: session.meetingId,
    })) ?? [];

  // console.log("events", events);

  const handleEventClick = async (info: EventClickArg) => {
    const eventData: CalendarEvent = {
      instructor: info.event.extendedProps.instructor,
      category: info.event.extendedProps.category,
      participants: info.event.extendedProps.participants,
      status: info.event.extendedProps.status,
      start: (info.event.start || new Date()).toISOString(),
      end: (info.event.end || new Date()).toISOString(),
      title: info.event.title,
      description: info.event.extendedProps.description,
      maxParticipants: info.event.extendedProps.maxParticipants,
      id: info.event.id,
      instId: info.event.extendedProps.instId,
      roomId: info.event.extendedProps.roomId,
      meetingId: info.event.extendedProps.meetingId,
    };
    setSelectedEvent(eventData);
    setShowEventModal(true);
  };

  const handleDateSelect = (selectInfo: any) => {
    setSelectedDates({
      start: selectInfo.start,
      end: selectInfo.end,
    });
    setShowCreateModal(true);
  };

  // Type-safe status styles
  const statusStyles: Record<LiveSession["status"], string> = {
    scheduled: "bg-yellow-100 text-yellow-800",
    live: "bg-green-100 text-green-800",
    ended: "bg-gray-100 text-gray-800",
    cancelled: "bg-red-100 text-red-800",
  };

  const handleEditClick = async () => {
    if (!selectedEvent) return;

    const session = liveSessionsData?.classes.find(
      (s) => s._id === selectedEvent.id
    );
    if (session) {
      setEditSession(session);
      setShowEventModal(false);
      setShowEditModal(true);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Teaching Schedule</h1>
        <div className="flex space-x-2 relative z-10">
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-yellow-400 mr-2"></div>
            <span className="text-sm text-gray-600">Scheduled</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-green-400 mr-2"></div>
            <span className="text-sm text-gray-600">Live</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 rounded-full bg-gray-400 mr-2"></div>
            <span className="text-sm text-gray-600">Completed</span>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6 relative z-0">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="timeGridWeek"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          events={events}
          eventClick={handleEventClick}
          select={handleDateSelect}
          height="auto"
        />
      </div>

      {/* Event Details Modal */}
      {showEventModal && selectedEvent && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Session Details
              </h3>
              <button
                onClick={() => {
                  setShowEventModal(false);
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Title
                </label>
                <p className="mt-1 text-sm text-gray-900">
                  {selectedEvent.title}
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Instructor
                </label>
                <p className="mt-1 text-sm text-gray-900">
                  {selectedEvent.instructor}{" "}
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <p className="mt-1 text-sm text-gray-900">
                  {selectedEvent.category}
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Date & Time
                </label>
                <p className="mt-1 text-sm text-gray-900">
                  {selectedEvent.start
                    ? new Date(selectedEvent.start).toLocaleString()
                    : "Not scheduled"}
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <span
                  className={`mt-1 inline-flex px-2 py-1 text-xs font-semibold rounded-full ${
                    statusStyles[selectedEvent.status]
                  }`}
                >
                  {selectedEvent.status.charAt(0).toUpperCase() +
                    selectedEvent.status.slice(1)}
                </span>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowEventModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                >
                  Close
                </button>
                <button
                  onClick={handleEditClick}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm font-medium"
                >
                  Edit Session
                </button>

                {(selectedEvent?.status === "live" ||
                  selectedEvent?.status === "scheduled") && (
                  <button
                    type="button"
                    onClick={() =>
                      navigate(`/instructor/classroom/${selectedEvent.roomId}`)
                    }
                    className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 text-sm font-medium"
                  >
                    Enter Classroom
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <LiveSessionModal
        isOpen={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          setSelectedDates(null);
        }}
        courses={(Array.isArray(coursesData) ? coursesData : []).map(
          (course) => ({
            id: course._id,
            title: course.title,
          })
        )}
        scheduledFor={selectedDates?.start?.toISOString()}
      />

      <EditSessionModal
        isOpen={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          setEditSession(null);
        }}
        session={editSession}
      />
    </div>
  );
};

export default AdminCalendar;
