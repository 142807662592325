import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import LoginModal from "../LoginModal";
import SignUpModal from "../SignUpModal";
import { useAuth } from "../../context/AuthContext";
import { getInitialsAvatar } from "../../utils/avatar";
import { AdminUser } from "../../types";
import { Menu } from "@headlessui/react";

const Header: FC = () => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isAuthenticated, logout, user } = useAuth();

  const isAdminUser = (user: any): user is AdminUser => {
    return user?.role === "admin";
  };

  return (
    <header className="sticky top-0 bg-[#020A13] py-5 z-50">
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center">
            <img src="/logo_text.png" alt="Code Coast" className="h-7" />
          </Link>

          <nav className="hidden md:flex items-center space-x-10">
            <Link
              to="/"
              className="text-white hover:text-blue-400 text-sm font-medium"
            >
              Home
            </Link>
            <Link
              to="/learning"
              className="text-white hover:text-blue-400 text-sm font-medium"
            >
              My Learning
            </Link>
            <Link
              to="/courses"
              className="text-white hover:text-blue-400 text-sm font-medium"
            >
              Courses
            </Link>
            <Link
              to="/live-classes"
              className="text-white hover:text-blue-400 text-sm font-medium"
            >
              Live Classes
            </Link>
          </nav>

          <div className="flex items-center space-x-8">
            <div className="flex items-center space-x-6">
              <button className="text-gray-400 hover:text-white">
                <svg
                  className="w-[18px] h-[18px]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
              <button className="text-gray-400 hover:text-white">
                <svg
                  className="w-[18px] h-[18px]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4z"
                  />
                </svg>
              </button>
            </div>
            {isAuthenticated ? (
              <div className="flex items-center gap-4">
                <Menu as="div" className="relative">
                  <Menu.Button
                    className="flex items-center gap-2 hover:bg-gray-100 p-2 rounded-lg"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {user && (
                      <div
                        className={`w-8 h-8 rounded-full ${
                          getInitialsAvatar(user.email).bgColor
                        } flex items-center justify-center text-white font-medium`}
                      >
                        {getInitialsAvatar(user.email).initial}
                      </div>
                    )}
                    <span className="text-white">{user?.email}</span>
                  </Menu.Button>
                  <Menu.Items
                    static={isDropdownOpen}
                    className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50"
                  >
                    {user && isAdminUser(user) && (
                      <Link
                        to="/admin"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Admin Dashboard
                      </Link>
                    )}
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Settings
                    </Link>
                    <button
                      onClick={logout}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </Menu.Items>
                </Menu>
              </div>
            ) : (
              <>
                <button
                  className="text-sm font-medium hover:text-blue-600"
                  onClick={() => setIsLoginModalOpen(true)}
                >
                  Login
                </button>
                <button
                  className="bg-[#0284C7] text-white px-6 py-2 rounded-lg text-sm font-medium hover:bg-blue-600 transition-colors"
                  onClick={() => setIsSignUpModalOpen(true)}
                >
                  Sign up
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onSignUpClick={() => setIsSignUpModalOpen(true)}
      />
      <SignUpModal
        isOpen={isSignUpModalOpen}
        onClose={() => setIsSignUpModalOpen(false)}
        onLoginClick={() => setIsLoginModalOpen(true)}
      />
    </header>
  );
};

export default Header;
