import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";

interface Category {
  _id: string;
  title: string;
}

interface Course {
  _id: string;
  title: string;
  description: string;
  thumbnail: string;
  author: string;
  tag: string;
  rating: number;
  reviews: number;
  price: number;
  categoryId: Category;
  isActive: boolean;
  totalLessons: number;
}

const WatchingCategory: FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRandomCourses = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get("/courses/random");
        setCourses(data);
      } catch (err) {
        console.error("Error fetching random courses:", err);
        setError("Failed to load courses");
      } finally {
        setIsLoading(false);
      }
    };

    fetchRandomCourses();
  }, []);

  if (isLoading) {
    return (
      <div className="animate-pulse">
        <div className="flex justify-between items-center mb-6">
          <div className="h-8 bg-gray-200 rounded w-1/3"></div>
          <div className="h-8 bg-gray-200 rounded w-24"></div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <div key={i} className="bg-white rounded-lg shadow-md p-4">
              <div className="h-48 bg-gray-200 rounded-lg mb-4"></div>
              <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
        {error}
      </div>
    );
  }

  return (
    <section className="mb-12">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">
          Our most watching category this month
        </h2>
        <Link
          to="/courses"
          className="text-blue-600 hover:text-blue-700 font-medium"
        >
          Explore all
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {courses.map((course) => (
          <Link
            key={course._id}
            to={`/course/${course._id}`}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
          >
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={course.thumbnail}
                alt={course.title}
                className="w-full h-48 object-cover"
                onError={(e) => {
                  e.currentTarget.src = "/placeholder-course.jpg";
                }}
              />
            </div>
            <div className="p-4">
              <div className="flex items-center mb-2">
                <span className="px-2 py-1 text-xs font-semibold bg-blue-100 text-blue-800 rounded-full">
                  {course.categoryId.title}
                </span>
              </div>
              <h3 className="text-lg font-semibold mb-2 line-clamp-2">
                {course.title}
              </h3>
              <div className="flex items-center justify-between">
                <div className="flex items-center text-sm text-gray-600">
                  <div className="flex items-center mr-4">
                    <i className="fas fa-star text-yellow-400 mr-1"></i>
                    <span>{course.rating.toFixed(1)}</span>
                    <span className="ml-1">({course.reviews})</span>
                  </div>
                  <div>
                    <i className="fas fa-book-open mr-1"></i>
                    <span>{course.totalLessons} lessons</span>
                  </div>
                </div>
                <div className="text-lg font-bold text-blue-600">
                  ${course.price}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default WatchingCategory;
