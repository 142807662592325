import { FC } from "react";
import { BaseEditorProps } from "./BaseEditor";

const ReactEditor: FC<BaseEditorProps> = () => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">React Editor</span>
        </div>
        <div className="text-sm text-gray-300">Powered by CodeSandbox</div>
      </div>
      <div className="flex-1 bg-white">
        <iframe
          src="https://codesandbox.io/embed/react-new?fontsize=14&hidenavigation=1&theme=dark"
          className="w-full h-full border-none"
          title="React CodeSandbox"
          allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
        />
      </div>
    </div>
  );
};

export default ReactEditor;
