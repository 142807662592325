export const testimonials = [
  {
    id: 1,
    name: "Martin August",
    role: "Full-stack Developer",
    image: "/images/testimonials/martin.jpg",
    content:
      "Suspendisse ultrices at diam lectus nullam. Nisl, sagittis viverra enim erat tortor ultricies massa turpis. Arcu pulvinar.",
  },
  {
    id: 2,
    name: "Michael Chen",
    role: "UI/UX Designer",
    image: "/images/testimonials/martin.jpg",
    content:
      "Quisquam similique molestiae quis quod dolorum et. Aliquid blanditiis voluptatem maxime qui et voluptas minus. Non proident pariatur nisi. Repellat voluptas culpa nisi.",
  },
  {
    id: 3,
    name: "Sarah Johnson",
    role: "Product Manager",
    image: "/images/testimonials/martin.jpg",
    content:
      "Orci vari quia. Perspiciatis et suscipit. Voluptatem corporis expedita et suscipit. Fuga. Sed fugit ex. Ea rem cum reiciendis sed. Fugit similique.",
  },
];
