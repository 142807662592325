import { FC } from "react";
import { BaseEditorProps } from "../BaseEditor";

const SQLiteEditor: FC<BaseEditorProps> = (props) => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">SQLite Editor</span>
        </div>
        <div className="text-sm text-gray-300">Powered by OneCompiler</div>
      </div>
      <div className="flex-1 bg-white">
        <iframe
          src="https://onecompiler.com/embed/sqlite"
          className="w-full h-full border-none"
          title="SQLite OneCompiler"
          allow="fullscreen"
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
        />
      </div>
    </div>
  );
};

export default SQLiteEditor;
