import { FC } from "react";
import { BaseEditorProps } from "./BaseEditor";

const ZappEditor: FC<BaseEditorProps> = ({ code, readOnly = false }) => {
  // Zapp embed URL
  const zappUrl =
    "https://zapp.run/edit/flutter?entry=lib/main.dart&file=lib/main.dart";

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Zapp Flutter Editor</span>
        </div>
        <div className="text-sm text-gray-300">Powered by Zapp.run</div>
      </div>
      <div className="flex-1 bg-white">
        <iframe
          src={zappUrl}
          className="w-full h-full border-none"
          title="Zapp Flutter Editor"
          allow="web-share"
        />
      </div>
    </div>
  );
};

export default ZappEditor;
