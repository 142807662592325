import React, { FC, useEffect, useState, useRef } from "react";
// import ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { Course, Lesson } from "../../types";
import api from "../../services/api";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Resizable } from "re-resizable";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { AVAILABLE_EDITORS } from "../../constants/editors";
import { useAlert } from "../../context/AlertContext";

const CourseVideoDetails: FC = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState<Course | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);
  const [showControls, setShowControls] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [activeTab, setActiveTab] = useState<
    "description" | "resources" | "assignments"
  >("description");
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [editorSize, setEditorSize] = useState({
    width: 400,
    height: window.innerHeight - 80,
  });
  const nodeRef = useRef(null);
  const { showAlert } = useAlert();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const { data } = await api.get(`/courses/${courseId}`);
        // console.log(data);
        setCourse(data);
        if (
          data.content &&
          data.content.length > 0 &&
          data.content[0].lessons.length > 0
        ) {
          // Set first lesson as selected lesson
          setSelectedLesson(data.content[0].lessons[0]);
          // Auto play if video ref is available
          if (videoRef.current) {
            videoRef.current.play().catch((err) => {
              console.log("Autoplay prevented:", err);
            });
          }
        }
      } catch (error) {
        console.error("Failed to fetch course");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourse();
  }, [courseId]);

  useEffect(() => {
    if (videoRef.current && selectedLesson) {
      videoRef.current.src = selectedLesson.videoUrl;
      videoRef.current.load(); // Reload the video
      videoRef.current.play().catch((err) => {
        console.log("Autoplay prevented:", err);
      });
    }
  }, [selectedLesson]);

  const handleSkipTime = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime += seconds;
    }
  };

  const handleRepeat = () => {
    setIsRepeat(!isRepeat);
  };

  const handleNavigateLesson = (direction: "prev" | "next") => {
    if (!course || !selectedLesson) return;

    let currentSectionIndex = -1;
    let currentLessonIndex = -1;

    course.content.forEach((section, sIndex) => {
      section.lessons.forEach((lesson, lIndex) => {
        if (lesson._id === selectedLesson._id) {
          currentSectionIndex = sIndex;
          currentLessonIndex = lIndex;
        }
      });
    });

    if (currentSectionIndex === -1) return;

    const currentSection = course.content[currentSectionIndex];

    if (direction === "prev") {
      if (currentLessonIndex > 0) {
        setSelectedLesson(currentSection.lessons[currentLessonIndex - 1]);
      } else if (currentSectionIndex > 0) {
        const prevSection = course.content[currentSectionIndex - 1];
        setSelectedLesson(prevSection.lessons[prevSection.lessons.length - 1]);
      }
    } else {
      if (currentLessonIndex < currentSection.lessons.length - 1) {
        setSelectedLesson(currentSection.lessons[currentLessonIndex + 1]);
      } else if (currentSectionIndex < course.content.length - 1) {
        setSelectedLesson(course.content[currentSectionIndex + 1].lessons[0]);
      }
    }
  };

  const handleCodeEditorToggle = () => {
    setShowCodeEditor(!showCodeEditor);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    // Add any drag end logic here if needed
  };

  if (isLoading) return <LoadingSpinner />;
  if (!course) return <div>Course not found</div>;

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-6 py-8">
        <div className="grid grid-cols-12 gap-8">
          {/* Main Content */}
          <div className="col-span-8">
            <div className="bg-white rounded-xl overflow-hidden">
              <div
                className="aspect-w-16 aspect-h-9 bg-gray-900 relative"
                onMouseEnter={() => setShowControls(true)}
                onMouseLeave={() => setShowControls(false)}
              >
                {selectedLesson && (
                  <>
                    <div
                      className={`absolute inset-x-0 top-1/2 -translate-y-1/2 flex items-center justify-between px-4 z-10 pointer-events-none transition-opacity duration-300 ${
                        showControls ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <button
                        onClick={() => handleNavigateLesson("prev")}
                        className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity pointer-events-auto"
                        aria-label="Previous lesson"
                      >
                        <i className="fas fa-chevron-left"></i>
                      </button>
                      <div className="flex gap-4 pointer-events-auto">
                        <button
                          onClick={() => handleSkipTime(-10)}
                          className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity"
                          aria-label="Rewind 10 seconds"
                        >
                          <i className="fas fa-undo"></i>
                        </button>
                        <button
                          onClick={() => handleSkipTime(10)}
                          className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity"
                          aria-label="Forward 10 seconds"
                        >
                          <i className="fas fa-redo"></i>
                        </button>
                      </div>
                      <button
                        onClick={() => handleNavigateLesson("next")}
                        className="p-2 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-75 transition-opacity pointer-events-auto"
                        aria-label="Next lesson"
                      >
                        <i className="fas fa-chevron-right"></i>
                      </button>
                    </div>
                    <div
                      className={`absolute bottom-4 right-4 z-10 pointer-events-none transition-opacity duration-300 ${
                        showControls ? "opacity-100" : "opacity-0"
                      }`}
                    >
                      <button
                        onClick={handleRepeat}
                        className={`p-2 mb-12 rounded-full transition-opacity pointer-events-auto ${
                          isRepeat
                            ? "bg-blue-500 text-white"
                            : "bg-black bg-opacity-50 text-white hover:bg-opacity-75"
                        }`}
                        aria-label="Toggle repeat"
                      >
                        <i className="fas fa-repeat"></i>
                      </button>
                    </div>
                    <video
                      ref={videoRef}
                      controls
                      className="w-full h-full object-contain"
                      poster={course.thumbnail}
                      controlsList="nodownload"
                      onContextMenu={(e) => e.preventDefault()}
                      disablePictureInPicture
                      loop={isRepeat}
                    />
                  </>
                )}
              </div>
              <div className="p-8">
                <h1 className="text-2xl font-bold mb-4">{course.title}</h1>
                <p className="text-gray-600 mb-6">
                  By <span className="text-[#0284C7]">{course.author}</span>
                </p>

                {/* Progress Bar */}
                {course.progress !== undefined && (
                  <div className="mb-8">
                    <div className="flex justify-between text-sm mb-2">
                      <span className="text-[#0284C7] font-medium">
                        {course.progress}% completed
                      </span>
                      <span className="text-gray-500">
                        {Math.round((course.progress / 100) * 12)}/12 lessons
                      </span>
                    </div>
                    <div className="h-1.5 bg-gray-100 rounded-full">
                      <div
                        className="h-full bg-[#55B7FF] rounded-full transition-all duration-300"
                        style={{ width: `${course.progress}%` }}
                      />
                    </div>
                  </div>
                )}

                {/* Next Lesson Button */}
                {/* <button
                  onClick={() => handleNavigateLesson("next")}
                  className="w-full bg-[#0284C7] text-white py-3 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Continue to Next Lesson
                </button> */}

                <div className="mt-8 border-t">
                  {/* Tabs */}
                  <div className="flex border-b">
                    <button
                      onClick={() => setActiveTab("description")}
                      className={`px-6 py-3 font-medium text-sm transition-colors ${
                        activeTab === "description"
                          ? "border-b-2 border-[#0284C7] text-[#0284C7]"
                          : "text-gray-500 hover:text-gray-700"
                      }`}
                    >
                      Description
                    </button>
                    <button
                      onClick={() => setActiveTab("resources")}
                      className={`px-6 py-3 font-medium text-sm transition-colors ${
                        activeTab === "resources"
                          ? "border-b-2 border-[#0284C7] text-[#0284C7]"
                          : "text-gray-500 hover:text-gray-700"
                      }`}
                    >
                      Resources
                    </button>
                    <button
                      onClick={() => setActiveTab("assignments")}
                      className={`px-6 py-3 font-medium text-sm transition-colors ${
                        activeTab === "assignments"
                          ? "border-b-2 border-[#0284C7] text-[#0284C7]"
                          : "text-gray-500 hover:text-gray-700"
                      }`}
                    >
                      Assignments
                    </button>
                  </div>

                  {/* Tab Content */}
                  <div className="p-6">
                    {activeTab === "description" && selectedLesson && (
                      <div className="prose max-w-none">
                        <p className="text-gray-600">
                          {selectedLesson.description}
                        </p>
                      </div>
                    )}

                    {activeTab === "resources" && selectedLesson && (
                      <div className="space-y-4">
                        {selectedLesson.resources?.map((resource, index) => (
                          <a
                            key={index}
                            href={resource}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center gap-3 text-[#0284C7] hover:underline"
                          >
                            <i className="fas fa-file-download"></i>
                            <span>Download Resource {index + 1}</span>
                          </a>
                        ))}
                        {(!selectedLesson.resources ||
                          selectedLesson.resources.length === 0) && (
                          <p className="text-gray-500">
                            No resources available for this lesson.
                          </p>
                        )}
                      </div>
                    )}

                    {activeTab === "assignments" &&
                    selectedLesson?.assignment ? (
                      <div className="space-y-4">
                        <div className="bg-white p-6 rounded-lg shadow-sm">
                          <h3 className="text-lg font-medium mb-4">
                            {selectedLesson.assignment.title}
                          </h3>

                          <div className="space-y-4">
                            <div>
                              <p className="text-gray-600">
                                {selectedLesson.assignment.description}
                              </p>
                            </div>

                            <div>
                              <h4 className="text-sm font-medium text-gray-700 mb-2">
                                Due Date
                              </h4>
                              <p className="text-gray-600">
                                {new Date(
                                  selectedLesson.assignment.dueDate
                                ).toLocaleDateString()}
                              </p>
                            </div>

                            <div>
                              <h4 className="text-sm font-medium text-gray-700 mb-2">
                                Instructions
                              </h4>
                              <p className="text-gray-600">
                                {selectedLesson.assignment.instructions}
                              </p>
                            </div>

                            {selectedLesson.assignment.attachments && (
                              <div>
                                <h4 className="text-sm font-medium text-gray-700 mb-2">
                                  Attachments
                                </h4>
                                <a
                                  href={selectedLesson.assignment.attachments}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-600 hover:text-blue-800 flex items-center gap-2"
                                >
                                  <i className="fas fa-download"></i>
                                  <span>Download Assignment Files</span>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-gray-500">
                        {/* <p>No assignments available for this lesson.</p> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Course Modules */}
          <div className="col-span-4">
            <div className="bg-white rounded-xl p-6">
              <h2 className="font-bold mb-6">Course Content</h2>
              <div className="space-y-6">
                {course.content.map((section, index) => (
                  <div key={index}>
                    <h3 className="font-medium mb-3">{section.title}</h3>
                    <div className="space-y-3">
                      {section.lessons.map((lesson, lessonIndex) => (
                        <div
                          key={lessonIndex}
                          className="flex items-center justify-between hover:bg-gray-50 p-2 rounded-lg"
                        >
                          <div
                            className="flex items-center gap-3 text-sm cursor-pointer flex-grow"
                            onClick={() => setSelectedLesson(lesson)}
                          >
                            <div
                              className={`w-5 h-5 rounded-full flex items-center justify-center ${
                                selectedLesson?._id === lesson._id
                                  ? "bg-[#0284C7]"
                                  : lesson.completed
                                  ? "bg-[#0284C7]"
                                  : "border-2 border-gray-200"
                              }`}
                            >
                              {(selectedLesson?._id === lesson._id ||
                                lesson.completed) && (
                                <svg
                                  className="w-3 h-3 text-white"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                                </svg>
                              )}
                            </div>
                            <span
                              className={
                                selectedLesson?._id === lesson._id
                                  ? "text-[#0284C7]"
                                  : lesson.completed
                                  ? "text-[#0284C7]"
                                  : "text-gray-600"
                              }
                            >
                              {lesson.title}
                            </span>
                          </div>
                          <button
                            onClick={() => {
                              if (!selectedLesson) {
                                showAlert(
                                  "Please select a lesson first",
                                  "info"
                                );
                                return;
                              }
                              handleCodeEditorToggle();
                            }}
                            className={`text-xs px-3 py-1 ${
                              selectedLesson
                                ? "bg-gray-100 text-gray-600 hover:bg-gray-200"
                                : "bg-gray-50 text-gray-400 cursor-not-allowed"
                            } rounded transition-colors`}
                            title={
                              selectedLesson
                                ? "Toggle code editor"
                                : "Select a lesson first"
                            }
                            disabled={!selectedLesson}
                          >
                            Code Along
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Floating Code Editor Panel */}
        {showCodeEditor && (
          <DragDropContext onDragEnd={handleDragEnd}>
            <div ref={nodeRef} className="fixed top-20 right-6 z-50">
              <Resizable
                size={editorSize}
                onResizeStop={(e, direction, ref, d) => {
                  setEditorSize({
                    width: editorSize.width + d.width,
                    height: editorSize.height + d.height,
                  });
                }}
                minWidth={300}
                minHeight={400}
                maxWidth={window.innerWidth - 40}
                maxHeight={window.innerHeight - 100}
              >
                <div className="bg-gray-900 rounded-xl overflow-hidden shadow-2xl h-full">
                  <div className="p-4 bg-gray-800 text-white flex justify-between items-center drag-handle cursor-move">
                    <div className="flex items-center gap-2">
                      <i className="fas fa-grip-lines"></i>
                      <span className="font-medium">Code Editor</span>
                    </div>
                    <button
                      onClick={handleCodeEditorToggle}
                      className="text-gray-400 hover:text-white"
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                  <div className="h-[calc(100%-56px)]">
                    <iframe
                      src={
                        AVAILABLE_EDITORS.find((editor) => {
                          //console.log(selectedLesson?.tools[0]?.id);
                          return editor.id === selectedLesson?.tools[0]?.id;
                        })?.url || AVAILABLE_EDITORS[0].url
                      }
                      className="w-full h-full border-none"
                      title={
                        AVAILABLE_EDITORS.find(
                          (editor) => editor.id === selectedLesson?.tools[0]?.id
                        )?.name || "Code Editor"
                      }
                      allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
                      sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
                    />
                  </div>
                </div>
              </Resizable>
            </div>
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default CourseVideoDetails;
