import { FC } from "react";
import { BaseEditorProps } from "./BaseEditor";

const FlutterEditor: FC<BaseEditorProps> = ({ code, readOnly = false }) => {
  // DartPad embed URL with parameters for Flutter mode
  const dartpadUrl = `https://dartpad.dev/embed-flutter.html?split=60&run=true&null_safety=true`;

  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">Flutter Editor</span>
        </div>
        <div className="text-sm text-gray-300">Powered by DartPad</div>
      </div>
      <div className="flex-1 bg-white">
        <iframe
          src={dartpadUrl}
          className="w-full h-full border-none"
          title="Flutter Editor"
          allow="web-share"
        />
      </div>
    </div>
  );
};

export default FlutterEditor;
