import io, { Socket } from "socket.io-client";
import SimplePeer from "simple-peer";

export class StreamService {
  private socket: typeof Socket;
  private peer: SimplePeer.Instance | null = null;
  private stream: MediaStream | null = null;

  constructor() {
    const wsUrl =
      process.env.NODE_ENV === "production"
        ? "wss://your-production-domain.com"
        : "ws://localhost:3030/live-classes";

    console.log("Connecting to WebSocket URL:", wsUrl);

    this.socket = io(wsUrl, {
      transports: ["websocket", "polling"],
      auth: {
        token: localStorage.getItem("access_token"),
      },
      forceNew: true,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 10000,
    });

    // Log connection events for debugging
    this.socket.on("connect", () => {
      console.log("Socket connected successfully");
    });

    this.socket.on("connect_error", (error: any) => {
      console.error("Socket connection error:", error);
    });

    this.initializePeer();
    this.setupSocketListeners();
  }

  private initializePeer(initiator: boolean = false) {
    // console.log("initializePeer:::::", this.stream?.getTracks());
    this.peer = new SimplePeer({
      initiator,
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:stun.twilio.com:3478" },
        ],
      },
      stream: this.stream || undefined,
      trickle: true,
    });

    this.setupPeerListeners();
  }

  private setupPeerListeners() {
    if (!this.peer) return;

    this.peer.on("signal", (data) => {
      // Send signaling data to remote peer via socket
      this.socket.emit("signal", data);
    });

    this.peer.on("connect", () => {
      console.log("Peer connection established");
    });

    this.peer.on("stream", (stream: MediaStream) => {
      console.log("Received remote stream");
      // Handle incoming stream (e.g., attach to video element)
      this.socket.emit("instructor-stream", stream);
    });

    this.peer.on("error", (err: Error) => {
      console.error("Detailed peer error:", {
        message: err.message,
        name: err.name,
        stack: err.stack,
      });

      // Attempt to reconnect on error
      this.reconnectPeer();
    });
  }

  private reconnectPeer() {
    console.log("Attempting to reconnect peer...");
    if (this.peer) {
      this.peer.destroy();
      this.peer = null;
    }

    setTimeout(() => {
      this.initializePeer(true);
    }, 1000);
  }

  private setupSocketListeners() {
    this.socket.on("signal", (data: any) => {
      if (!this.peer) {
        this.initializePeer(false);
      }
      this.peer?.signal(data);
    });

    this.socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    this.socket.on("connect", () => {
      console.log("Socket connected");
    });
  }

  public async startStreaming(
    stream: MediaStream,
    roomId: string,
    userId: string,
    name: string
  ): Promise<void> {
    try {
      this.stream = stream;
      // Initialize as initiator since this is the instructor
      this.initializePeer(true);

      // Join room
      this.socket.emit("join-room", {
        roomId,
        userId,
        role: "admin",
        name,
      });

      // Listen for student connection requests
      this.socket.on("request-stream", (data: any) => {
        console.log("Stream requested by:", data.userId);
        // Ensure peer is initialized with the stream
        if (!this.peer) {
          this.initializePeer(true);
        }
      });
    } catch (error) {
      console.error("Streaming error:", error);
      throw error;
    }
  }

  public stopStreaming() {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }

    if (this.peer) {
      this.peer.destroy();
      this.peer = null;
    }

    this.socket.disconnect();
  }

  public get socketInstance(): typeof Socket {
    return this.socket;
  }

  public leaveRoom(roomId: string, userId: string) {
    if (this.socketInstance) {
      this.socketInstance.emit("leave-room", { roomId, userId });
      this.socketInstance.disconnect();
    }
  }

  public async joinRoom(
    roomId: string,
    userId: string,
    role: string,
    name: string
  ) {
    if (this.socketInstance) {
      this.socketInstance.emit("join-room", { roomId, userId, role, name });
    }
  }

  public async toggleAudio(enabled: boolean): Promise<void> {
    if (this.stream) {
      const audioTrack = this.stream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = enabled;
      }
    }
  }

  public async toggleVideo(enabled: boolean): Promise<void> {
    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = enabled;
      }
    }
  }

  public get peerInstance(): SimplePeer.Instance | null {
    return this.peer;
  }

  public initializeNewPeer(
    initiator: boolean = false,
    stream?: MediaStream
  ): void {
    if (this.peerInstance) {
      this.peerInstance.destroy();
    }

    this.peer = new SimplePeer({
      initiator,
      stream,
      trickle: true,
      config: {
        iceServers: [
          { urls: "stun:stun.l.google.com:19302" },
          { urls: "stun:global.stun.twilio.com:3478" },
        ],
      },
    });

    // Set up common peer event handlers
    this.peer.on("error", (err) => {
      console.error("Peer error:", err);
    });

    this.peer.on("connect", () => {
      console.log("Peer connected");
    });

    this.peer.on("close", () => {
      console.log("Peer connection closed");
    });
  }
}
