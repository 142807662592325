import { FC, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useAlert } from "../../../context/AlertContext";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import AVAILABLE_EDITORS from "../../../components/CodeEditors/AvailableEditors";

interface Assignment {
  title: string;
  description: string;
  dueDate: string;
  instructions: string;
  attachments?: string;
}

interface Lesson {
  _id?: string;
  title: string;
  description: string;
  videoUrl: string;
  duration: string;
  isFree: boolean;
  resources: string[];
  editor?: {
    id: string;
    name: string;
    url: string;
  };
  assignment?: Assignment;
}

interface Section {
  _id?: string;
  title: string;
  description: string;
  lessons: Lesson[];
}

interface Course {
  _id: string;
  title: string;
  content: Section[];
}

interface UploadProgress {
  sectionIndex: number;
  lessonIndex: number;
  progress: number;
}

const AddLessons: FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState<Course | null>(null);
  const [sections, setSections] = useState<Section[]>([
    { title: "", description: "", lessons: [] },
  ]);
  const [uploadProgress, setUploadProgress] = useState<UploadProgress | null>(
    null
  );

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const { data } = await api.get(`/courses/${courseId}`);
        setCourse(data);
        if (data.content && data.content.length > 0) {
          setSections(data.content);
        }
      } catch (error) {
        showAlert("Failed to fetch course details", "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourse();
  }, [courseId, showAlert]);

  const handleAddSection = () => {
    setSections([...sections, { title: "", description: "", lessons: [] }]);
  };

  const handleAddLesson = (sectionIndex: number) => {
    const newSections = [...sections];
    newSections[sectionIndex].lessons.push({
      title: "",
      description: "",
      videoUrl: "",
      duration: "0",
      isFree: false,
      resources: [],
    });
    setSections(newSections);
  };

  const handleSectionChange = (
    index: number,
    field: keyof Section,
    value: string | Lesson[]
  ) => {
    const newSections = [...sections];
    if (field === "lessons") {
      newSections[index][field] = value as Lesson[];
    } else {
      newSections[index][field] = value as string;
    }
    setSections(newSections);
  };

  const handleLessonChange = (
    sectionIndex: number,
    lessonIndex: number,
    field: keyof Lesson,
    value:
      | string
      | boolean
      | string[]
      | { id: string; name: string; url: string }
      | Assignment
      | undefined
  ) => {
    const newSections = structuredClone(sections);
    // @ts-ignore
    newSections[sectionIndex].lessons[lessonIndex][field] = value;
    setSections(newSections);
  };

  const handleSubmit = async () => {
    try {
      for (const section of sections) {
        const payload = {
          title: section.title,
          description: section.description,
          sectionPosition: sections.indexOf(section),
          lessons: section.lessons.map((lesson) => ({
            title: lesson.title,
            description: lesson.description,
            videoUrl: lesson.videoUrl || "",
            duration: lesson.duration,
            isFree: lesson.isFree,
            resources: lesson.resources || [],
            tools: lesson.editor || undefined,
            assignment: lesson.assignment || undefined,
            sectionId: section._id,
            courseId: courseId,
          })),
        };

        await api.post(`/courses/${courseId}/add/lessons`, payload);
      }

      showAlert("Lessons added successfully", "success");
      navigate("/admin/courses");
    } catch (error: any) {
      showAlert(
        error.response?.data?.message || "Failed to add lessons",
        "error"
      );
    }
  };

  const handleVideoUpload = async (
    sectionIndex: number,
    lessonIndex: number,
    file: File
  ) => {
    try {
      const formData = new FormData();
      formData.append("video", file);

      const { data } = await api.post("/courses/upload/video", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total ?? 0)
          );
          setUploadProgress({ sectionIndex, lessonIndex, progress });
        },
      });

      //Update the lesson's videoUrl using structuredClone to maintain state
      const newSections = structuredClone(sections);
      newSections[sectionIndex].lessons[lessonIndex].videoUrl = data.videoUrl;
      setSections(newSections);

      setUploadProgress(null);
      showAlert("Video uploaded successfully", "success");
    } catch (error) {
      showAlert("Failed to upload video", "error");
      setUploadProgress(null);
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSections(items);
  };

  const handleRemoveLesson = async (
    sectionIndex: number,
    lessonIndex: number
  ) => {
    try {
      const sectionId = sections[sectionIndex]._id;
      const lessonId = sections[sectionIndex].lessons[lessonIndex]._id;

      if (!sectionId || !lessonId) {
        // If not saved yet, just remove from state
        const newSections = structuredClone(sections);
        newSections[sectionIndex].lessons.splice(lessonIndex, 1);
        setSections(newSections);
        return;
      }

      // Call API to delete lesson
      await api.delete(
        `/courses/${courseId}/sections/${sectionId}/lessons/${lessonId}`
      );

      // Update state after successful deletion
      const newSections = structuredClone(sections);
      newSections[sectionIndex].lessons.splice(lessonIndex, 1);
      setSections(newSections);
      showAlert("Lesson deleted successfully", "success");
    } catch (error) {
      showAlert("Failed to delete lesson", "error");
    }
  };

  const handleRemoveSection = async (index: number) => {
    try {
      const sectionId = sections[index]._id; // Get section ID
      if (!sectionId) {
        // If section hasn't been saved yet, just remove from state
        const newSections = sections.filter((_, i) => i !== index);
        setSections(newSections);
        return;
      }

      // Call API to delete section
      await api.delete(`/courses/${courseId}/sections/${sectionId}`);

      // Update state after successful deletion
      const newSections = sections.filter((_, i) => i !== index);
      setSections(newSections);
      showAlert("Section deleted successfully", "success");
    } catch (error) {
      showAlert("Failed to delete section", "error");
    }
  };

  const handleSaveLesson = async (
    sectionIndex: number,
    lessonIndex: number
  ) => {
    try {
      const section = sections[sectionIndex];
      const lesson = section.lessons[lessonIndex];

      const payload = {
        title: lesson.title,
        description: lesson.description,
        videoUrl: lesson.videoUrl,
        duration: lesson.duration,
        isFree: lesson.isFree,
        resources: lesson.resources || [],
        editor: lesson.editor || undefined,
      };

      const { data } = await api.post(
        `/courses/${courseId}/sections/${section._id}/lessons`,
        payload
      );

      // Update lesson with returned data (including _id)
      const newSections = structuredClone(sections);
      newSections[sectionIndex].lessons[lessonIndex] = {
        ...lesson,
        _id: data._id,
      };
      setSections(newSections);

      showAlert("Lesson saved successfully", "success");
    } catch (error) {
      showAlert("Failed to save lesson", "error");
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold">Add Lessons to {course?.title}</h1>
        <p className="text-gray-600">
          Organize your course content by sections
        </p>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="sections">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="space-y-6"
            >
              {sections.map((section, index) => (
                <Draggable
                  key={`section-${index}`}
                  draggableId={`section-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="bg-white rounded-lg shadow-sm p-6 space-y-4">
                        <div className="flex justify-between items-center">
                          <div
                            {...provided.dragHandleProps}
                            className="cursor-move p-2 hover:bg-gray-100 rounded"
                          >
                            ⋮⋮
                          </div>
                          <span className="text-sm text-gray-500">
                            Section {index + 1}
                          </span>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Section Title
                            </label>
                            <input
                              type="text"
                              value={section.title}
                              onChange={(e) =>
                                handleSectionChange(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Section Description
                            </label>
                            <input
                              type="text"
                              value={section.description}
                              onChange={(e) =>
                                handleSectionChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                            />
                          </div>
                        </div>

                        <div className="space-y-4">
                          {section.lessons.map((lesson, lessonIndex) => (
                            <div
                              key={lessonIndex}
                              className="border rounded-lg p-4 space-y-4 bg-gray-50"
                            >
                              <div className="flex justify-between items-start">
                                <h3 className="text-sm font-medium text-gray-700">
                                  Lesson {lessonIndex + 1}
                                </h3>
                                <div className="flex gap-2">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleSaveLesson(index, lessonIndex)
                                    }
                                    className={`text-green-600 hover:text-green-800 p-1 ${
                                      lesson._id ? "hidden" : "" // Hide if lesson already exists
                                    }`}
                                  >
                                    <svg
                                      className="w-5 h-5"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M5 13l4 4L19 7"
                                      />
                                    </svg>
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveLesson(index, lessonIndex)
                                    }
                                    className="text-red-600 hover:text-red-800 p-1"
                                  >
                                    <svg
                                      className="w-5 h-5"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">
                                    Lesson Title
                                  </label>
                                  <input
                                    type="text"
                                    value={lesson.title}
                                    onChange={(e) =>
                                      handleLessonChange(
                                        index,
                                        lessonIndex,
                                        "title",
                                        e.target.value
                                      )
                                    }
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                  />
                                </div>
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">
                                    Description
                                  </label>
                                  <textarea
                                    value={lesson.description}
                                    onChange={(e) =>
                                      handleLessonChange(
                                        index,
                                        lessonIndex,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                    rows={3}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    placeholder="Brief description of the lesson content..."
                                  />
                                </div>
                              </div>

                              <div className="grid grid-cols-2 gap-4">
                                <div>
                                  <label className="block text-sm font-medium text-gray-700">
                                    Duration (minutes)
                                  </label>
                                  <input
                                    type="number"
                                    value={lesson.duration}
                                    onChange={(e) =>
                                      handleLessonChange(
                                        index,
                                        lessonIndex,
                                        "duration",
                                        e.target.value
                                      )
                                    }
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                  />
                                </div>
                                <div className="flex items-center mt-6">
                                  <input
                                    type="checkbox"
                                    checked={lesson.isFree}
                                    onChange={(e) =>
                                      handleLessonChange(
                                        index,
                                        lessonIndex,
                                        "isFree",
                                        e.target.checked
                                      )
                                    }
                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                  />
                                  <label className="ml-2 block text-sm text-gray-900">
                                    Free Preview
                                  </label>
                                </div>
                              </div>

                              <div>
                                <label className="block text-sm font-medium text-gray-700">
                                  Video Upload
                                </label>
                                <input
                                  type="file"
                                  accept="video/*"
                                  onChange={(e) => {
                                    const file = e.target.files?.[0];
                                    if (file) {
                                      handleVideoUpload(
                                        index,
                                        lessonIndex,
                                        file
                                      );
                                    }
                                  }}
                                  className="mt-1 block w-full"
                                />
                                {uploadProgress &&
                                  uploadProgress.sectionIndex === index &&
                                  uploadProgress.lessonIndex ===
                                    lessonIndex && (
                                    <div className="mt-2">
                                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                                        <div
                                          className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                                          style={{
                                            width: `${uploadProgress.progress}%`,
                                          }}
                                        />
                                      </div>
                                      <p className="text-sm text-gray-600 mt-1">
                                        Uploading: {uploadProgress.progress}%
                                      </p>
                                    </div>
                                  )}
                              </div>

                              <div>
                                <label className="block text-sm font-medium text-gray-700">
                                  Code Editor Tool
                                </label>
                                <select
                                  value={lesson.editor?.id || ""}
                                  onChange={(e) => {
                                    const selectedEditor =
                                      AVAILABLE_EDITORS.find(
                                        (editor) => editor.id === e.target.value
                                      );
                                    handleLessonChange(
                                      index,
                                      lessonIndex,
                                      "editor",
                                      selectedEditor || undefined
                                    );
                                  }}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                >
                                  <option value="">No editor</option>
                                  {AVAILABLE_EDITORS.map((editor) => (
                                    <option key={editor.id} value={editor.id}>
                                      {editor.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                  Assignment
                                </label>
                                <div className="space-y-4 p-4 border rounded-lg">
                                  <div>
                                    <label className="block text-sm text-gray-600">
                                      Title
                                    </label>
                                    <input
                                      type="text"
                                      value={lesson.assignment?.title || ""}
                                      onChange={(e) =>
                                        handleLessonChange(
                                          index,
                                          lessonIndex,
                                          "assignment",
                                          {
                                            title: e.target.value,
                                            description:
                                              lesson.assignment?.description ||
                                              "",
                                            dueDate:
                                              lesson.assignment?.dueDate || "",
                                            instructions:
                                              lesson.assignment?.instructions ||
                                              "",
                                            attachments:
                                              lesson.assignment?.attachments,
                                          } as Assignment
                                        )
                                      }
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                  </div>

                                  <div>
                                    <label className="block text-sm text-gray-600">
                                      Description
                                    </label>
                                    <textarea
                                      value={
                                        lesson.assignment?.description || ""
                                      }
                                      onChange={(e) =>
                                        handleLessonChange(
                                          index,
                                          lessonIndex,
                                          "assignment",
                                          {
                                            ...lesson.assignment,
                                            description: e.target.value,
                                          } as Assignment
                                        )
                                      }
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                      rows={3}
                                    />
                                  </div>

                                  <div>
                                    <label className="block text-sm text-gray-600">
                                      Due Date
                                    </label>
                                    <input
                                      type="datetime-local"
                                      value={
                                        lesson.assignment?.dueDate
                                          ? new Date(lesson.assignment.dueDate)
                                              .toISOString()
                                              .slice(0, 16)
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleLessonChange(
                                          index,
                                          lessonIndex,
                                          "assignment",
                                          {
                                            ...lesson.assignment,
                                            dueDate: new Date(
                                              e.target.value
                                            ).toISOString(),
                                          } as Assignment
                                        )
                                      }
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    />
                                  </div>

                                  <div>
                                    <label className="block text-sm text-gray-600">
                                      Instructions
                                    </label>
                                    <textarea
                                      value={
                                        lesson.assignment?.instructions || ""
                                      }
                                      onChange={(e) =>
                                        handleLessonChange(
                                          index,
                                          lessonIndex,
                                          "assignment",
                                          {
                                            ...lesson.assignment,
                                            instructions: e.target.value,
                                          } as Assignment
                                        )
                                      }
                                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                      rows={4}
                                    />
                                  </div>

                                  <div>
                                    <label className="block text-sm text-gray-600">
                                      Attachments
                                    </label>
                                    <input
                                      type="file"
                                      onChange={async (e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                          const formData = new FormData();
                                          formData.append("file", file);
                                          try {
                                            const { data } = await api.post(
                                              "/courses/upload/file",
                                              formData,
                                              {
                                                headers: {
                                                  "Content-Type":
                                                    "multipart/form-data",
                                                },
                                              }
                                            );
                                            handleLessonChange(
                                              index,
                                              lessonIndex,
                                              "assignment",
                                              {
                                                title:
                                                  lesson.assignment?.title ||
                                                  "",
                                                description:
                                                  lesson.assignment
                                                    ?.description || "",
                                                dueDate:
                                                  lesson.assignment?.dueDate ||
                                                  "",
                                                instructions:
                                                  lesson.assignment
                                                    ?.instructions || "",
                                                attachments: data.fileUrl,
                                              } as Assignment
                                            );
                                            showAlert(
                                              "File uploaded successfully",
                                              "success"
                                            );
                                          } catch (error) {
                                            showAlert(
                                              "Failed to upload file",
                                              "error"
                                            );
                                          }
                                        }
                                      }}
                                      className="mt-1 block w-full"
                                    />
                                    {lesson.assignment?.attachments && (
                                      <a
                                        href={lesson.assignment.attachments}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-sm text-blue-600 hover:text-blue-800 mt-1 inline-block"
                                      >
                                        View Attachment
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div>
                                <label className="block text-sm font-medium text-gray-700">
                                  Resources
                                </label>
                                <div className="space-y-2">
                                  <input
                                    type="file"
                                    onChange={async (e) => {
                                      const file = e.target.files?.[0];
                                      if (file) {
                                        const formData = new FormData();
                                        formData.append("file", file);
                                        try {
                                          const { data } = await api.post(
                                            "/courses/upload/file",
                                            formData,
                                            {
                                              headers: {
                                                "Content-Type":
                                                  "multipart/form-data",
                                              },
                                            }
                                          );
                                          handleLessonChange(
                                            index,
                                            lessonIndex,
                                            "resources",
                                            [
                                              ...(lesson.resources || []),
                                              data.fileUrl,
                                            ]
                                          );
                                          showAlert(
                                            "Resource uploaded successfully",
                                            "success"
                                          );
                                        } catch (error) {
                                          showAlert(
                                            "Failed to upload resource",
                                            "error"
                                          );
                                        }
                                      }
                                    }}
                                    className="mt-1 block w-full"
                                  />
                                  {lesson.resources &&
                                    lesson.resources.length > 0 && (
                                      <div className="mt-2 space-y-1">
                                        {lesson.resources.map(
                                          (resource, idx) => (
                                            <div
                                              key={idx}
                                              className="flex items-center justify-between"
                                            >
                                              <a
                                                href={resource}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-sm text-blue-600 hover:text-blue-800"
                                              >
                                                {resource.split("/").pop()}
                                              </a>
                                              <button
                                                onClick={() => {
                                                  const newResources =
                                                    lesson.resources.filter(
                                                      (_, i) => i !== idx
                                                    );
                                                  handleLessonChange(
                                                    index,
                                                    lessonIndex,
                                                    "resources",
                                                    newResources
                                                  );
                                                }}
                                                className="text-red-600 hover:text-red-800"
                                              >
                                                <i className="fas fa-times"></i>
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="flex gap-2">
                            <button
                              type="button"
                              onClick={() => handleAddLesson(index)}
                              className="mt-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              Add Lesson
                            </button>
                            {sections.length > 1 && (
                              <button
                                type="button"
                                onClick={() => handleRemoveSection(index)}
                                className="mt-2 inline-flex items-center px-3 py-2 border border-red-300 shadow-sm text-sm leading-4 font-medium rounded-md text-red-700 bg-white hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                              >
                                Remove Section
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={handleAddSection}
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Add Section
        </button>

        <button
          onClick={handleSubmit}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default AddLessons;
