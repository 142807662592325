import { FC, useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { StreamService } from "../../services/StreamService";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { getRtcToken } from "../../services/AgoraApiServices";
import { AgoraService } from "../../services/AgoraService";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";

interface ChatMessage {
  message: string;
  sender: {
    userId: string;
    name: string;
    role: string;
  };
  timestamp: string;
}

interface Participant {
  userId: string;
  name: string;
  role: string;
  peerId?: string;

  id: string;
  handRaised: boolean;
  raisedAt?: Date;
}

const LiveClassroom: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const streamServiceRef = useRef<StreamService | null>(null);
  const remoteUserRef = useRef<IAgoraRTCRemoteUser | null>(null);

  // State
  const [isMicOn, setIsMicOn] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isHandRaised, setIsHandRaised] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showLeaveConfirm, setShowLeaveConfirm] = useState(false);
  const [isConnecting, setIsConnecting] = useState(true);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  const sessionTitle = location.state?.title;

  useEffect(() => {
    if (!id) return;
    setIsConnecting(true);

    const agoraMethods = async (userId: number, roomId: string) => {
      //initialize agora service
      const token = await getRtcToken(roomId, userId);
      // Make sure we have a valid token
      if (!token) {
        throw new Error("Failed to get valid Agora token");
      }

      console.log("token:::", token);
      const agoraService = new AgoraService("user", token);
      agoraService.initializeAgoraClient("user");

      // Setup event handlers before joining
      agoraService.setupEventListeners();

      agoraService.joinAsAudience(roomId, userId);

      //handle remote users
      agoraService
        .getClient()
        ?.on("user-published", async (user, mediaType) => {
          await agoraService.getClient()?.subscribe(user, mediaType);
          //console.log("subscribe success");
          remoteUserRef.current = user;

          //display remote video
          if (mediaType === "video") {
            //console.log("displaying remote video");

            const videoElement = document.getElementById("camera-video");
            if (videoElement) {
              user.videoTrack?.play(videoElement);
              setIsConnecting(false);
            }
          }

          //display remote audio
          if (mediaType === "audio") {
            //console.log("displaying remote audio");
            user.audioTrack?.play();
          }
        });
    };

    const initializeStream = async () => {
      try {
        const service = new StreamService();
        streamServiceRef.current = service;
        const user = JSON.parse(localStorage.getItem("user") || "{}");

        const userId = user.id || user._id || user.userId;
        const name = user.username || user.name;
        const role = user.role || "user";

        // Join room first
        service.socketInstance.emit("join-room", {
          roomId: id,
          userId: userId,
          role: role,
          name: name,
        });

        // Debug room joining
        service.socketInstance.on("joined-room", (data: any) => {
          console.log("Successfully joined room:", data);
        });

        // Debug socket connection
        service.socketInstance.on("connect", () => {
          console.log("Socket connected, joining room:", id);
          setIsSocketConnected(true);
        });

        // Handle socket connection states
        service.socketInstance.on("connect", () => {
          setIsSocketConnected(true);
        });

        service.socketInstance.on("disconnect", () => {
          setIsSocketConnected(false);
        });

        // Generate unique random 8 digit number for the instructor
        const uuid = Math.floor(10000000 + Math.random() * 90000000);
        console.log("uuid:::", uuid);
        await agoraMethods(uuid, id);
      } catch (error) {
        console.error("Failed to initialize:", error);
        setIsConnecting(false);
      }
    };

    initializeStream();

    // Cleanup function
    return () => {
      if (streamServiceRef.current) {
        streamServiceRef.current.stopStreaming();
      }
    };
  }, [id]);

  // Message sending function
  const sendMessage = () => {
    if (!chatMessage.trim() || !streamServiceRef.current) return;

    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const userId = user._id || user.userId || user.id;
    const name = user.username || user.name || "Anonymous";

    const newMessage = {
      message: chatMessage.trim(),
      sender: {
        userId: userId,
        name: name,
        role: user.role || "user",
      },
      timestamp: new Date().toISOString(),
    };

    // Emit message to server
    streamServiceRef.current.socketInstance.emit("chat-message", {
      roomId: id,
      message: newMessage.message,
      sender: newMessage.sender,
    });

    // Add to local messages
    //setMessages((prev) => [...prev, newMessage]);
    setChatMessage("");
  };

  // Listen for chat messages
  useEffect(() => {
    if (!streamServiceRef.current) return;

    streamServiceRef.current.socketInstance.on("chat-message", (data: any) => {
      const message = {
        message: data.message,
        sender: {
          userId: data.sender.userId || "anonymous",
          name: data.sender.name || "Anonymous",
          role: data.sender.role || "user",
        },
        timestamp: data.timestamp || new Date().toISOString(),
      };
      setMessages((prev) => [...prev, message]);
    });

    return () => {
      streamServiceRef.current?.socketInstance.off("chat-message");
    };
  }, []);

  // Auto scroll chat
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Media control handlers
  const toggleMic = async () => {
    try {
      remoteUserRef.current?.audioTrack?.setVolume(isMicOn ? 0 : 100);
      setIsMicOn(!isMicOn);
    } catch (error) {
      console.error("Error toggling microphone:", error);
    }
  };

  const toggleCamera = async () => {
    try {
      setIsCameraOn(!isCameraOn);
    } catch (error) {
      console.error("Error toggling camera:", error);
    }
  };

  // Inside your component
  useEffect(() => {
    if (!id || !streamServiceRef.current) return;

    const service = streamServiceRef.current;
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    // Join room and announce presence
    service.socketInstance.emit("participant-joined", {
      roomId: id,
      participant: {
        userId: user.id || user._id || user.userId,
        name: user.username || user.name,
        role: user.role || "user",
      },
    });

    // Listen for new participants
    service.socketInstance.on(
      "participant-joined",
      (data: { participant: Participant; timestamp: string }) => {
        console.log(`${data.participant.name} joined at ${data.timestamp}`);
        setParticipants((prev) => {
          if (prev.some((p) => p.userId === data.participant.userId)) {
            return prev;
          }
          return [...prev, data.participant];
        });
      }
    );

    // Listen for participants leaving
    service.socketInstance.on(
      "participant-left",
      (data: { participant: Participant; timestamp: string }) => {
        console.log(`${data.participant.name} left at ${data.timestamp}`);
        setParticipants((prev) =>
          prev.filter((p) => p.userId !== data.participant.userId)
        );
      }
    );

    // Request initial participants list
    service.socketInstance.emit("get-participants", { roomId: id });

    // Handle get-participants response
    service.socketInstance.on(
      "get-participants",
      (response: {
        success: boolean;
        participants?: Participant[];
        error?: string;
      }) => {
        if (response.success && response.participants) {
          console.log("Current participants:", response.participants);
          setParticipants(response.participants);
        } else {
          console.error("Failed to get participants:", response.error);
        }
      }
    );

    // Listen for participants list updates
    service.socketInstance.on(
      "participants-updated",
      (data: { participants: Participant[]; timestamp: string }) => {
        console.log("Participants list updated at", data);
        console.log(
          `Participants list updated at ${data.timestamp}:`,
          data.participants
        );
        setParticipants(data.participants);
      }
    );

    // Cleanup listeners on unmount
    return () => {
      service.socketInstance.off("participant-joined");
      service.socketInstance.off("participant-left");
      service.socketInstance.off("get-participants");
      service.socketInstance.off("participants-updated");
    };
  }, [id]);

  const handleLeaveClass = () => {
    if (streamServiceRef.current) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");

      // Emit participant-left before leaving
      streamServiceRef.current.socketInstance.emit("participant-left", {
        roomId: id,
        participant: {
          userId: user.id || user._id || user.userId,
          name: user.username || user.name,
          role: user.role || "user",
        },
      });

      streamServiceRef.current.stopStreaming();
    }

    navigate("/live-classes");
  };

  return (
    <div className="h-screen bg-gray-100">
      {/* Top Navigation */}
      <div className="bg-white shadow-sm px-6 py-3 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <h1 className="text-xl font-semibold">{sessionTitle}</h1>
          <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-sm">
            Live
          </span>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-600">
            {participants.length} Participants
          </span>
          <button
            onClick={() => setShowLeaveConfirm(true)}
            className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
          >
            Leave Class
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex h-[calc(100vh-64px)]">
        {/* Left Panel - Video/Screen Share */}
        <div className="flex-1 p-4">
          <div className="bg-white rounded-lg shadow-sm h-full flex flex-col">
            {/* Video Container */}
            <div className="flex-1 bg-gray-900 rounded-t-lg relative overflow-hidden">
              {/* Main Video */}
              <video
                id="camera-video"
                autoPlay
                playsInline
                className="w-full h-full object-contain transform scale-x-[-1]"
              />

              {/* Loading State */}
              {isConnecting && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-900">
                  <div className="text-center">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white mx-auto mb-4"></div>
                    <p className="text-white">
                      Connecting to instructor's stream...
                    </p>
                  </div>
                </div>
              )}

              {/* Connection Status */}
              {!isSocketConnected && (
                <div className="absolute top-4 left-4 bg-red-500 text-white px-3 py-1 rounded-full text-sm">
                  Disconnected
                </div>
              )}

              {/* Student Controls */}
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/50 flex justify-center space-x-4">
                <button
                  onClick={toggleMic}
                  className="p-3 bg-white/10 hover:bg-white/20 rounded-full relative group"
                >
                  <i
                    className={`fas fa-microphone${
                      isMicOn ? "" : "-slash"
                    } text-${isMicOn ? "white" : "red-400"}`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isMicOn ? "Mute" : "Unmute"}
                  </span>
                </button>

                <button
                  onClick={toggleCamera}
                  className="p-3 bg-white/10 hover:bg-white/20 rounded-full relative group"
                >
                  <i
                    className={`fas fa-video${
                      isCameraOn ? "" : "-slash"
                    } text-${isCameraOn ? "white" : "red-400"}`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isCameraOn ? "Stop Video" : "Start Video"}
                  </span>
                </button>

                <button
                  onClick={() => setIsHandRaised(!isHandRaised)}
                  className="p-3 bg-white/10 hover:bg-white/20 rounded-full relative group"
                >
                  <i
                    className={`fas fa-hand-paper text-${
                      isHandRaised ? "yellow-400" : "white"
                    }`}
                  />
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-black/75 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 whitespace-nowrap transition-opacity">
                    {isHandRaised ? "Lower Hand" : "Raise Hand"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Right Panel - Chat & Participants */}
        <div className="w-80 bg-white border-l">
          <div className="h-full flex flex-col">
            {/* Tabs */}
            <div className="flex border-b">
              <button
                onClick={() => setShowParticipants(false)}
                className={`flex-1 px-4 py-3 text-sm font-medium ${
                  !showParticipants
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-600 hover:text-gray-800"
                }`}
              >
                Chat
              </button>
              <button
                onClick={() => setShowParticipants(true)}
                className={`flex-1 px-4 py-3 text-sm font-medium ${
                  showParticipants
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-600 hover:text-gray-800"
                }`}
              >
                Participants
              </button>
            </div>

            {/* Content */}
            {!showParticipants ? (
              <>
                {/* Chat Messages */}
                <div className="flex-1 overflow-y-auto p-4">
                  <div className="space-y-4">
                    {messages.map((msg, index) => (
                      <div key={index} className="flex items-start space-x-3">
                        <img
                          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                            msg?.sender?.name || "Anonymous"
                          )}`}
                          alt={msg?.sender?.name || "Anonymous"}
                          className="w-8 h-8 rounded-full"
                        />
                        <div>
                          <p className="text-sm font-medium">
                            {msg?.sender?.name || "Anonymous"}
                          </p>
                          <p className="text-sm text-gray-600">
                            {msg?.message || ""}
                          </p>
                          <span className="text-xs text-gray-400">
                            {msg?.timestamp
                              ? new Date(msg.timestamp).toLocaleTimeString()
                              : ""}
                          </span>
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                </div>

                {/* Chat Input */}
                <div className="p-4 border-t">
                  <div className="flex space-x-2">
                    <input
                      type="text"
                      value={chatMessage}
                      onChange={(e) => setChatMessage(e.target.value)}
                      onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                      placeholder="Type a message..."
                      className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                      onClick={sendMessage}
                      disabled={!chatMessage.trim()}
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </>
            ) : (
              /* Participants List */
              <div className="flex-1 overflow-y-auto p-4">
                <div className="space-y-3">
                  {participants.map((participant, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between"
                    >
                      <div className="flex items-center space-x-3">
                        <img
                          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                            participant.name
                          )}`}
                          alt={participant.name}
                          className="w-8 h-8 rounded-full"
                        />
                        <span className="font-medium">{participant.name}</span>
                      </div>
                      {participant.handRaised && (
                        <i className="fas fa-hand-paper text-yellow-500" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Connection Status */}
      {!isSocketConnected && (
        <div className="fixed bottom-4 left-4 bg-red-100 text-red-700 px-4 py-2 rounded-md">
          Disconnected from server. Trying to reconnect...
        </div>
      )}

      <ConfirmModal
        isOpen={showLeaveConfirm}
        onClose={() => setShowLeaveConfirm(false)}
        onConfirm={handleLeaveClass}
        title="Leave Class"
        message="Are you sure you want to leave the class? This action cannot be undone."
      />
    </div>
  );
};

export default LiveClassroom;
