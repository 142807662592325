import React, { FC } from "react";

const Footer: FC = () => {
  return (
    <footer className="bg-[#020A13] text-white">
      <div className="container mx-auto px-6 py-12">
        {/* Main Footer Content */}
        <div className="flex justify-between pb-16">
          {/* Logo & Description */}
          <div className="max-w-[450px]">
            <div className="flex items-center gap-2 mb-4">
              <img src="/logo.png" alt="" className="h-8" />
              <span className="text-xl">Code Coast</span>
            </div>
            <p className="text-sm text-gray-400">
              Unlock Your Potential and Transform Your Future
            </p>
            {/* Contact */}
            <div className="mt-8 rounded-lg py-4 px-2 grid grid-cols-2">
              <div>
                <div className="text-white font-medium mb-2">Email</div>
                <div className="text-white">contact@codecoast.com</div>
              </div>
              <div className="ml-4">
                <div className="text-white font-medium mb-2">Telephone</div>
                <div className="text-white">+233 50 7856 567</div>
              </div>
            </div>
          </div>

          {/* Navigation Links */}
          <div>
            <h3 className="text-lg font-medium mb-6">Social Media</h3>
            <ul className="space-y-4 text-gray-400">
              <li>Instagram</li>
              <li>Twitter</li>
              <li>LinkedIn</li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-medium mb-6">Support</h3>
            <ul className="space-y-4 text-gray-400">
              <li>Privacy Policy</li>
              <li>Terms</li>
              <li>Help and Support</li>
            </ul>
          </div>

          {/* Download Apps */}
          <div>
            <h3 className="text-lg font-medium mb-6">Download App</h3>
            <div className="flex flex-col gap-3">
              <img
                src="/images/footer/google_play.png"
                alt="Get it on Google Play"
                className="h-10 w-32"
              />
              <img
                src="/images/footer/app_store.png"
                alt="Download on App Store"
                className="h-10 w-32"
              />
            </div>
          </div>
        </div>

        {/* Bottom Navigation & Copyright */}
        <div className=" text-white py-4">
          <div className="container mx-auto px-6 flex justify-between items-center">
            <p className="text-sm text-gray-400">
              © Copyright Code Coast Learn 2023
            </p>
            <nav>
              <ul className="flex gap-8 text-sm">
                <li>HOME</li>
                <li>LEARNING</li>
                <li>COURSES</li>
                <li>LIVE CLASSES</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
