import React, { FC, useState, useEffect } from "react";
import JavaScriptEditor from "./JavaScriptEditor";
import PythonPlayground from "./PythonPlayground";
import FlutterEditor from "./FlutterEditor";
import ZappEditor from "./ZappEditor";
import ReactEditor from "./ReactEditor";
import JupyterEditor from "./JupyterEditor";
import CyberSecurityEditor from "./CyberSecurityEditor";
import MySQLEditor from "./DatabaseEditors/MySQLEditor";
import PostgreSQLEditor from "./DatabaseEditors/PostgreSQLEditor";
import MongoDBEditor from "./DatabaseEditors/MongoDBEditor";
import SQLiteEditor from "./DatabaseEditors/SQLiteEditor";
import RedisEditor from "./DatabaseEditors/RedisEditor";
import MariaDBEditor from "./DatabaseEditors/MariaDBEditor";
import OraclePLSQLEditor from "./DatabaseEditors/OraclePLSQLEditor";
import MSSQLEditor from "./DatabaseEditors/MSSQLEditor";
import OracleDBEditor from "./DatabaseEditors/OracleDBEditor";
import OpenMLEditor from "./OpenMLEditor";

interface EditorSelectorProps {
  editorType: string;
  initialCode?: string;
  onChange?: (code: string) => void;
}

const EditorSelector: FC<EditorSelectorProps> = ({
  editorType,
  initialCode = "",
  onChange,
}) => {
  const [code, setCode] = useState(initialCode);

  // Reset code when editor type changes
  useEffect(() => {
    setCode(initialCode);
  }, [editorType, initialCode]);

  const handleCodeChange = (newCode: string) => {
    setCode(newCode);
    if (onChange) {
      onChange(newCode);
    }
  };

  // Use editorType in key to force re-render when switching
  switch (editorType) {
    case "react":
      return (
        <ReactEditor
          key={`react-${editorType}`}
          code={code}
          onChange={handleCodeChange}
          language="typescript"
        />
      );
    case "javascript":
      return (
        <JavaScriptEditor
          key={`js-${editorType}`}
          code={code}
          onChange={handleCodeChange}
          language="javascript"
        />
      );
    case "python":
      return (
        <PythonPlayground
          key={`python-${editorType}`}
          code={code}
          onChange={handleCodeChange}
          language="python"
        />
      );
    case "flutter":
      return (
        <FlutterEditor
          key={`flutter-${editorType}`}
          code={code}
          onChange={handleCodeChange}
          language="dart"
        />
      );
    case "zapp":
      return (
        <ZappEditor
          key={`zapp-${editorType}`}
          code={code}
          onChange={handleCodeChange}
          language="dart"
        />
      );
    case "jupyter":
      return (
        <JupyterEditor
          key={`jupyter-${editorType}`}
          code={code}
          onChange={handleCodeChange}
          language="python"
        />
      );
    case "cybersecurity":
      return (
        <CyberSecurityEditor
          key={`cyber-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "mysql":
      return (
        <MySQLEditor
          key={`mysql-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "postgresql":
      return (
        <PostgreSQLEditor
          key={`postgresql-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "mongodb":
      return (
        <MongoDBEditor
          key={`mongodb-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "sqlite":
      return (
        <SQLiteEditor
          key={`sqlite-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "redis":
      return (
        <RedisEditor
          key={`redis-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "mariadb":
      return (
        <MariaDBEditor
          key={`mariadb-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "oracle-plsql":
      return (
        <OraclePLSQLEditor
          key={`oracle-plsql-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "mssql":
      return (
        <MSSQLEditor
          key={`mssql-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "oracle":
      return (
        <OracleDBEditor
          key={`oracle-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    case "openml":
      return (
        <OpenMLEditor
          key={`openml-${editorType}`}
          code={code}
          onChange={handleCodeChange}
        />
      );
    default:
      return <div>Unsupported editor type</div>;
  }
};

export default EditorSelector;
