import React, { FC, useState, useEffect, useCallback } from "react";
import CreateCourseModal from "./CreateCourseModal";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { API_URL } from "../../../config";
import EditCourseModal from "./EditCourseModal";
import ViewCourseModal from "./ViewCourseModal";
import { useNavigate } from "react-router-dom";
import { adminFetch } from "../../../utils/fetchInterceptor";

interface Course {
  _id: string;
  title: string;
  description: string;
  thumbnail: string;
  price: number;
  isPublished: boolean;
  category?: {
    _id: string;
    name: string;
  };
  tags: string[];
  author: string;
  createdAt: string;
  updatedAt: string;
}

const AdminCourses: FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [statusFilter, setStatusFilter] = useState<
    "all" | "published" | "draft"
  >("all");
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  //const [isCreating, setIsCreating] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState<Course | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [courseToEdit, setCourseToEdit] = useState<Course | null>(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [courseToView, setCourseToView] = useState<Course | null>(null);
  const accessToken = localStorage.getItem("access_token");
  const navigate = useNavigate();

  const filteredCourses = courses.filter((course) => {
    const matchesSearch = course.title
      ? course.title.toLowerCase().includes(searchTerm.toLowerCase())
      : false;

    const matchesStatus =
      statusFilter === "all" ||
      (statusFilter === "published" && course.isPublished) ||
      (statusFilter === "draft" && !course.isPublished);

    return matchesSearch && matchesStatus;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCourses.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredCourses.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const fetchCourses = useCallback(async () => {
    try {
      setIsFetching(true);
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/courses`
      );

      if (!response) return; // Handle redirect case
      if (!response.ok) throw new Error("Failed to fetch courses");

      const data = await response.json();
      console.log("Fetched courses:", data);
      setCourses(data.courses || data);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
      console.error("Error fetching courses:", err);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  // const handleCreateCourse = async (formData: FormData) => {
  //   try {
  //     setIsCreating(true);
  //     console.log("Creating course with data:", {
  //       title: formData.get("title"),
  //       description: formData.get("description"),
  //       price: formData.get("price"),
  //       isPublished: formData.get("isPublished"),
  //       categoryId: formData.get("categoryId"),
  //       tags: formData.get("tags"),
  //       author: formData.get("author"),
  //     });

  //     const response = await fetch(`${API_URL}/courses`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       body: formData,
  //     });

  //     const responseData = await response.json();
  //     console.log("Server response:", responseData);

  //     if (response.ok) {
  //       await fetchCourses();
  //       setShowCreateModal(false);
  //     } else {
  //       throw new Error(
  //         responseData.message || `Failed to create course: ${response.status}`
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error creating course:", error);
  //     setError(
  //       error instanceof Error ? error.message : "Failed to create course"
  //     );
  //   } finally {
  //     setIsCreating(false);
  //   }
  // };

  const handleDeleteClick = (course: Course) => {
    setCourseToDelete(course);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!courseToDelete) return;

    try {
      setIsDeleting(true);
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/courses/${courseToDelete._id}`,
        { method: "DELETE" }
      );

      if (!response) return; // Handle redirect case
      if (response.ok) {
        await fetchCourses();
        setShowDeleteModal(false);
        setCourseToDelete(null);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete course");
      }
    } catch (error) {
      console.error("Error deleting course:", error);
      setError(
        error instanceof Error ? error.message : "Failed to delete course"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEditClick = (course: Course) => {
    setCourseToEdit(course);
    setShowEditModal(true);
  };

  const handleEditSubmit = async (courseId: string, formData: FormData) => {
    try {
      setIsEditing(true);
      const response = await fetch(`${API_URL}/courses/${courseId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        await fetchCourses();
        setShowEditModal(false);
        setCourseToEdit(null);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update course");
      }
    } catch (error) {
      console.error("Error updating course:", error);
      setError(
        error instanceof Error ? error.message : "Failed to update course"
      );
    } finally {
      setIsEditing(false);
    }
  };

  const handleViewClick = (course: Course) => {
    setCourseToView(course);
    setShowViewModal(true);
  };

  const handleManageLessons = (courseId: string) => {
    navigate(`/admin/courses/${courseId}/lessons`);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Courses Management</h1>
        <button
          onClick={() => setShowCreateModal(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          Add New Course
        </button>
      </div>

      {/* Statistics Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Total Courses</p>
              <h3 className="text-2xl font-bold text-gray-900">
                {courses.length}
              </h3>
            </div>
            <div className="bg-blue-100 rounded-full p-3">
              <i className="fas fa-book text-blue-600 text-xl"></i>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">
                Published Courses
              </p>
              <h3 className="text-2xl font-bold text-gray-900">
                {courses.filter((course) => course.isPublished).length}
              </h3>
            </div>
            <div className="bg-green-100 rounded-full p-3">
              <i className="fas fa-check-circle text-green-600 text-xl"></i>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Draft Courses</p>
              <h3 className="text-2xl font-bold text-gray-900">
                {courses.filter((course) => !course.isPublished).length}
              </h3>
            </div>
            <div className="bg-yellow-100 rounded-full p-3">
              <i className="fas fa-pencil-alt text-yellow-600 text-xl"></i>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-gray-500">Average Price</p>
              <h3 className="text-2xl font-bold text-gray-900">
                $
                {courses.length > 0
                  ? (
                      courses.reduce((acc, curr) => acc + curr.price, 0) /
                      courses.length
                    ).toFixed(2)
                  : "0.00"}
              </h3>
            </div>
            <div className="bg-purple-100 rounded-full p-3">
              <i className="fas fa-dollar-sign text-purple-600 text-xl"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex gap-4">
          <div className="flex-1">
            <input
              type="text"
              placeholder="Search courses..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <select
            value={statusFilter}
            onChange={(e) =>
              setStatusFilter(e.target.value as "all" | "published" | "draft")
            }
            className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Status</option>
            <option value="published">Published</option>
            <option value="draft">Draft</option>
          </select>
        </div>
      </div>

      {/* Initial Loading State */}
      {isFetching && courses.length === 0 && (
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner size="large" color="#2563eb" />
        </div>
      )}

      {/* Error State */}
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg mb-6">
          {error}
        </div>
      )}

      {/* Courses Grid */}
      {!isFetching && !error && (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Course
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Category
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created At
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((course: Course) => (
                <tr key={course._id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <img
                          src={course.thumbnail}
                          alt={course.title}
                          className="h-10 w-10 rounded-full object-cover"
                          onError={(e) => {
                            e.currentTarget.src = "/placeholder-image.jpg";
                          }}
                        />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {course.title}
                        </div>
                        <div className="text-sm text-gray-500">
                          {course.description.substring(0, 50)}...
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {course.category?.name || "Uncategorized"}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      ${course.price}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        course.isPublished
                          ? "bg-green-100 text-green-800"
                          : "bg-gray-100 text-gray-800"
                      }`}
                    >
                      {course.isPublished ? "Published" : "Draft"}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(course.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleViewClick(course)}
                      className="text-gray-600 hover:text-gray-900 mr-3"
                    >
                      <i className="fas fa-eye"></i>
                    </button>
                    <button
                      onClick={() => handleManageLessons(course._id)}
                      className="text-indigo-600 hover:text-indigo-900 mr-3"
                      title="Manage Lessons"
                    >
                      <i className="fas fa-book-open"></i>
                    </button>
                    <button
                      onClick={() => handleEditClick(course)}
                      className="text-blue-600 hover:text-blue-900 mr-3"
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      onClick={() => handleDeleteClick(course)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Empty State */}
      {filteredCourses.length === 0 && !isFetching && (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <div className="text-gray-400 mb-2">
            <i className="fas fa-book text-4xl"></i>
          </div>
          <h3 className="text-lg font-medium text-gray-900">
            {courses.length === 0 ? "No Courses" : "No matching courses found"}
          </h3>
          <p className="text-gray-500">
            {courses.length === 0
              ? "Get started by creating a new course"
              : "Try adjusting your search or filter"}
          </p>
        </div>
      )}

      {/* Pagination */}
      {filteredCourses.length > 0 && (
        <div className="mt-6 flex items-center justify-between bg-white px-4 py-3 rounded-lg shadow">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">{indexOfFirstItem + 1}</span> to{" "}
                <span className="font-medium">
                  {Math.min(indexOfLastItem, filteredCourses.length)}
                </span>{" "}
                of <span className="font-medium">{filteredCourses.length}</span>{" "}
                results
              </p>
            </div>
            <div>
              <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (number) => (
                    <button
                      key={number}
                      onClick={() => handlePageChange(number)}
                      className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                        currentPage === number
                          ? "z-10 bg-blue-50 border-blue-500 text-blue-600"
                          : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                      }`}
                    >
                      {number}
                    </button>
                  )
                )}
              </nav>
            </div>
          </div>
        </div>
      )}

      <CreateCourseModal
        isOpen={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          fetchCourses();
        }}
        //isCreating={isCreating}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <div className="text-center">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Delete Course
              </h3>
              <p className="text-sm text-gray-500">
                Are you sure you want to delete "{courseToDelete?.title}"? This
                action cannot be undone.
              </p>
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={() => {
                  setShowDeleteModal(false);
                  setCourseToDelete(null);
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                disabled={isDeleting}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 text-sm font-medium disabled:opacity-50"
              >
                {isDeleting ? (
                  <div className="flex items-center">
                    <LoadingSpinner size="small" color="#ffffff" />
                    <span className="ml-2">Deleting...</span>
                  </div>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {courseToEdit && (
        <EditCourseModal
          isOpen={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setCourseToEdit(null);
          }}
          onSubmit={handleEditSubmit}
          course={courseToEdit}
          isEditing={isEditing}
        />
      )}

      {courseToView && (
        <ViewCourseModal
          isOpen={showViewModal}
          onClose={() => {
            setShowViewModal(false);
            setCourseToView(null);
          }}
          course={courseToView}
        />
      )}
    </div>
  );
};

export default AdminCourses;
