import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home/Home";
import Learning from "./pages/Learning/Learning";
import Courses from "./pages/Courses";
import LiveClasses from "./pages/LiveClasses";
import CourseVideoDetails from "./pages/CourseVideoDetails";
import CourseDetails from "./pages/CourseDetails";
import LiveSessionDetails from "./pages/LiveSessionDetails";
import LiveClassroom from "./pages/LiveClassroom";
import { setPageTitle } from "./utils/seo";
import { AuthProvider } from "./context/AuthContext";
import AdminLogin from "./pages/Admin/AdminLogin";
import Overview from "./pages/Admin/Overview/Overview";
import AdminLayout from "./layouts/AdminLayout";
import AdminCourses from "./pages/Admin/Courses/AdminCourses";
import AdminUsers from "./pages/Admin/Users/AdminUsers";
import AdminCategories from "./pages/Admin/Categories/AdminCategories";
import AdminSettings from "./pages/Admin/Settings/AdminSettings";
import AdminLiveSessions from "./pages/Admin/LiveSessions/AdminLiveSessions";
import AdminCalendar from "./pages/Admin/Calendar/AdminCalendar";
import AdminSubscribers from "./pages/Admin/Subscribers/AdminSubscribers";
import AdminSubscriptions from "./pages/Admin/Subscriptions/AdminSubscriptions";
import AdminCourseTools from "./pages/Admin/CourseTools/AdminCourseTools";
import { Provider } from "react-redux";
import { store } from "./store";
import { AlertProvider } from "./context/AlertContext";
import ProtectedRoute from "./components/ProtectedRoute";
import AddLessons from "./pages/Admin/Courses/AddLessons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import InstructorClassroom from "./pages/Classroom/InstructorClassroom";

const queryClient = new QueryClient();

const App = () => {
  React.useEffect(() => {
    setPageTitle("Learn Programming from Industry Experts");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AlertProvider>
          <AuthProvider>
            <Router>
              <Routes>
                <Route element={<Layout />}>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/learning"
                    element={
                      <ProtectedRoute>
                        <Learning />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/courses" element={<Courses />} />
                  <Route path="/live-classes" element={<LiveClasses />} />
                  <Route
                    path="/course/:courseId/classroom"
                    element={<CourseVideoDetails />}
                  />
                  <Route
                    path="/course/:id"
                    element={<CourseDetails isEnrolled={false} />}
                  />
                  <Route
                    path="/live-session/:id"
                    element={<LiveSessionDetails />}
                  />
                  <Route path="/classroom/:id" element={<LiveClassroom />} />
                </Route>

                <Route path="/admin/login" element={<AdminLogin />} />
                <Route element={<AdminLayout />}>
                  <Route path="/admin" element={<Overview />} />
                  <Route path="/admin/courses" element={<AdminCourses />} />
                  <Route
                    path="/admin/courses/:courseId/lessons"
                    element={<AddLessons />}
                  />
                  <Route
                    path="/admin/live-sessions"
                    element={<AdminLiveSessions />}
                  />
                  <Route path="/admin/calendar" element={<AdminCalendar />} />
                  <Route path="/admin/users" element={<AdminUsers />} />
                  <Route
                    path="/admin/subscribers"
                    element={<AdminSubscribers />}
                  />
                  <Route
                    path="/admin/categories"
                    element={<AdminCategories />}
                  />
                  <Route
                    path="/admin/course-tools"
                    element={<AdminCourseTools />}
                  />
                  <Route path="/admin/settings" element={<AdminSettings />} />
                  <Route
                    path="/admin/subscriptions"
                    element={<AdminSubscriptions />}
                  />
                </Route>

                <Route
                  path="/instructor/classroom/:roomId"
                  element={<InstructorClassroom />}
                />
              </Routes>
            </Router>
          </AuthProvider>
        </AlertProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
