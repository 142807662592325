import { FC } from "react";
import { BaseEditorProps } from "./BaseEditor";

const OpenMLEditor: FC<BaseEditorProps> = () => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex justify-between items-center p-2 bg-gray-800 text-white">
        <div className="flex items-center space-x-2">
          <span className="text-sm font-medium">OpenML Editor</span>
        </div>
        <div className="text-sm text-gray-300">Powered by OpenML</div>
      </div>
      <div className="flex-1 bg-white">
        <iframe
          src="https://www.openml.org/search?type=task"
          className="w-full h-full border-none"
          title="OpenML Platform"
          allow="fullscreen"
          sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
        />
      </div>
    </div>
  );
};

export default OpenMLEditor;
