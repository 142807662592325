import React, { FC } from "react";
import { LiveClassStudent } from "../../types";

interface LiveClassSectionProps {
  students: LiveClassStudent[];
}

const LiveClassSection: FC<LiveClassSectionProps> = ({ students }) => {
  return (
    <div className="bg-white rounded-3xl p-12 shadow-sm relative overflow-hidden">
      {/* Live Tag */}
      <div className="inline-flex items-center gap-2 bg-orange-50 text-orange-500 px-3 py-1.5 rounded-full text-sm font-medium mb-6">
        <span className="w-2 h-2 bg-orange-500 rounded-full animate-pulse"></span>
        Live
      </div>

      <div className="flex justify-between items-start gap-12">
        <div className="max-w-md">
          <h2 className="text-2xl font-bold mb-4">
            We are live!! Join our live classes now alongside over 1000+
            students.
          </h2>
          <p className="text-gray-500 mb-8">
            Worried about learning alone without the company of other students
            or tutors, well that's over now, you can now join our live classes
            and learn alongside your fellow learners and amazing instructors.
          </p>
          <button className="bg-[#0284C7] text-white px-6 py-2.5 rounded-lg text-sm font-medium hover:bg-blue-600 transition-colors">
            Join now
          </button>
        </div>

        {/* Live Section Image */}
        <div className="relative flex-shrink-0">
          <img
            src="/images/live_section_image.png"
            alt="Live class session"
            className="w-[420px] h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default LiveClassSection;
