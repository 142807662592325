import React, { FC } from "react";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useQuery } from "@tanstack/react-query";
import { adminFetch } from "../../../utils/fetchInterceptor";

interface DashboardStats {
  revenue: {
    total: number;
    growth: number;
    trend: Array<{ month: string; amount: number }>;
  };
  students: {
    active: number;
    growth: number;
    weeklyTrend: Array<{ week: number; count: number }>;
  };
  courses: {
    completion: number;
    activeCourses: number;
    totalHoursWatched: number;
  };
  learning: {
    totalHoursWatched: number;
    avgCompletionTime: number;
    certificatesIssued: number;
  };
  engagement: {
    dailyActiveUsers: number;
    avgSessionDuration: number;
    assignmentsSubmitted: number;
  };
  platform: {
    uptime: number;
    avgLoadTime: number;
    errorRate: number;
  };
  satisfaction: {
    nps: number;
    studentSatisfaction: number;
    overall: number;
    responseRate: number;
  };
}

const Overview: FC = () => {
  const { data: stats } = useQuery<DashboardStats>({
    queryKey: ["dashboard-stats"],
    queryFn: async () => {
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/dashboard/stats`
      );

      if (!response) return null; // Handle redirect case
      if (!response.ok) throw new Error("Failed to fetch dashboard stats");

      return response.json();
    },
  });

  // Format stats for display
  const formattedStats = {
    revenue: {
      total: stats ? `$${stats.revenue.total.toLocaleString()}` : "$0",
      trend: stats
        ? `${stats.revenue.growth > 0 ? "+" : ""}${stats.revenue.growth}%`
        : "0%",
      data: stats?.revenue.trend || [],
    },
    students: {
      active: stats?.students.active || 0,
      trend: stats
        ? `${stats.students.growth > 0 ? "+" : ""}${stats.students.growth}%`
        : "0%",
      data:
        stats?.students.weeklyTrend.map((w) => ({
          name: `Week ${w.week}`,
          users: w.count,
        })) || [],
    },
    courses: {
      published: stats?.courses.activeCourses || 0,
      completionRate: stats ? `${stats.courses.completion}%` : "0%",
    },
    engagement: {
      dailyActiveUsers: stats?.engagement.dailyActiveUsers || 0,
      avgSessionDuration: stats
        ? `${stats.engagement.avgSessionDuration} mins`
        : "0 mins",
      assignmentsSubmitted: stats?.engagement.assignmentsSubmitted || 0,
    },
    learning: {
      totalHoursWatched: stats?.learning.totalHoursWatched || 0,
      avgCompletionTime: stats
        ? `${stats.learning.avgCompletionTime} weeks`
        : "0 weeks",
      certificatesIssued: stats?.learning.certificatesIssued || 0,
    },
    performance: {
      platformUptime: stats ? `${stats.platform.uptime}%` : "0%",
      avgLoadTime: stats ? `${stats.platform.avgLoadTime}s` : "0s",
      errorRate: stats ? `${stats.platform.errorRate}%` : "0%",
    },
    satisfaction: {
      nps: stats?.satisfaction.nps || 0,
      studentSatisfaction: stats?.satisfaction.studentSatisfaction || 0,
      overall: stats?.satisfaction.overall || 0,
      responseRate: stats?.satisfaction.responseRate || 0,
    },
  };

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-bold">Dashboard Overview</h1>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Total Revenue</div>
          <div className="text-2xl font-bold">
            {formattedStats.revenue.total}
          </div>
          <div className="flex items-center">
            <span className="text-sm text-green-600">
              {formattedStats.revenue.trend} vs last month
            </span>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Active Students</div>
          <div className="text-2xl font-bold">
            {formattedStats.students.active}
          </div>
          <div className="flex items-center">
            <span className="text-sm text-green-600">
              {formattedStats.students.trend} growth
            </span>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Course Completion</div>
          <div className="text-2xl font-bold">
            {formattedStats.courses.completionRate}
          </div>
          <div className="flex items-center">
            <span className="text-sm text-blue-600">
              {formattedStats.courses.published} active courses
            </span>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-sm text-gray-500">Student Satisfaction</div>
          <div className="text-2xl font-bold">
            {formattedStats.satisfaction.studentSatisfaction}
          </div>
          <div className="flex items-center">
            <span className="text-sm text-blue-600">
              NPS Score: {formattedStats.satisfaction.nps}
            </span>
          </div>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-medium mb-4">Revenue Trend</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={formattedStats.revenue.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="amount"
                  stroke="#3B82F6"
                  fill="#93C5FD"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-medium mb-4">User Growth</h3>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={formattedStats.students.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="users"
                  stroke="#10B981"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Detailed Stats Sections */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {/* Learning Metrics */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-medium mb-4">Learning Metrics</h3>
          <div className="space-y-4">
            <div>
              <div className="text-sm text-gray-500">Total Hours Watched</div>
              <div className="text-xl font-semibold">
                {formattedStats.learning.totalHoursWatched.toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Avg. Completion Time</div>
              <div className="text-xl font-semibold">
                {formattedStats.learning.avgCompletionTime}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Certificates Issued</div>
              <div className="text-xl font-semibold">
                {formattedStats.learning.certificatesIssued.toLocaleString()}
              </div>
            </div>
          </div>
        </div>

        {/* Engagement Metrics */}
        <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-medium mb-4">Engagement Metrics</h3>
          <div className="space-y-4">
            <div>
              <div className="text-sm text-gray-500">Daily Active Users</div>
              <div className="text-xl font-semibold">
                {formattedStats.engagement.dailyActiveUsers.toLocaleString()}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Avg. Session Duration</div>
              <div className="text-xl font-semibold">
                {formattedStats.engagement.avgSessionDuration}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Assignments Submitted</div>
              <div className="text-xl font-semibold">
                {formattedStats.engagement.assignmentsSubmitted.toLocaleString()}
              </div>
            </div>
          </div>
        </div>

        {/* Platform Performance */}
        {/* <div className="bg-white p-4 rounded-lg shadow">
          <h3 className="text-lg font-medium mb-4">Platform Performance</h3>
          <div className="space-y-4">
            <div>
              <div className="text-sm text-gray-500">Platform Uptime</div>
              <div className="text-xl font-semibold">
                {formattedStats.performance.platformUptime}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Avg. Load Time</div>
              <div className="text-xl font-semibold">
                {formattedStats.performance.avgLoadTime}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Error Rate</div>
              <div className="text-xl font-semibold">
                {formattedStats.performance.errorRate}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Overview;
