import React, { FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SubscriptionModal from "../../components/SubscriptionModal";
import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

interface Assignment {
  title: string;
  description: string;
  dueDate: string;
  instructions: string;
  attachments: string;
  _id: string;
}

interface Lesson {
  title: string;
  description: string;
  videoUrl: string;
  duration: number;
  isPreview: boolean;
  courseId: string;
  sectionId: string;
  tools: {
    id: string;
    name: string;
    url: string;
  }[];
  resources: string[];
  assignment?: Assignment;
  _id: string;
}

interface ContentSection {
  title: string;
  description: string;
  lessons: Lesson[];
  sectionPosition: number;
  _id: string;
}

interface LiveSession {
  _id: string;
  title: string;
  description: string;
  scheduledFor: string;
  duration: number;
  courseId: string;
  instructorId: { username: string };
  instId: string;
  status: string;
  totalSignups: number;
  maxStudents: number;
  roomId: string;
  meetingId: string;
  registeredStudents: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Course {
  _id: string;
  title: string;
  description: string;
  thumbnail: string;
  author: string;
  price: number;
  rating: number;
  reviews: number;
  categoryId: string;
  detailedDescription: {
    overview: string;
    certification: string;
    targetAudience: string;
    learningObjectives: string[];
    _id: string;
  };
  totalLessons: number;
  certificate: boolean;
  language: string;
  quizzes: number;
  isActive: boolean;
  requiresSubscription: boolean;
  content: ContentSection[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  liveSessions?: LiveSession[];
}

type RouteParams = {
  id: string;
};

const LiveSessionDetails: FC = () => {
  const { id } = useParams<keyof RouteParams>();
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);
  const [countdown, setCountdown] = React.useState<string>("");
  const [showCalendarModal, setShowCalendarModal] = React.useState(false);
  const [pendingCalendarEvent, setPendingCalendarEvent] =
    React.useState<any>(null);

  const { data: course, isLoading } = useQuery<Course>({
    queryKey: ["course-details", id],
    queryFn: async () => {
      if (!id) throw new Error("Course ID is required");

      const { data } = await api.get(`/courses/${id}/live-sessions`);
      console.log("Course data:", data);

      return data;
    },
    enabled: !!id,
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      const nextSession = course?.liveSessions
        ?.filter((s) => s.status === "scheduled")
        .sort(
          (a, b) =>
            new Date(a.scheduledFor).getTime() -
            new Date(b.scheduledFor).getTime()
        )[0];

      if (nextSession) {
        const now = new Date().getTime();
        const sessionTime = new Date(nextSession.scheduledFor).getTime();
        const distance = sessionTime - now;

        if (distance > 0) {
          const hours = Math.max(0, Math.floor(distance / (1000 * 60 * 60)));
          const minutes = Math.max(
            0,
            Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          );
          const seconds = Math.max(
            0,
            Math.floor((distance % (1000 * 60)) / 1000)
          );

          setCountdown(
            `${Math.abs(hours).toString().padStart(2, "0")}:${Math.abs(minutes)
              .toString()
              .padStart(2, "0")}:${Math.abs(seconds)
              .toString()
              .padStart(2, "0")}`
          );
        } else {
          setCountdown("00:00:00");
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [course]);

  if (isLoading) return <LoadingSpinner />;
  if (!course) return <div>Course not found</div>;

  // TODO: Replace with actual subscription check
  const user = localStorage.getItem("user");
  //to json
  const userData = JSON.parse(user || "{}");
  const hasSubscription = userData.isSubscribed;

  const handleJoinSession = () => {
    const nextSession = getNextAvailableSession();
    if (hasSubscription) {
      navigate(`/classroom/${nextSession?.roomId}`, {
        state: { title: nextSession?.title },
      });
    } else {
      navigate(`/classroom/${nextSession?.roomId}`, {
        state: { title: nextSession?.title },
      });
      //   setShowModal(true);
    }
  };

  const handleSubscribe = () => {
    // TODO: Implement subscription logic
    setShowModal(false);
    handleJoinSession();
  };

  // Combine all registered students
  const allRegisteredStudents =
    course?.liveSessions?.reduce<string[]>((acc, session) => {
      return acc.concat(session.registeredStudents || []);
    }, []) || [];

  const addToCalendar = () => {
    if (!hasSubscription) {
      setShowModal(true);
      return;
    }

    course.liveSessions?.forEach((session) => {
      const startTime = new Date(session.scheduledFor);
      const endTime = new Date(startTime.getTime() + session.duration * 60000);

      const calendarEvent = {
        title: session.title,
        description: session.description,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        duration: session.duration,
        googleUrl: `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
          session.title
        )}&details=${encodeURIComponent(session.description)}&dates=${startTime
          .toISOString()
          .replace(/[-:]/g, "")
          .replace(/\.\d{3}/g, "")}/${endTime
          .toISOString()
          .replace(/[-:]/g, "")
          .replace(/\.\d{3}/g, "")}`,
        appleUrl: `webcal://calendar.google.com/calendar/ical/${encodeURIComponent(
          session.title
        )}/basic.ics?dates=${startTime
          .toISOString()
          .replace(/[-:]/g, "")
          .replace(/\.\d{3}/g, "")}/${endTime
          .toISOString()
          .replace(/[-:]/g, "")
          .replace(/\.\d{3}/g, "")}&details=${encodeURIComponent(
          session.description
        )}&text=${encodeURIComponent(session.title)}`,
      };

      setPendingCalendarEvent(calendarEvent);
      setShowCalendarModal(true);
    });
  };

  const getNextAvailableSession = () => {
    return course?.liveSessions
      ?.filter((s) => s.status === "scheduled")
      .sort(
        (a, b) =>
          new Date(a.scheduledFor).getTime() -
          new Date(b.scheduledFor).getTime()
      )[0];
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-3 gap-8">
          {/* Main Content */}
          <div className="col-span-2">
            {/* Session Preview */}
            <div className="relative aspect-video rounded-lg overflow-hidden mb-8">
              <img
                src={course.thumbnail}
                alt={course.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                <div className="text-center text-white">
                  {countdown !== "00:00:00" ? (
                    <>
                      <div className="text-5xl font-bold mb-2">{countdown}</div>
                      <p className="text-lg">Until session starts</p>
                    </>
                  ) : (
                    <>
                      <p className="text-lg mb-4">Session is live!</p>
                      <button
                        onClick={() =>
                          hasSubscription
                            ? navigate(
                                `/classroom/${
                                  getNextAvailableSession()?.roomId
                                }`
                              )
                            : setShowModal(true)
                        }
                        className="bg-[#0284C7] text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors"
                      >
                        {hasSubscription ? "Join Now" : "Subscribe to Join"}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Session Title & Stats */}
            <h1 className="text-2xl font-bold mb-4">{course.title}</h1>
            <div className="flex items-center gap-4 text-sm text-gray-600 mb-8">
              <div className="flex items-center gap-2">
                <svg
                  className="w-5 h-5 text-[#0284C7]"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" />
                </svg>
                <span>
                  {new Date(
                    getNextAvailableSession()?.scheduledFor || ""
                  ).toLocaleDateString()}{" "}
                  at{" "}
                  {new Date(
                    getNextAvailableSession()?.scheduledFor || ""
                  ).toLocaleTimeString()}
                </span>
              </div>
              <span>•</span>
              <span>{getNextAvailableSession()?.duration} minutes</span>
            </div>

            {/* Session Description */}
            <div className="bg-white rounded-xl p-8 mb-8">
              <h2 className="font-bold text-xl mb-4">Course Details</h2>
              <p className="text-gray-600 mb-8">
                {course.detailedDescription.overview}
              </p>

              <h3 className="font-bold mb-4">Learning Objectives:</h3>
              <ul className="space-y-3 mb-8">
                {course.detailedDescription.learningObjectives.map(
                  (objective, index) => (
                    <li
                      key={index}
                      className="flex items-start gap-3 text-gray-600"
                    >
                      <svg
                        className="w-5 h-5 text-[#0284C7] mt-0.5 flex-shrink-0"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                      </svg>
                      {objective}
                    </li>
                  )
                )}
              </ul>

              <h3 className="font-bold mb-4">Course Content:</h3>
              <ul className="space-y-3">
                {course.content.map((content, index) => (
                  <li
                    key={index}
                    className="flex items-start gap-3 text-gray-600"
                  >
                    <svg
                      className="w-5 h-5 text-[#0284C7] mt-0.5 flex-shrink-0"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                    </svg>
                    {content.title}
                  </li>
                ))}
              </ul>
            </div>

            {/* Participants */}
            <div className="bg-white rounded-lg shadow-sm p-6 mb-8">
              <h2 className="font-bold text-xl mb-4">Course Participants</h2>
              <div className="flex items-center gap-4">
                <div className="flex -space-x-3">
                  {allRegisteredStudents.slice(0, 5).map((student, i) => (
                    <div
                      key={i}
                      className="w-10 h-10 rounded-full border-2 border-white overflow-hidden"
                    >
                      <img
                        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          student
                        )}`}
                        alt={student}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </div>
                <p className="text-gray-600">
                  <span className="font-medium text-gray-900">
                    {course.liveSessions?.reduce(
                      (total, session) => total + session.totalSignups,
                      0
                    )}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium text-gray-900">
                    {course.liveSessions?.reduce(
                      (total, session) => total + session.maxStudents,
                      0
                    )}
                  </span>{" "}
                  total spots filled
                </p>
              </div>
              <div className="h-2 bg-gray-100 rounded-full">
                <div
                  className="h-full bg-[#0284C7] rounded-full"
                  style={{
                    width: `${
                      ((course.liveSessions?.reduce(
                        (total, session) => total + session.totalSignups,
                        0
                      ) || 0) /
                        (course.liveSessions?.reduce(
                          (total, session) => total + session.maxStudents,
                          0
                        ) || 1)) *
                      100
                    }%`,
                  }}
                />
                {/* <div className="text-sm text-gray-600">
                  <span className="font-medium">
                    {allRegisteredStudents.length}
                  </span>{" "}
                  total participants
                </div> */}
              </div>
            </div>
          </div>

          {/* Sidebar */}
          <div className="col-span-1">
            <div className="bg-white rounded-xl p-6 shadow-sm sticky top-24">
              <div className="mb-6">
                <div className="text-3xl font-bold text-[#0284C7]">
                  {hasSubscription ? "Free" : "Premium"}
                </div>
                <p className="text-gray-500 text-sm mt-1">
                  {hasSubscription
                    ? "with your subscription"
                    : "with a subscription plan"}
                </p>
              </div>
              <button
                onClick={handleJoinSession}
                className="w-full bg-[#0284C7] text-white py-3 rounded-lg hover:bg-blue-600 transition-colors mb-4"
              >
                {hasSubscription ? "Join Session" : "Subscribe to Join"}
              </button>
              <button
                onClick={addToCalendar}
                className="w-full border border-[#0284C7] text-[#0284C7] py-3 rounded-lg hover:bg-blue-50 transition-colors mb-8"
              >
                {hasSubscription
                  ? "Add to Calendar"
                  : "Subscribe to Add to Calendar"}
              </button>

              <h2 className="font-bold mb-6">Live Sessions</h2>
              <div className="space-y-4">
                {course.liveSessions
                  ?.filter((session) =>
                    ["active", "scheduled"].includes(session.status)
                  )
                  .map((session) => {
                    const date = new Date(session.scheduledFor);
                    const formattedDate = date.toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    });
                    const formattedTime = date.toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    });
                    const formattedDuration = `${Math.floor(
                      session.duration / 60
                    )}:${session.duration % 60}`;

                    return (
                      <div
                        key={session._id}
                        className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow"
                      >
                        <div className="flex justify-between items-start mb-4">
                          <div>
                            <h3 className="text-lg font-semibold text-gray-900 mb-2">
                              {session.title}
                            </h3>
                            <p className="text-gray-600">
                              {session.description}
                            </p>
                          </div>
                          <span
                            className={`px-3 py-1 rounded-full text-sm font-medium ${
                              session.status === "active"
                                ? "bg-green-100 text-green-800"
                                : "bg-blue-100 text-blue-800"
                            }`}
                          >
                            {session.status === "active" ? "Live" : "Upcoming"}
                          </span>
                        </div>

                        <div className="flex items-center gap-4 text-sm text-gray-600 mb-6">
                          <div className="flex items-center gap-2">
                            <svg
                              className="w-5 h-5 text-[#0284C7]"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" />
                            </svg>
                            <span>
                              {formattedDate} at {formattedTime}
                            </span>
                          </div>
                          <span>•</span>
                          <div className="flex items-center gap-2">
                            <svg
                              className="w-5 h-5 text-[#0284C7]"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" />
                            </svg>
                            <span>{formattedDuration} hours</span>
                          </div>
                        </div>

                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-4">
                            <div className="flex -space-x-3">
                              {session.registeredStudents
                                ?.slice(0, 5)
                                .map((student, i) => (
                                  <div
                                    key={i}
                                    className="w-10 h-10 rounded-full border-2 border-white overflow-hidden"
                                  >
                                    <img
                                      src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                                        student
                                      )}`}
                                      alt={student}
                                      className="w-full h-full object-cover"
                                    />
                                  </div>
                                ))}
                            </div>
                            <div className="text-sm text-gray-600">
                              <span className="font-medium">
                                {session.totalSignups || 0}
                              </span>{" "}
                              / {session.maxStudents} spots filled
                            </div>
                          </div>
                          <button
                            onClick={() =>
                              navigate(`/classroom/${session.roomId}`)
                            }
                            className="bg-[#0284C7] text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                          >
                            Join Session
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubscriptionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubscribe={handleSubscribe}
      />
      {showCalendarModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-xl font-bold mb-4">Choose Calendar Type</h3>
            <div className="space-y-4">
              <button
                onClick={() => {
                  window.open(pendingCalendarEvent.googleUrl, "_blank");
                  setShowCalendarModal(false);
                }}
                className="w-full bg-[#0284C7] text-white py-3 rounded-lg hover:bg-blue-600 transition-colors"
              >
                Add to Google Calendar
              </button>
              <button
                onClick={() => {
                  window.open(pendingCalendarEvent.appleUrl, "_blank");
                  setShowCalendarModal(false);
                }}
                className="w-full border border-[#0284C7] text-[#0284C7] py-3 rounded-lg hover:bg-blue-50 transition-colors"
              >
                Add to Apple Calendar
              </button>
            </div>
            <button
              onClick={() => setShowCalendarModal(false)}
              className="mt-4 w-full text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveSessionDetails;
