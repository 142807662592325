import React, { FC } from "react";
import { Link } from "react-router-dom";

export interface Course {
  _id: string;
  title: string;
  description: string;
  thumbnail: string;
  author: string;
  categoryId: {
    _id: string;
    title: string;
  };
  rating: number;
  reviews: number;
  price: number;
  totalLessons: number;
}

interface CourseCardProps {
  course: Course;
}

const CourseCard: FC<CourseCardProps> = ({ course }) => {
  return (
    <Link
      to={`/course/${course._id}`}
      className="block bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
    >
      <div className="aspect-w-16 aspect-h-9">
        <img
          src={course.thumbnail}
          alt={course.title}
          className="w-full h-48 object-cover"
        />
      </div>
      <div className="p-4">
        <span className="px-2 py-1 text-xs font-semibold bg-blue-100 text-blue-800 rounded-full">
          {course.categoryId.title}
        </span>
        <h3 className="text-lg font-semibold mt-2 mb-1">{course.title}</h3>
        <p className="text-sm text-gray-500 mb-2">{course.author}</p>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-yellow-400 mr-1">★</span>
            <span>{course.rating}</span>
            <span className="text-gray-500 ml-1">({course.reviews})</span>
          </div>
          <span className="font-bold text-blue-600">${course.price}</span>
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
