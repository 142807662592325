import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import LiveClassSection from "../../components/LiveClassSection";
import TestimonialCard from "../../components/TestimonialCard";
import TeacherCard from "../../components/TeacherCard/TeacherCard";
import { Testimonial } from "../../types";
import { testimonials } from "../../data/testimonials";
import { featuredTeacher } from "../../data/teachers";
import { students } from "../../data/students";
import WatchingCategory from "../../components/WatchingCategory";
import CTASection from "../../components/CTASection";
import { getCategories } from "../../services/categoryService";
import { Category } from "../../types";
import FeaturedCourses from "../../components/FeaturedCourses/FeaturedCourses";

const Home: FC = () => {
  const navigate = useNavigate();
  const [selectedTestimonial, setSelectedTestimonial] = useState(
    testimonials[0]
  );
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(data);
      } catch (err) {
        setError("Failed to load categories");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Auto-change testimonial every 5 seconds
  useEffect(() => {
    if (!isAutoPlaying) return;

    const interval = setInterval(() => {
      setSelectedTestimonial((current) => {
        const currentIndex = testimonials.findIndex((t) => t.id === current.id);
        const nextIndex = (currentIndex + 1) % testimonials.length;
        return testimonials[nextIndex];
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [isAutoPlaying]);

  // Pause auto-play when user interacts
  const handleTestimonialClick = (testimonial: Testimonial) => {
    setSelectedTestimonial(testimonial);
    setIsAutoPlaying(false);
  };

  // Manual navigation handlers
  const handlePrevious = () => {
    setIsAutoPlaying(false);
    const currentIndex = testimonials.findIndex(
      (t) => t.id === selectedTestimonial.id
    );
    const prevIndex =
      currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1;
    setSelectedTestimonial(testimonials[prevIndex]);
  };

  const handleNext = () => {
    setIsAutoPlaying(false);
    const currentIndex = testimonials.findIndex(
      (t) => t.id === selectedTestimonial.id
    );
    const nextIndex = (currentIndex + 1) % testimonials.length;
    setSelectedTestimonial(testimonials[nextIndex]);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <main>
        {/* Hero Section */}
        <section className="bg-[#020A13] text-white relative overflow-hidden min-h-[700px] mb-10 flex items-center">
          {/* Background Video */}
          <div className="absolute inset-0 w-full h-full">
            <video
              autoPlay
              muted={true}
              loop
              playsInline
              className="w-full h-full object-cover opacity-20"
            >
              <source
                src="https://codecoast-s3.s3.us-east-1.amazonaws.com/misc/narh.mp4"
                type="video/mp4"
              />
            </video>
            <div className="absolute inset-0 bg-gradient-to-r from-[#020A13] via-[#020A13]/60 to-transparent"></div>
          </div>

          <div className="container mx-auto px-6 relative z-10">
            <div className="grid grid-cols-2 gap-8">
              <div className="max-w-xl">
                <h1 className="text-6xl font-bold mb-6 leading-tight relative">
                  <span className="inline-block animate-glitch-1 before:content-['Welcome_to_Code_Coast,_a_cutting_edge_platform_to_learn_from_industry_experts.'] before:absolute before:left-[2px] before:text-[#00ff9f] before:w-full before:h-full before:animate-glitch-skew">
                    Welcome to Code Coast, a cutting edge platform to learn from
                    industry experts.
                  </span>
                  <span className="absolute top-0 left-0 w-full animate-glitch-2 before:content-['Welcome_to_Code_Coast,_a_cutting_edge_platform_to_learn_from_industry_experts.'] before:absolute before:left-[-2px] before:text-[#ff00c1] before:w-full before:h-full before:animate-glitch-skew">
                    Welcome to Code Coast, a cutting edge platform to learn from
                    industry experts.
                  </span>
                  <span className="absolute top-0 left-0 w-full animate-glitch-3 before:content-['Welcome_to_Code_Coast,_a_cutting_edge_platform_to_learn_from_industry_experts.'] before:absolute before:left-[1px] before:text-[#00ffff] before:w-full before:h-full before:animate-glitch-skew">
                    Welcome to Code Coast, a cutting edge platform to learn from
                    industry experts.
                  </span>
                </h1>
                <p className="text-gray-400 mb-8 text-lg">
                  Code Coast Learn is an interesting platform that will teach
                  you in more interactive way
                </p>
                <button
                  onClick={() => navigate("/courses")}
                  className="bg-[#0284C7] text-white px-8 py-3 rounded-md hover:bg-blue-600 transition-colors text-sm font-medium"
                >
                  Explore courses
                </button>
              </div>

              <div className="relative">
                <div className="absolute right-0 top-1/2 -translate-y-1/2 bg-[#1E293B]/80 rounded-lg p-8 backdrop-blur-sm w-[480px]">
                  <div className="flex items-center gap-2 mb-6">
                    <div className="flex gap-2">
                      <div className="w-4 h-4 rounded-full bg-red-500"></div>
                      <div className="w-4 h-4 rounded-full bg-yellow-500"></div>
                      <div className="w-4 h-4 rounded-full bg-green-500"></div>
                    </div>
                    <span className="text-gray-400 text-base">analysis.py</span>
                  </div>

                  {/* Python Data Science */}
                  <div className="font-mono text-base leading-relaxed">
                    <div className="text-blue-400 animate-type-line-1 overflow-hidden whitespace-nowrap">
                      import pandas as pd
                    </div>
                    <div className="text-blue-400 animate-type-line-2 overflow-hidden whitespace-nowrap">
                      import numpy as np
                    </div>
                    <div className="text-purple-400 animate-type-line-3 overflow-hidden whitespace-nowrap">
                      def analyze_data(df):
                    </div>
                    <div className="pl-6 text-green-400 animate-type-line-4 overflow-hidden whitespace-nowrap">
                      results = df.describe()
                    </div>
                    <div className="pl-6 text-yellow-400 animate-type-line-5 overflow-hidden whitespace-nowrap">
                      return results.to_json()
                    </div>
                    <div className="text-white animate-type-line-6 overflow-hidden whitespace-nowrap">
                      # Code Coast Analytics
                    </div>
                    <div className="animate-blink inline-block w-2.5 h-5 bg-white/70 ml-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container mx-auto px-6">
          {/* Categories Section */}
          <section className="mb-16">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-bold">Our Categories</h2>
              <p className="text-gray-500">
                Learn the essential skills for any domain
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {isLoading ? (
                // Add loading skeletons here
                Array(4)
                  .fill(0)
                  .map((_, i) => (
                    <div key={i.toString()} className="animate-pulse">
                      <div className="bg-gray-200 h-40 rounded-lg"></div>
                    </div>
                  ))
              ) : error ? (
                <div key="error" className="text-red-500">
                  {error}
                </div>
              ) : (
                categories.map((category: Category, index) => (
                  <CategoryCard key={category._id} category={category} />
                ))
              )}
            </div>
          </section>

          {/* Live Class Section */}
          <section className="mb-16">
            <LiveClassSection students={students} />
          </section>

          <FeaturedCourses />

          {/* Testimonials Section */}
          <section className="mb-20">
            <div className="flex gap-12 mb-12">
              {/* Navigation Arrows */}
              <div className="flex flex-col gap-3 self-center">
                <button
                  onClick={handlePrevious}
                  className="w-12 h-12 rounded-full border-2 border-gray-200 flex items-center justify-center text-[#0284C7] hover:border-[#0284C7] transition-colors"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                </button>
                <button
                  onClick={handleNext}
                  className="w-12 h-12 rounded-full border-2 border-[#0284C7] bg-[#0284C7] flex items-center justify-center text-white"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
              </div>

              {/* Title and Content */}
              <div className="flex-1">
                <h2 className="text-2xl font-bold mb-12">
                  What our students have to say
                </h2>

                <div className="flex gap-20">
                  {/* Testimonials List - Left Side */}
                  <div className="w-[60%] space-y-12">
                    {testimonials.map((testimonial) => (
                      <TestimonialCard
                        key={testimonial.id}
                        testimonial={testimonial}
                        isSelected={selectedTestimonial.id === testimonial.id}
                        onClick={() => handleTestimonialClick(testimonial)}
                      />
                    ))}
                  </div>

                  {/* Selected Testimonial Image - Right Side */}
                  <div className="w-[40%] sticky top-8">
                    <div className="aspect-[4/3] transition-opacity duration-500">
                      <img
                        key={selectedTestimonial.id}
                        src={selectedTestimonial.image}
                        alt={selectedTestimonial.name}
                        className="w-full h-full object-cover rounded-2xl animate-fade-in"
                      />
                    </div>
                    <h4 className="font-semibold text-xl mt-6 mb-1 transition-all duration-300">
                      {selectedTestimonial.name}
                    </h4>
                    <p className="text-gray-400 transition-all duration-300">
                      {selectedTestimonial.role}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Most Watching Category Section */}
          <WatchingCategory />

          {/* Featured Teacher Section */}
          <TeacherCard teacher={featuredTeacher} />

          {/* CTA Section */}
          <CTASection />
        </div>
      </main>
    </div>
  );
};

export default Home;
