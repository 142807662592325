import React, { FC, useState, useEffect } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Category } from "../../../types";
import { API_URL } from "../../../config";
import api from "../../../services/api";
import { useAlert } from "../../../context/AlertContext";

interface CreateCourseModalProps {
  isOpen: boolean;
  onClose: () => void;
  //isCreating: boolean;
}

interface DetailedDescription {
  overview: string;
  certification: string;
  targetAudience: string;
  learningObjectives: string[];
}

const CreateCourseModal: FC<CreateCourseModalProps> = ({
  isOpen,
  onClose,
  //isCreating,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: 0,
    isPublished: false,
    categoryId: "",
    tags: [] as string[],
    author: "",
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [tagInput, setTagInput] = useState("");
  const [detailedDescription, setDetailedDescription] =
    useState<DetailedDescription>({
      overview: "",
      certification: "",
      targetAudience: "",
      learningObjectives: [],
    });
  const [newObjective, setNewObjective] = useState("");
  const { showAlert } = useAlert();
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${API_URL}/categories?showInactive=false`
        );
        if (response.ok) {
          const data = await response.json();
          setCategories(data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (isOpen) {
      fetchCategories();
    }
  }, [isOpen]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddTag = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      const tag = tagInput.trim();
      if (tag && !formData.tags.includes(tag)) {
        setFormData({ ...formData, tags: [...formData.tags, tag] });
      }
      setTagInput("");
    }
  };

  const removeTag = (tagToRemove: string) => {
    setFormData({
      ...formData,
      tags: formData.tags.filter((tag) => tag !== tagToRemove),
    });
  };

  const handleObjectiveAdd = () => {
    if (newObjective.trim()) {
      setDetailedDescription((prev) => ({
        ...prev,
        learningObjectives: [...prev.learningObjectives, newObjective.trim()],
      }));
      setNewObjective("");
    }
  };

  const handleObjectiveRemove = (index: number) => {
    setDetailedDescription((prev) => ({
      ...prev,
      learningObjectives: prev.learningObjectives.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsCreating(true);
    try {
      const formDataToSend = new FormData();

      // Basic course info
      formDataToSend.append("title", formData.title.trim());
      formDataToSend.append("description", formData.description.trim());
      formDataToSend.append("price", formData.price.toString());
      formDataToSend.append("isPublished", formData.isPublished.toString());
      formDataToSend.append("categoryId", formData.categoryId);
      formDataToSend.append("author", formData.author.trim());

      // Tags as JSON string
      if (formData.tags.length > 0) {
        formDataToSend.append("tags", JSON.stringify(formData.tags));
      }

      // Detailed description fields separately
      formDataToSend.append(
        "detailedDescription.overview",
        detailedDescription.overview.trim()
      );
      formDataToSend.append(
        "detailedDescription.certification",
        detailedDescription.certification.trim()
      );
      formDataToSend.append(
        "detailedDescription.targetAudience",
        detailedDescription.targetAudience.trim()
      );
      formDataToSend.append(
        "detailedDescription.learningObjectives",
        JSON.stringify(detailedDescription.learningObjectives)
      );

      // Thumbnail if exists
      if (imageFile) {
        formDataToSend.append("thumbnail", imageFile);
      }

      await api.post("/courses", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      showAlert("Course created successfully!", "success");
      onClose();
    } catch (error: any) {
      console.error("Error creating course:", error);
      showAlert(
        error.response?.data?.message || "Failed to create course",
        "error"
      );
    } finally {
      setIsCreating(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl flex flex-col max-h-[90vh]">
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-start">
            <h3 className="text-lg font-medium text-gray-900">
              Create New Course
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <form onSubmit={handleSubmit} id="course-form" className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title *
                </label>
                <input
                  type="text"
                  required
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm px-4 py-2 transition duration-150 ease-in-out"
                  placeholder="Enter course title"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Category *
                </label>
                <select
                  required
                  value={formData.categoryId}
                  onChange={(e) =>
                    setFormData({ ...formData, categoryId: e.target.value })
                  }
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                >
                  <option value="">Select a category</option>
                  {categories.map((category: Category) => {
                    //console.log(category);
                    return (
                      <option key={category._id} value={category._id}>
                        {category.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description *
                </label>
                <textarea
                  required
                  rows={4}
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm px-4 py-2 transition duration-150 ease-in-out resize-none"
                  placeholder="Enter course description"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Price *
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    required
                    min="0"
                    step="0.01"
                    value={formData.price}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        price: parseFloat(e.target.value),
                      })
                    }
                    className="pl-7 mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Author *
                </label>
                <input
                  type="text"
                  required
                  value={formData.author}
                  onChange={(e) =>
                    setFormData({ ...formData, author: e.target.value })
                  }
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="e.g. John Doe"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Tags
                </label>
                <div className="mt-1 flex flex-wrap gap-2 p-2 border border-gray-300 rounded-md">
                  {formData.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                    >
                      {tag}
                      <button
                        type="button"
                        onClick={() => removeTag(tag)}
                        className="ml-1 inline-flex items-center p-0.5 hover:bg-blue-200 rounded-full"
                      >
                        <span className="sr-only">Remove tag</span>
                        <i className="fas fa-times text-xs"></i>
                      </button>
                    </span>
                  ))}
                  <input
                    type="text"
                    value={tagInput}
                    onChange={(e) => setTagInput(e.target.value)}
                    onKeyDown={handleAddTag}
                    className="flex-1 outline-none border-0 focus:ring-0 p-0.5 text-sm min-w-[120px]"
                    placeholder="Add tags (press Enter or comma)"
                  />
                </div>
                <p className="mt-1 text-xs text-gray-500">
                  Press Enter or comma to add tags
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Course Image
                </label>
                <div className="mt-1 flex items-center space-x-4">
                  <div className="flex-shrink-0 h-24 w-24 border rounded-lg overflow-hidden bg-gray-100">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="h-full w-full object-cover"
                      />
                    ) : (
                      <div className="h-full w-full flex items-center justify-center text-gray-400">
                        <i className="fas fa-image text-2xl"></i>
                      </div>
                    )}
                  </div>
                  <div className="flex-1">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                      id="course-image"
                    />
                    <label
                      htmlFor="course-image"
                      className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Upload Image
                    </label>
                  </div>
                </div>
              </div>

              <div className="md:col-span-2">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={formData.isPublished}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        isPublished: e.target.checked,
                      })
                    }
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label className="ml-2 block text-sm text-gray-900">
                    Publish course immediately
                  </label>
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block mb-1">Overview</label>
                  <textarea
                    value={detailedDescription.overview}
                    onChange={(e) =>
                      setDetailedDescription((prev) => ({
                        ...prev,
                        overview: e.target.value,
                      }))
                    }
                    className="w-full p-2 border rounded"
                    rows={4}
                    required
                  />
                </div>

                <div>
                  <label className="block mb-1">Certification Details</label>
                  <textarea
                    value={detailedDescription.certification}
                    onChange={(e) =>
                      setDetailedDescription((prev) => ({
                        ...prev,
                        certification: e.target.value,
                      }))
                    }
                    className="w-full p-2 border rounded"
                    rows={3}
                    required
                  />
                </div>

                <div>
                  <label className="block mb-1">Target Audience</label>
                  <textarea
                    value={detailedDescription.targetAudience}
                    onChange={(e) =>
                      setDetailedDescription((prev) => ({
                        ...prev,
                        targetAudience: e.target.value,
                      }))
                    }
                    className="w-full p-2 border rounded"
                    rows={3}
                    required
                  />
                </div>

                <div>
                  <label className="block mb-1">Learning Objectives</label>
                  <div className="flex gap-2 mb-2">
                    <input
                      type="text"
                      value={newObjective}
                      onChange={(e) => setNewObjective(e.target.value)}
                      className="flex-1 p-2 border rounded"
                      placeholder="Enter a learning objective"
                    />
                    <button
                      type="button"
                      onClick={handleObjectiveAdd}
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                      Add
                    </button>
                  </div>
                  <ul className="space-y-2">
                    {detailedDescription.learningObjectives.map(
                      (objective, index) => (
                        <li key={index} className="flex items-center gap-2">
                          <span>{objective}</span>
                          <button
                            type="button"
                            onClick={() => handleObjectiveRemove(index)}
                            className="text-red-500 hover:text-red-700"
                          >
                            ✕
                          </button>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="p-6 border-t border-gray-200 bg-gray-50">
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              form="course-form"
              disabled={isCreating}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 text-sm font-medium"
            >
              {isCreating ? (
                <div className="flex items-center">
                  <LoadingSpinner size="small" color="#ffffff" />
                  <span className="ml-2">Creating...</span>
                </div>
              ) : (
                "Create Course"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCourseModal;
