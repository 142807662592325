import AgoraRTC, {
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from "agora-rtc-sdk-ng";

export class AgoraService {
  private agoraClient: IAgoraRTCClient | null = null;
  public localAudioTrack: IMicrophoneAudioTrack | null = null;
  public localVideoTrack: ICameraVideoTrack | null = null;
  private token: string;
  //   private uid: string;
  constructor(role: "admin" | "user", token: string) {
    this.initializeAgoraClient(role);
    this.token = token;
  }

  public initializeAgoraClient(role: "admin" | "user") {
    console.log("Initializing Agora client", role);
    const agoraClient = AgoraRTC.createClient({
      mode: "live",
      codec: "h264",
      role: role === "admin" ? "host" : "audience",
      clientRoleOptions: {
        level: 1,
      },
    });
    this.agoraClient = agoraClient;
    console.log("Agora client initialized");
  }

  public async joinChannel(channelName: string, uid: number) {
    console.log(
      "Joining channel:::",
      process.env.REACT_APP_AGORA_APP_ID!,
      channelName,
      this.token,
      uid
    );
    await this.agoraClient?.join(
      process.env.REACT_APP_AGORA_APP_ID!,
      channelName,
      this.token,
      uid
    );

    console.log("Joined channel");
  }

  public async leaveChannel() {
    if (this.localAudioTrack) {
      this.localAudioTrack.close();
      this.localAudioTrack = null;
    }
    if (this.localVideoTrack) {
      this.localVideoTrack.close();
      this.localVideoTrack = null;
    }

    await this.agoraClient?.leave();
    console.log("Left channel");
  }

  // Join as a host
  public async joinAsHost(
    channelName: string,
    uid: number,
    callback: () => void
  ) {
    console.log("Joining channel as host", channelName, uid);
    await this.joinChannel(channelName, uid);
    // A host can both publish tracks and subscribe to tracks
    this.agoraClient?.setClientRole("host");
    console.log("Set client role to host");
    await this.createAndPublishLocalTracks();
    console.log("Host joined and published tracks.");
    callback();
  }

  // Join as an audience
  public async joinAsAudience(channelName: string, uid: number) {
    console.log("Joining channel as audience");
    await this.joinChannel(channelName, uid);
    // An audience can only subscribe to tracks
    this.agoraClient?.setClientRole("audience");
    console.log("Audience joined and subscribed to tracks.");
  }

  // Create and publish local tracks
  public async createAndPublishLocalTracks() {
    this.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    await this.agoraClient?.publish([
      this.localAudioTrack,
      this.localVideoTrack,
    ]);
    console.log("Local tracks created and published.");
  }

  //return client
  public getClient() {
    return this.agoraClient;
  }

  //setupEventListeners
  public setupEventListeners() {
    this.agoraClient?.on("user-joined", (user) => {
      console.log(`User ${user.uid} joined the channel`);
    });

    this.agoraClient?.on("user-left", (user) => {
      console.log(`User ${user.uid} left the channel`);
    });

    this.agoraClient?.on("user-published", (user, mediaType) => {
      console.log(`User ${user.uid} published ${mediaType} track`);
    });

    this.agoraClient?.on("user-unpublished", (user, mediaType) => {
      console.log(`User ${user.uid} unpublished ${mediaType} track`);
    });

    this.agoraClient?.on("error", (err: any) => {
      console.log(`Error: ${err.message}`);
    });

    this.agoraClient?.on(
      "connection-state-change",
      (state: any, reason: any) => {
        console.log(
          `Connection state changed to ${state} because of ${reason}`
        );
      }
    );
  }

  async turnOffCamera() {
    if (this.localVideoTrack) {
      await this.localVideoTrack.stop();
      await this.localVideoTrack.close();
    }
  }

  async turnOffMicrophone() {
    if (this.localAudioTrack) {
      await this.localAudioTrack.stop();
      await this.localAudioTrack.close();
    }
  }

  public async createLocalTracks() {
    const [microphoneTrack, cameraTrack] =
      await AgoraRTC.createMicrophoneAndCameraTracks();
    this.localAudioTrack = microphoneTrack;
    this.localVideoTrack = cameraTrack;
  }

  public stopLocalTracks() {
    this.localAudioTrack?.close();
    this.localVideoTrack?.close();
  }
}
