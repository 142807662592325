import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import CourseCard, { Course } from "../CourseCard";

interface Category {
  _id: string;
  title: string;
}

const FeaturedCourses: FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRandomCourses = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get("/courses/random");
        setCourses(data);
      } catch (err) {
        console.error("Error fetching random courses:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRandomCourses();
  }, []);

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {[1, 2, 3].map((i) => (
          <div key={i} className="animate-pulse">
            <div className="h-48 bg-gray-200 rounded-lg mb-4"></div>
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <section className="mb-20">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h2 className="text-2xl font-bold flex items-center gap-2">
            Browse from our{" "}
            <span className="text-[#0284C7]">broad selections</span>
          </h2>
          <p className="text-gray-500 mt-1">of courses</p>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate("/courses")}
            className="bg-[#0284C7] text-white px-5 py-2 rounded-lg text-sm font-medium"
          >
            Explore all
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {courses.map((course) => (
          <CourseCard key={course._id} course={course} />
        ))}
      </div>
    </section>
  );
};

export default FeaturedCourses;
