import axios from "axios";
import { API_URL } from "../config";
import { handleTokenExpiration } from "../utils/auth";

const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Skip token refresh for login request
    if (error.config?.headers?.skipAuthRefresh) {
      return Promise.reject(error);
    }
    return handleTokenExpiration(error).then((config) =>
      config ? api(config) : Promise.reject(error)
    );
  }
);

export default api;
