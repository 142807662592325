import React, { FC } from "react";

interface LoadingSpinnerProps {
  size?: "small" | "medium" | "large";
  color?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  size = "medium",
  color = "blue",
}) => {
  const sizeClasses = {
    small: "h-4",
    medium: "h-6",
    large: "h-8",
  };

  const bubbleBaseClass = "rounded-full inline-block mx-0.5 animate-bounce";
  const bubbleStyle = { backgroundColor: color };

  return (
    <div className="flex items-center justify-center space-x-1">
      <div
        className={`${bubbleBaseClass} ${sizeClasses[size]} aspect-square`}
        style={bubbleStyle}
      />
      <div
        className={`${bubbleBaseClass} ${sizeClasses[size]} aspect-square animate-bounce200`}
        style={bubbleStyle}
      />
      <div
        className={`${bubbleBaseClass} ${sizeClasses[size]} aspect-square animate-bounce400`}
        style={bubbleStyle}
      />
    </div>
  );
};

export default LoadingSpinner;
