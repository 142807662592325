import React, { FC } from "react";

interface Course {
  _id: string;
  title: string;
  description: string;
  thumbnail: string;
  price: number;
  isPublished: boolean;
  category?: {
    _id: string;
    name: string;
  };
  tags: string[];
  author: string;
  detailedDescription?: {
    overview: string;
    certification: string;
    targetAudience: string;
    learningObjectives: string[];
    _id: string;
  };
  createdAt: string;
  updatedAt: string;
}

interface ViewCourseModalProps {
  isOpen: boolean;
  onClose: () => void;
  course: Course;
}

const ViewCourseModal: FC<ViewCourseModalProps> = ({
  isOpen,
  onClose,
  course,
}) => {
  if (!isOpen || !course) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl flex flex-col max-h-[90vh]">
        {/* Fixed Header */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-start">
            <h3 className="text-lg font-medium text-gray-900">
              Course Details
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500"
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto p-6">
          <div className="space-y-6">
            {/* Course Image */}
            <div>
              <img
                src={course.thumbnail}
                alt={course.title}
                className="w-full h-48 object-cover rounded-lg"
              />
            </div>

            {/* Basic Info */}
            <div>
              <h2 className="text-xl font-bold mb-2">{course.title}</h2>
              <p className="text-gray-600">{course.description}</p>
            </div>

            {/* Course Details */}
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h4 className="font-medium text-gray-700">Category</h4>
                <p>{course.category?.name || "Uncategorized"}</p>
              </div>
              <div>
                <h4 className="font-medium text-gray-700">Author</h4>
                <p>{course.author}</p>
              </div>
              <div>
                <h4 className="font-medium text-gray-700">Price</h4>
                <p>${course.price}</p>
              </div>
              <div>
                <h4 className="font-medium text-gray-700">Status</h4>
                <p>{course.isPublished ? "Published" : "Draft"}</p>
              </div>
            </div>

            {/* Detailed Description */}
            <div className="space-y-4">
              <div>
                <h4 className="font-medium text-gray-700 mb-2">Overview</h4>
                <p className="text-gray-600">
                  {course.detailedDescription?.overview ||
                    "No overview available"}
                </p>
              </div>

              <div>
                <h4 className="font-medium text-gray-700 mb-2">
                  Certification Details
                </h4>
                <p className="text-gray-600">
                  {course.detailedDescription?.certification ||
                    "No certification details available"}
                </p>
              </div>

              <div>
                <h4 className="font-medium text-gray-700 mb-2">
                  Target Audience
                </h4>
                <p className="text-gray-600">
                  {course.detailedDescription?.targetAudience ||
                    "No target audience specified"}
                </p>
              </div>

              <div>
                <h4 className="font-medium text-gray-700 mb-2">
                  Learning Objectives
                </h4>
                <ul className="list-disc pl-5 space-y-1">
                  {course.detailedDescription?.learningObjectives?.map(
                    (objective, index) => (
                      <li key={index} className="text-gray-600">
                        {objective}
                      </li>
                    )
                  ) || (
                    <li className="text-gray-600">No objectives specified</li>
                  )}
                </ul>
              </div>
            </div>

            {/* Tags */}
            <div>
              <h4 className="font-medium text-gray-700 mb-2">Tags</h4>
              <div className="flex flex-wrap gap-2">
                {course.tags?.map((tag, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Fixed Footer */}
        <div className="p-6 border-t border-gray-200 bg-gray-50">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCourseModal;
