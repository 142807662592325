import React, { createContext, useContext, useState, FC } from "react";
import Alert from "../components/Alert/Alert";

interface AlertContextType {
  showAlert: (message: string, type: "success" | "error" | "info") => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [alertInfo, setAlertInfo] = useState({
    message: "",
    type: "info" as "success" | "error" | "info",
    isVisible: false,
  });

  const showAlert = (message: string, type: "success" | "error" | "info") => {
    setAlertInfo({ message, type, isVisible: true });
  };

  const hideAlert = () => {
    setAlertInfo((prev) => ({ ...prev, isVisible: false }));
  };

  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      <Alert
        message={alertInfo.message}
        type={alertInfo.type}
        isVisible={alertInfo.isVisible}
        onClose={hideAlert}
      />
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};
