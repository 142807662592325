import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useAuth } from "../../context/AuthContext";
import { setShowLoginModal } from "../../store/authSlice";
import { useDispatch } from "react-redux";

interface EnrolledCourse {
  _id: string;
  title: string;
  description: string;
  thumbnail: string;
  price: number;
  isActive: boolean;
  categoryId: {
    _id: string;
    title: string;
  };
  content: Array<{
    title: string;
    lessons: any[]; // Define specific lesson type if needed
  }>;
  author: string;
  totalLessons: number;
  createdAt: string;
  updatedAt: string;
  progress: number;
  lastAccessed: string;
}

const Learning: FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const [enrolledCourses, setEnrolledCourses] = useState<EnrolledCourse[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setShowLoginModal(true));
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    const fetchEnrolledCourses = async () => {
      try {
        setLoading(true);
        const { data } = await api.get("/courses/my-courses");
        setEnrolledCourses(data);
      } catch (error) {
        console.error("Failed to fetch enrolled courses:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchEnrolledCourses();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return null;

  const filteredCourses = enrolledCourses.filter((enrollment) =>
    enrollment?.title?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-50 pt-8 pb-16">
      <div className="container mx-auto px-6">
        {/* Search Section */}
        <div className="mb-12">
          <div className="relative max-w-2xl mx-auto">
            <input
              type="text"
              placeholder="Search course..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-3 pl-12 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <svg
              className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 -translate-y-1/2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>

        {/* Course Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {loading ? (
            <LoadingSpinner />
          ) : (
            filteredCourses.map((course) => (
              <Link
                to={`/course/${course._id}`}
                key={course._id}
                className="bg-white rounded-lg overflow-hidden border border-gray-100"
              >
                <div className="relative h-[200px]">
                  <img
                    src={course.thumbnail}
                    alt={course.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute bottom-0 left-0 right-0 h-1.5 bg-gray-100">
                    <div
                      className="h-full bg-[#55B7FF] transition-all duration-300"
                      style={{ width: `${course.progress}%` }}
                    />
                  </div>
                </div>
                <div className="p-5">
                  <h3 className="font-medium text-lg mb-2 line-clamp-2">
                    {course.title}
                  </h3>
                  <p className="text-gray-600 text-sm">{course.author}</p>
                  <span className="inline-block mt-3 text-xs font-medium text-[#55B7FF]">
                    {course.progress}% completed
                  </span>
                </div>
              </Link>
            ))
          )}
        </div>

        {filteredCourses.length === 0 && (
          <div className="text-center text-gray-500 mt-8">
            No courses found matching your search.
          </div>
        )}
      </div>
    </div>
  );
};

export default Learning;
