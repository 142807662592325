import React, { FC, useState, useEffect, useCallback } from "react";
import { Category } from "../../../types";
import CreateCategoryModal from "./CreateCategoryModal";
import DeleteCategoryModal from "./DeleteCategoryModal";
import EditCategoryModal from "./EditCategoryModal";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { adminFetch } from "../../../utils/fetchInterceptor";

const AdminCategories: FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState<
    "all" | "active" | "inactive"
  >("all");
  const [isLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const accessToken = localStorage.getItem("access_token");

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category | null>(
    null
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<Category | null>(null);

  const fetchCategories = useCallback(async () => {
    try {
      setIsFetching(true);
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/categories?showInactive=true`
      );

      if (!response) return;
      if (!response.ok) throw new Error("Failed to fetch categories");

      const data = await response.json();
      console.log("Fetched categories:", data);
      setCategories(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An error occurred");
      console.error("Error fetching categories:", err);
    } finally {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [accessToken, fetchCategories]);

  const handleCreateCategory = async (formData: FormData) => {
    try {
      setIsCreating(true);
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/categories`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response) return;
      if (response.ok) {
        await fetchCategories();
        setShowCreateModal(false);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create category");
      }
    } catch (error) {
      console.error("Error creating category:", error);
      setError(
        error instanceof Error ? error.message : "Failed to create category"
      );
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeleteClick = (category: Category) => {
    setCategoryToDelete(category);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!categoryToDelete) return;

    try {
      setIsDeleting(true);
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/categories/${categoryToDelete._id}`,
        { method: "DELETE" }
      );

      if (!response) return;
      if (response.ok) {
        await fetchCategories();
        setShowDeleteModal(false);
        setCategoryToDelete(null);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete category");
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      setError(
        error instanceof Error ? error.message : "Failed to delete category"
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEditClick = (category: Category) => {
    setCategoryToEdit(category);
    setShowEditModal(true);
  };

  const handleEditSubmit = async (categoryId: string, formData: FormData) => {
    try {
      setIsEditing(true);
      const response = await adminFetch(
        `${process.env.REACT_APP_API_URL}/categories/${categoryId}`,
        {
          method: "PATCH",
          body: formData,
        }
      );

      if (!response) return;
      if (response.ok) {
        await fetchCategories();
        setShowEditModal(false);
        setCategoryToEdit(null);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update category");
      }
    } catch (error) {
      console.error("Error updating category:", error);
      setError(
        error instanceof Error ? error.message : "Failed to update category"
      );
    } finally {
      setIsEditing(false);
    }
  };

  const filteredCategories = categories.filter((category) => {
    const matchesSearch = category.title
      ? category.title.toLowerCase().includes(searchTerm.toLowerCase())
      : false;

    const matchesStatus =
      statusFilter === "all" ||
      (statusFilter === "active" && Boolean(category.isActive)) ||
      (statusFilter === "inactive" && !Boolean(category.isActive));

    return matchesSearch && matchesStatus;
  });

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Categories Management</h1>
        <button
          onClick={() => setShowCreateModal(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          Add New Category
        </button>
      </div>

      {/* Filters */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex gap-4">
          <div className="flex-1">
            <input
              type="text"
              placeholder="Search categories..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <select
            value={statusFilter}
            onChange={(e) =>
              setStatusFilter(e.target.value as "all" | "active" | "inactive")
            }
            className="px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>

      {/* Initial Loading State */}
      {isFetching && categories.length === 0 && (
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner size="large" color="#2563eb" />
        </div>
      )}

      {/* Error State */}
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg mb-6">
          {error}
        </div>
      )}

      {/* Categories Grid */}
      {!isFetching && !error && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCategories.map((category: Category) => (
            <div
              key={category._id}
              className="bg-white rounded-lg shadow overflow-hidden"
            >
              {/* Category Image */}
              <div className="h-48 overflow-hidden">
                <img
                  src={category.image}
                  alt={category.title}
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.currentTarget.src = "/placeholder-image.jpg";
                  }}
                />
              </div>
              {/* Category Details */}
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-900">
                  {category.title}
                </h3>
                <p className="text-sm text-gray-500 mt-1">
                  {category.description}
                </p>
                <div className="mt-4 flex items-center justify-between">
                  <span
                    className={`px-2 py-1 text-xs font-semibold rounded-full ${
                      category.isActive
                        ? "bg-green-100 text-green-800"
                        : "bg-gray-100 text-gray-800"
                    }`}
                  >
                    {category.isActive ? "Active" : "Inactive"}
                  </span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditClick(category)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <i className="fas fa-edit"></i>
                    </button>
                    <button
                      onClick={() => handleDeleteClick(category)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Empty State */}
      {filteredCategories.length === 0 && (
        <div className="text-center py-12 bg-white rounded-lg shadow">
          <div className="text-gray-400 mb-2">
            <i className="fas fa-folder-open text-4xl"></i>
          </div>
          <h3 className="text-lg font-medium text-gray-900">
            {categories.length === 0
              ? "No Categories"
              : "No matching categories found"}
          </h3>
          <p className="text-gray-500">
            {categories.length === 0
              ? "Get started by creating a new category"
              : "Try adjusting your search or filter"}
          </p>
        </div>
      )}

      {/* Pagination */}
      <div className="mt-6 flex items-center justify-between bg-white px-4 py-3 rounded-lg shadow">
        <div className="flex-1 flex justify-between sm:hidden">
          <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Previous
          </button>
          <button className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
            Next
          </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">1</span> to{" "}
              <span className="font-medium">{filteredCategories.length}</span>{" "}
              of <span className="font-medium">{categories.length}</span>{" "}
              results
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination"
            >
              <button className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                Previous
              </button>
              <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                1
              </button>
              <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                2
              </button>
              <button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                Next
              </button>
            </nav>
          </div>
        </div>
      </div>

      {categoryToEdit && (
        <EditCategoryModal
          isOpen={showEditModal}
          onClose={() => {
            setShowEditModal(false);
            setCategoryToEdit(null);
          }}
          onSubmit={handleEditSubmit}
          category={categoryToEdit}
          isEditing={isEditing}
        />
      )}

      <DeleteCategoryModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setCategoryToDelete(null);
        }}
        onConfirm={handleDeleteConfirm}
        categoryName={categoryToDelete?.title || ""}
        isDeleting={isDeleting}
      />

      <CreateCategoryModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onSubmit={handleCreateCategory}
        isCreating={isCreating}
      />

      {/* Action Loading Overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <LoadingSpinner size="medium" color="#ffffff" />
        </div>
      )}
    </div>
  );
};

export default AdminCategories;
