import React, { FC, useState, useEffect } from "react";
import api from "../../services/api";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { API_URL } from "../../config";
import { useAuth } from "../../context/AuthContext";
import LoginModal from "../LoginModal";

interface SubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubscribe: (planId: string) => void;
}

interface Plan {
  _id: string;
  name: string;
  price: number;
  duration: number;
  description: string;
  features: string[];
  isPopular: boolean;
  isActive: boolean;
}

const SubscriptionModal: FC<SubscriptionModalProps> = ({
  isOpen,
  onClose,
  onSubscribe,
}) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscribingPlanId, setSubscribingPlanId] = useState<string | null>(
    null
  );
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const { isAuthenticated } = useAuth();

  const handleSubscribe = async (planId: string) => {
    if (!isAuthenticated) {
      setShowSignUpModal(true);
      return;
    }

    if (!planId) return;

    try {
      setSubscribingPlanId(planId);
      const { data } = await api.post(`/subscriptions/subscribe/${planId}`);
      //console.log("data:::", data);
      //update user in local storage
      const user = localStorage.getItem("user");
      if (user) {
        const userData = JSON.parse(user);
        userData.isSubscribed = data.user.isSubscribed;
        localStorage.setItem("user", JSON.stringify(userData));
      }

      //save subscription plan to local storage
      localStorage.setItem("subscriptionPlan", data.subscription);

      onSubscribe(planId);
      onClose();
    } catch (error) {
      console.error("Failed to subscribe:", error);
    } finally {
      setSubscribingPlanId(null);
    }
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get<Plan[]>(
          `${API_URL}/subscriptions/plans`
        );
        setPlans(data.filter((plan) => plan.isActive));
      } catch (error) {
        console.error("Failed to fetch subscription plans:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchPlans();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div
          className="fixed inset-0 bg-black/50 transition-opacity"
          onClick={onClose}
        />
        <div className="relative min-h-screen flex items-center justify-center p-4">
          <div className="relative bg-white rounded-xl max-w-3xl w-full p-8">
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            {/* Header */}
            <div className="text-center mb-8">
              <h2 className="text-2xl font-bold mb-2">Choose Your Plan</h2>
              <p className="text-gray-600">
                Get unlimited access to all courses and features
              </p>
            </div>

            {/* Plans */}
            {isLoading ? (
              <div className="flex justify-center py-8">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {plans.map((plan) => (
                  <div
                    key={plan._id}
                    className={`border rounded-lg p-6 transition-all ${
                      subscribingPlanId === plan._id
                        ? "border-blue-500 shadow-lg"
                        : "border-gray-200"
                    } ${plan.isPopular ? "relative" : ""}`}
                  >
                    {plan.isPopular && (
                      <span className="absolute -top-3 left-1/2 -translate-x-1/2 bg-blue-500 text-white px-3 py-1 rounded-full text-sm">
                        Popular
                      </span>
                    )}
                    <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                    <p className="text-gray-600 mb-4">{plan.description}</p>
                    <p className="text-3xl font-bold mb-4">
                      ${plan.price}
                      <span className="text-sm font-normal text-gray-500">
                        /month
                      </span>
                    </p>
                    <ul className="space-y-2 mb-6">
                      {plan.features.map((feature, index) => (
                        <li key={index} className="flex items-center gap-2">
                          <i className="fas fa-check text-green-500" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => handleSubscribe(plan._id)}
                      disabled={subscribingPlanId === plan._id}
                      className={`w-full py-2 px-4 rounded-lg transition-colors ${
                        subscribingPlanId === plan._id
                          ? "bg-blue-600 text-white"
                          : "bg-gray-100 hover:bg-gray-200 text-gray-700"
                      }`}
                    >
                      {subscribingPlanId === plan._id ? (
                        <LoadingSpinner size="small" color="white" />
                      ) : (
                        "Subscribe Now"
                      )}
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* Footer */}
            <div className="text-center text-gray-500 text-sm mt-6">
              By subscribing, you agree to our Terms of Service and Privacy
              Policy
            </div>
          </div>
        </div>
      </div>
      <LoginModal
        isOpen={showSignUpModal}
        onClose={() => setShowSignUpModal(false)}
        onSignUpClick={() => setShowSignUpModal(false)}
      />
    </>
  );
};

export default SubscriptionModal;
